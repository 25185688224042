import Entity, { Transformer } from '~/contracts/entity'
import GrantImage from '~/modules/grants/entities/frontend/grant-image'

export default class Microgrant extends Entity {
  _transformers () {
    return {
      activity: String,
      amount: Number,
      createdAt: Transformer.date(),
      entrepreneurDisplayName: String,
      entrepreneurGender: String,
      entrepreneurId: Number,
      entrepreneurType: String,
      error: Boolean,
      errorMessage: String,
      id: Number,
      image: Transformer.itemFromPrimitive(GrantImage, 'image'),
      giftAidAmount: Number,
      grantId: Number,
      country: String,
      teamId: Number,
      teamName: String,
      teamUuid: String,
    }
  }

  get showUrl () {
    if (!this.grantId) return { name: 'grants:frontend:search' }

    return {
      name: 'grants:frontend:show',
      params: {
        grantId: this.grantId,
      },
    }
  }

  get type () {
    return 'microgrant'
  }
}
