/** @module users/store/actions */

import { apiRoutes } from '~/modules/angels/config'
import { getInstance } from '~/plugins/http'
import get from 'lodash/get'

/**
 * @param {Store} store
 * @param {function} store.commit
 * @param {object} store.getters
 * @returns {Promise<void>}
 */
const fetchConfig = async function ({ commit, getters }) {
  const response = await getInstance().get(apiRoutes.frontend.config)

  const data = get(response, 'data.data', {})
  data.meta = get(response, 'data.meta', {})

  commit('SET_CONFIG', data)

  return getters.config
}

export default {
  fetchConfig,
}
