import admin from './admin'
import frontend from './frontend'

export default {
  namespaced: true,

  ...frontend,

  modules: {
    admin,
  },
}
