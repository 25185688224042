import QueueServiceFactory from './service'
import Vue from 'vue'

export default function ({ app }) {
  const { router, store } = app

  router.beforeResolve((to, from, next) => {
    if (typeof Vue.prototype.$queue !== 'undefined') {
      Vue.prototype.$queue.$destroy()
    }

    /** @type {QueueService} $queue */
    const $queue = QueueServiceFactory()

    $queue.$store = store

    Vue.prototype.$queue = $queue

    next()
  })
}
