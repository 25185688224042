import merge from 'lodash/merge'
import { Transformer } from '~/contracts/entity'
import AuthUser from '~/modules/auth/entities/auth-user'
import Stats from './user-stats'

export default class User extends AuthUser {
  _transformers () {
    return merge({}, super._transformers(), {
      about: String,
      address1: String,
      address2: String,
      address3: String,
      county: String,
      country: String,
      displayAs: String,
      dob: Transformer.date(),
      gender: String,
      jointName: String,
      location: String,
      occupation: String,
      orgName: String,
      postcode: String,
      stats: Transformer.item(Stats),
      telephone: String,
      title: String,
      town: String,
      type: String,
      whyGive: String,
    })
  }

  get organisation () {
    return this.orgName
  }
}
