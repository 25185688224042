import messages from '~/locales'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import merge from 'lodash/merge'

Vue.use(VueI18n)

export const config = {
  locale: 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
}

export const translator = new VueI18n(merge(config, { messages }))

export default (context) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch.
  context.app.i18n = translator
}
