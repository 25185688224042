import get from 'lodash/get'

export default function ({ redirect, route }) {
  if (route.path.includes('_')) {
    // IDs of news items in CMS may include both underscores and dashes.
    // Make sure we don't rewrite anything here. Open for later logic update.
    if (get(route, 'name') === 'cms:news-item') {
      return true
    }

    redirect(301, route.path.replace('_', '-'), route.query)
  }
}
