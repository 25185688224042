/**
 * @const {string}
 */
export const SET_CURRENT = 'SET_CURRENT'

/**
 * @const {string}
 */
export const SET_MFI_INFO_MESSAGE = 'SET_MFI_INFO_MESSAGE'

/**
 * @typedef {Mutations} MFIs~Store~MutationsTypes
 * @property {function} SET_CURRENT
 * @property {function} SET_MFI_INFO_MESSAGE
 */
/**
 * @type {MFIs~Store~MutationsTypes}
 */
export default {
  SET_CURRENT,
  SET_MFI_INFO_MESSAGE,
}
