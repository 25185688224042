import { apiRoutes } from '@mfis/config'
import { getInstance } from '~/plugins/http'
import { SET_CURRENT, SET_MFI_INFO_MESSAGE } from './mutation-types'
import { unwrapResponse } from '@/utils/framework'
import Bus, { MFI_CONTEXT_UPDATED } from '~/bus'
import get from 'lodash/get'
import merge from 'lodash/merge'
import MfiInfoMessage from '~/modules/mfis/entities/admin/mfi-info-message'
import template from 'lodash/template'

/**
 * Commit a mutation setting a current MFI context and emit an event.
 *
 * @type {function}
 * @fires Bus#MFI_CONTEXT_UPDATED
 * @param {function} commit - Vuex commit function.
 * @param {(MFIs~MFI|null)} current - Current MFI context.
 */
export const setCurrentMfi = ({ commit }, current) => {
  commit(SET_CURRENT, current)
  Bus.$emit(MFI_CONTEXT_UPDATED, current)
}

/**
 * Fetch defined profile templates of a requested type (loan or grant) for the
 * current MFI.
 *
 * @param {function(mutation: string, mfi: MFI)} commit
 * @param {MFIStoreState} state
 * @param {MFIProfileTemplateType} type
 * @returns {Promise<MFI>}
 */
const fetchProfileTemplates = async function ({ state }, type) {
  const id = state.current.id
  const uri = template(apiRoutes.admin.profileTemplateOptions)({id, type})
  const res = await getInstance().get(uri)

  return res.data.data?.config?.profileTemplates || []
}

/**
 * @param {MFIStoreState} state
 * @param {number} tplId
 * @returns {Promise<MFIProfileTemplate>}
 */
const fetchProfileTemplate = async function ({ state }, tplId) {
  const id = state.current.id
  const uri = template(apiRoutes.admin.profileTemplate)({id, tplId})
  const res = await getInstance().get(uri)

  return unwrapResponse(res.data)
}

/**
 * Return an MFI info message from state. If there is no message in state, fetch
 * it from the API, set it in the state and then return it.
 * @param {Store} store
 * @param {function} store.commit
 * @param {object} store.state
 * @param {MfiInfoMessageStore} store.state.current
 * @param {MfiInfoMessageStore~State} store.state
 * @param {number} mfiInfoMessageId
 * @returns {Promise<void>}
 */
const fetchMfiInfoMessage = async function ({ commit, state }, { mfiInfoMessageId }) {
  const uri = template(apiRoutes.admin.mfiInfoMessages.get)({ mfiInfoMessageId })
  const response = await getInstance().get(uri)
  const mfiInfoMessage = get(response, 'data.data', null)

  commit(SET_MFI_INFO_MESSAGE, merge({}, mfiInfoMessage))

  return state.current
}

/**
 * Update MFI info message.
 *
 * @kind {Function}
 * @param {Function} commit
 * @param {Number} mfiInfoMessageId
 * @param {Object} data - MFI info message model data.
 * @returns {Promise<Response>}
 */
const updateMfiInfoMessage = async function ({ commit }, { mfiInfoMessageId, data }) {
  const uri = template(apiRoutes.admin.mfiInfoMessages.edit)({ mfiInfoMessageId })
  const response = await getInstance().put(uri, data)
  const mfiInfoMessage = get(response, 'data.data', null)

  commit(SET_MFI_INFO_MESSAGE, mfiInfoMessage)

  return response
}

export default {
  fetchProfileTemplates,
  fetchProfileTemplate,
  setCurrentMfi,
  fetchMfiInfoMessage,
  updateMfiInfoMessage,
}
