import get from 'lodash/get'
import has from 'lodash/has'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/offsetting/config'
import OffsetReport from '~/modules/offsetting/entities/offset-report'
import { getInstance } from '~/plugins/http'
import { error } from '~/utils/errors'

/**
 * Exclude loans from a funded batch affiliated with an offset report.
 * @param {Store} store
 * @param {int} offsetReportId
 * @param {{}} data
 * @param {string} data.note
 * @return {Promise<AxiosResponse>}
 */
const excludeLoan = async function (store, { offsetReportId, data }) {
  const uri = template(apiRoutes.admin.loans.exclude)({ offsetReportId })

  return getInstance().post(uri, data)
}

/**
 * Fetch an offset report from the API.
 * @param {Store} store
 * @param {function} store.commit
 * @param {function[]} store.getters
 * @param {OffsettingStore~State} store.state
 * @param {number} offsetReportId
 * @param {boolean} [convert=false] - Temporary - whether to convert the offset report to an entity.
 * @returns {Promise<Offsetting~OffsetReport>}
 */
const fetch = async function ({ commit, getters, state }, { offsetReportId, convert = false }) {
  const uri = template(apiRoutes.admin.get)({ offsetReportId })
  const response = await getInstance().get(uri, {
    params: {
      include: ['fundedBatch', 'payments', 'repaymentBatch', 'totals', 'exchangeRate'],
    },
  })
  const reportData = get(response, 'data.data', null)
  const report = convert ? new OffsetReport(reportData) : reportData

  commit('SET_REPORT', report)
  commit('mfis/SET_CURRENT', get(getters['report'], 'mfi.data', null), { root: true })

  return report
}

/**
 * Query the API and return the ID of the current offset report
 * for the given `mfiId` argument.
 * @param {Store} store
 * @param {number} mfiId
 * @return {Promise<number>}
 */
const getCurrentOffsetReportId = async function (store, mfiId) {
  // Validate mfiId argument.
  if (
    Array.isArray(mfiId) ||
    typeof mfiId !== 'number' ||
    Number.isNaN(mfiId)
  ) {
    throw new Error(`MFI ID argument must be a number.`)
  }

  // Query the API and wait for the response.
  const response = await getInstance().get(apiRoutes.admin.index, {
    params: {
      completed: false,
      mfi: mfiId,
      sort: '-id',
    },
  })

  const offsetReportId = get(response, ['data', 'data', 0, 'id'], null)

  return (typeof offsetReportId !== 'number' || Number.isNaN(offsetReportId))
    ? null
    : offsetReportId
}

/**
 * Include loans in a funded batch affiliated with an offset report.
 * @param {Store} store
 * @param {int} offsetReportId
 * @param {*} data
 * @return {Promise<AxiosResponse>}
 */
const includeLoan = async function (store, { offsetReportId, data }) {
  const uri = template(apiRoutes.admin.loans.include)({ offsetReportId })

  return getInstance().post(uri, data)
}

/**
 * Set (freeze) current funded loans batch.
 * @param {function} commit
 * @param {number} offsetReportId
 * @return {Promise<AxiosPromise>}
 */
const setFundedBatch = async function ({ commit }, { offsetReportId }) {
  const uri = template(apiRoutes.admin.loans.set)({ offsetReportId })

  const response = await getInstance().post(uri)

  if (response.status === 200 && has(response, 'data.data')) {
    commit('SET_REPORT', response.data.data)
  }
}

/**
 * Send a request setting an exchange rate between USD and funding currency
 * (or reversed).
 * @param {Store} store
 * @param {number} offsetReportid
 * @param {{}} data
 * @return {Promise<void>}
 */
const setFundedBatchRate = async function ({ commit }, { offsetReportId, data }) {
  const uri = template(apiRoutes.admin.loans.setRate)({ offsetReportId })

  const response = await getInstance().post(uri, data)

  if (response.status === 200 && has(response, 'data.data')) {
    commit('SET_REPORT', response.data.data)
  }
}

/**
 * Set (freeze) current repayments batch.
 * @param {function} commit
 * @param {number} offsetReportId
 * @return {Promise<AxiosPromise>}
 */
const setRepaymentBatch = async function ({ commit }, { offsetReportId }) {
  const uri = template(apiRoutes.admin.repayments.set)({ offsetReportId })

  const response = await getInstance().post(uri)

  if (response.status === 200 && has(response, 'data.data')) {
    commit('SET_REPORT', response.data.data)
  }
}

/**
 * POST exchange rates from local currencies to USD
 * @param {function} commit
 * @param {number} offsetReportId
 * @param {{}} data - POST data.
 * @returns {Promise<void>}
 */
const setRepaymentBatchRates = async function ({ commit }, { offsetReportId, data }) {
  const uri = template(apiRoutes.admin.repayments.setRates)({ offsetReportId })

  const response = await getInstance().post(uri, data)

  if (response.status === 200 && has(response, 'data.data')) {
    commit('SET_REPORT', response.data.data)
  }
}

/**
 * Update repayment's actual repaid value.
 * @param {Store} store
 * @param {string|null} note - Note required when making a payment "missed".
 * @param {number} offsetReportId
 * @param {number} repaymentId
 * @param {number} value
 * @return {Promise<AxiosResponse>}
 */
const updateRepaymentValue = async function (store, { note, offsetReportId, repaymentId, value }) {
  const uri = template(apiRoutes.admin.repayments.update)({ offsetReportId, repaymentId })

  return getInstance().post(uri, { amountReceived: value, missedNote: note })
}

/**
 * Upload a spreadsheet with updated repayments data.
 *
 * @param {Store} store
 * @param {{}} payload
 * @param {number} payload.offsetReportId
 * @param {File} payload.file
 * @return {Promise<boolean>}
 */
const uploadRepayments = async function (store, { file, offsetReportId }) {
  let data
  let uri

  try {
    // Prepare POST data.
    data = new FormData()
    data.append('repayments_file', file)
    // Determine upload URI.
    uri = template(apiRoutes.admin.repayments.upload)({ offsetReportId })
  } catch (err) {
    error(err)
  }

  const response = await getInstance().post(uri, data, {
    params: {
      XDEBUG_SESSION_START: 'PHPSTORM',
    },
  })

  return response.status === 204
}

/**
 * @typedef {Object} OffsettingStore~Actions
 * @property {Function} exclude
 * @property {Function} fetch
 * @property {Function} includeLoan
 * @property {Function} setFundedBatch
 * @property {Function} setRepaymentBatch
 * @property {Function} updateRepaymentValue
 * @property {Function} uploadRepayments
 */
/**
 * @type {OffsettingStore~Actions}
 */
export default {
  excludeLoan,
  fetch,
  getCurrentOffsetReportId,
  includeLoan,
  setFundedBatch,
  setFundedBatchRate,
  setRepaymentBatch,
  setRepaymentBatchRates,
  updateRepaymentValue,
  uploadRepayments,
}
