/**
 * @typedef {Object} Grants~FrontendStoreGetters
 * @property {Grant|null} current
 */

/**
 * @type {Grants~FrontendStoreGetters}
 */
export default {
  current: state => state.current,
  searchConfig: state => state.search.config,
  searchDefaults: state => state.search.defaults,
  searchInProgress: state => state.search.inProgress,
  searchParams: state => state.search.params === null ? state.search.defaults : state.search.params,
  searchResults: state => state.search.results,
}
