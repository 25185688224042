/** @module utils/format */

import Money from '@mmieluch/js-money'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'

/**
 * Format a monetary value.
 * @param {number} value - Value to return as a formatted string.
 * @param {string} currencyCode - 3-letter international currency code.
 * @param {string} locale - Locale to determine formatting.
 * @return {string}
 */
export const money = (value, currencyCode = 'USD', locale = 'en-GB') => {
  if (!isNumber(value)) {
    const type = typeof value
    throw new Error(`[utils/format::money] "value" must be a number, ${type} given.`)
  }
  if (Number.isNaN(value)) {
    throw new Error(`[utils/format::money] "value" must be a valid number, NaN given.`)
  }
  if (!isFinite(value)) {
    throw new Error(`[utils/format::money] "value" must be a finite number.`)
  }

  const currency = Money[currencyCode]
  const method = isInteger(value) ? 'fromInteger' : 'fromDecimal'
  const amount = Money[method](value, currency)

  const settings = {
    style: 'currency',
    currency: currency.code,
    minimumFractionDigits: currency.decimal_digits,
  }

  return amount.toDecimal().toLocaleString(locale, settings)
}

/**
 * Remove double slashes from the URL, but leave in the initial slashes
 * (after protocol).
 *
 * @example removeURLDoubleSlashes(http://test//a/b//d) =>  http://test/a/b/d
 *
 * @param {string} value
 * @return {string|*}
 */
export const removeURLDoubleSlashes = function (value) {
  if (typeof value !== 'string') {
    console.warn(`[value] must be a string. Skipping.`)
  }

  return value.replace(/([^:]\/)\/+/g, `$1`)
}

/**
 * Replace hyphens with full stops in the given string.
 *
 * @example hyphensToFullStops(ABC-123-DEF-456) =>  ABC.123.DEF.456
 *
 * @param {string} value
 * @return {string|*}
 */
export const hyphensToFullStops = function (value) {
  if (typeof value !== 'string') {
    console.warn(`[value] must be a string. Skipping.`)
  }

  return value.replace(/[_-]/g, '.')
}

/**
 * Break up single block of text to paragraphs using line break characters.
 *
 * @param {string} text
 * @return {string}
 */
export function textToParagraphs (text) {
  if (typeof text !== 'string') return ''

  return text.split('\n')
    .filter(line => typeof line === 'string')
    .filter(line => line.length > 0)
    .map(line => `<p>${line}</p>`)
    .join('')
}

/**
 * Append either "'s" or "'" to given string.
 * @param {string|*} str
 * @return {string|null}
 */
export function toPossessive (str) {
  if (typeof str !== 'string' || !str.length) return null

  return str.slice(-1).toLowerCase() === 's'
    ? `${str}'`
    : `${str}'s`
}
