import { getInstance } from '~/plugins/http'

const state = {
  xdebug: false,
}

export const mutations = {
  /**
   * Mark Xdebug session has finished.
   * @param {Object} state
   */
  XDEBUG_OFF (state) {
    state.xdebug = false
  },
  /**
   * Mark Xdebug session has started.
   * @param {Object} state
   */
  XDEBUG_ON (state) {
    state.xdebug = true
  },
}

export const actions = {
  async xdebugOn ({ commit }) {
    const http = getInstance()
    await http.get('/users', {
      params: {
        XDEBUG_SESSION_START: process.env.XDEBUG_SESSION_NAME,
      },
    })

    commit('XDEBUG_ON')
  },
  async xdebugOff ({ commit }) {
    const http = getInstance()
    await http.get('/users?XDEBUG_SESSION_STOP')

    commit('XDEBUG_OFF')
  },
}

export const getters = {
  xdebug: state => state.xdebug,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
