import '~/modules/grant-reporting/types'

/**
 * @typedef {{}} GrantReportingStoreState
 * @property {GrantReport} [current=null]
 */
/**
 * @return {GrantReportingStoreState}
 */
export default {
  current: null,
}
