/** @module modules/loans/store/admin/actions */

import get from 'lodash/get'
import has from 'lodash/has'
import merge from 'lodash/merge'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/loans/config'
import BulkProcessingError from '~/modules/loans/entities/admin/bulk-processing-error'
import { getInstance } from '~/plugins/http'

/**
 * Perform a bulk change of loans' status.
 * @param {Store} store
 * @param {number[]} loanIds - Array of loan IDs.
 * @param {string} status - Target status
 * @return {Promise<BulkProcessingError[]|boolean>}
 */
const bulkPublish = async function (store, { loanIds }) {
  const response = await getInstance().patch(apiRoutes.admin.bulkPublish, { loanIds })

  switch (response.status) {
    case 200:
      return get(response.data, 'data', []).map(error => new BulkProcessingError(error))
    case 204:
    default:
      return true
  }
}

/**
 * Perform a bulk change of loans' status.
 * @param {Store} store
 * @param {number[]} loanIds - Array of loan IDs.
 * @param {string} status - Target status
 * @return {Promise<BulkProcessingError[]|boolean>}
 */
const bulkStatus = async function (store, { loanIds, status }) {
  const data = { loanIds, status }

  const response = await getInstance().patch(apiRoutes.admin.bulkStatus, data)

  switch (response.status) {
    case 200:
      return get(response.data, 'data', []).map(error => new BulkProcessingError(error))
    case 204:
    default:
      return true
  }
}

/**
 * Create a new loan resource.
 *
 * @param {function} commit
 * @param {object} data
 * @returns {Promise<Response>}
 */
const create = async function ({ commit }, data) {
  const response = await getInstance().post(apiRoutes.admin.create, data)
  const loan = get(response, 'data.data', null)

  commit('SET_LOAN', loan)

  return response
}

/**
 * Return a loan from state. If there is no loan in state, fetch it from
 * the API, set it in the state and then return it.
 * @param {Store} store
 * @param {function} store.commit
 * @param {object} store.state
 * @param {Loans~Loan} store.state.current
 * @param {EntrepreneursStore~State} store.state
 * @param {number} loanId
 * @returns {Promise<void>}
 */
const fetch = async function ({ commit, state }, { loanId }) {
  const uri = template(apiRoutes.admin.get)({ loanId })
  const response = await getInstance().get(uri)
  const loan = get(response, 'data.data', null)

  commit('SET_LOAN', merge({}, loan))

  return state.current
}

/**
 * Fetch loans configuration from the API and store in state.
 * @param {Store} context
 * @param {Function} context.commit
 * @returns {Promise<{}|config>}
 */
const fetchConfig = async ({ commit, state }) => {
  const response = await getInstance().get(apiRoutes.admin.loansConfig)

  commit('SET_CONFIG', response.data.data.config)

  return state.config
}

/**
 * Post a message for a loan.
 * @param {Store} store
 * @param {number} loanId
 * @param {string} message
 * @return {Promise<void>}
 */
const postMessage = async function (store, { loanId, message }) {
  const uri = template(apiRoutes.admin.messages.create)({ loanId })
  const response = await getInstance().post(uri, { message })

  if (
    get(response, 'status', 0) === 200 &&
    has(response, 'data.data')
  ) {
    store.commit('SET_LOAN_MESSAGES', get(response, 'data.data'))
  }
}

/**
 * Search for loans in API.
 * @param {Store} store - Store context
 * @param {Object} params - Search parameters
 * @returns {Promise<void>}
 */
const search = async function (store, params) {
  return getInstance().get(apiRoutes.admin.index, params)
}

/**
 * Request changing loan's status.
 * @param {function} commit
 * @param {number} loanId
 * @param {string} status
 * @param {undefined|string} [message=undefined]
 * @returns {Promise<AxiosResponse>}
 */
const status = async function ({ commit }, { loanId, status, message = undefined }) {
  const uri = template(apiRoutes.admin.status)({ loanId })
  const data = { status }

  if (message) {
    data.message = message
  }

  const response = await getInstance().post(uri, data, {
    params: {
      include: ['loanImages', 'messages'],
    },
  })

  const loan = get(response, 'data.data', null)

  commit('SET_LOAN', loan)

  return response
}

/**
 * Update loan record in the API.
 * @param {object} context
 * @param {function} context.commit - Vuex store commit method
 * @param {number} id - Loan ID
 * @param {Loan} data - Loan data object
 * @return {Promise<Response>}
 */
const update = async ({ commit }, { loanId, data }) => {
  const uri = template(apiRoutes.admin.update)({ loanId })
  const response = await getInstance().put(uri, data)
  const loan = get(response, 'data.data', null)

  commit('SET_LOAN', loan)

  return response
}

export default {
  bulkPublish,
  bulkStatus,
  create,
  fetch,
  fetchConfig,
  postMessage,
  search,
  status,
  update,
}
