import admin from './admin'
import frontend from './frontend'

export default {
  namespaced: true,

  modules: {
    admin: {
      namespaced: true,
      ...admin,
    },
  },

  ...frontend,
}
