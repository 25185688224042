import merge from 'lodash/merge'

/**
 * Update individual report values
 *
 * @param {GrantReportingStoreState} state
 * @param {GrantReport} data
 * @constructor
 */
const PATCH_REPORT = (state, data) => {
  state.current = merge({}, state.current, data)
}

/**
 * Set a value as a current report in the store
 *
 * @kind function
 * @param {GrantReportingStoreState} state
 * @param {GrantReport} report
 * @return {void}
 */
const SET_REPORT = (state, report) => {
  state.current = report
}

/**
 * @typedef {{}} GrantReportingStoreMutations
 * @property {function} SET_REPORT
 */
/**
 * @type {GrantReportingStoreMutations}
 */
export default {
  PATCH_REPORT,
  SET_REPORT,
}
