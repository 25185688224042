import Entity, { Transformer } from '~/contracts/entity'

class UserSettingsConfigSetting extends Entity {
  _transformers () {
    return {
      label: String,
      value: Boolean,
    }
  }
}

export default class UserSettingsConfig extends Entity {
  _transformers () {
    return {
      careNewsletter: Transformer.item(UserSettingsConfigSetting),
      donateRepayments: Transformer.item(UserSettingsConfigSetting),
      emailContact: Transformer.item(UserSettingsConfigSetting),
      giftAid: Transformer.item(UserSettingsConfigSetting),
      loanUpdateEmail: Transformer.item(UserSettingsConfigSetting),
      lwcNewsletter: Transformer.item(UserSettingsConfigSetting),
      smsContact: Transformer.item(UserSettingsConfigSetting),
      telContact: Transformer.item(UserSettingsConfigSetting),
    }
  }

  _apiMapping () {
    return {
      giftaid: 'giftAid',
    }
  }
}
