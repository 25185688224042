/** @module appeals/store/frontend/actions */

import Appeal from '~/modules/appeals/entities/frontend/appeal'
import get from 'lodash/get'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/appeals/config'
import { getInstance } from '~/plugins/http'

/**
 * @param {Store} store
 * @param {function} store.commit
 * @param {object} store.state
 * @param {Appeals~Appeal} store.state.current
 * @param {string} appealSlug
 * @returns {Promise<void>}
 */
const fetch = async function ({ commit, getters, state }, { appealSlug }) {
  if (
    getters.appeal &&
    getters.appeal instanceof Appeal &&
    getters.appeal.appealSlug === appealSlug
  ) {
    return getters.appeal
  }

  const uri = template(apiRoutes.frontend.show)({ appealSlug })
  const response = await getInstance().get(uri)
  const appealData = get(response, 'data.data', null)

  commit('SET_APPEAL', new Appeal(appealData))

  return getters.appeal
}

export default {
  fetch,
}
