/** @module plugins/http */

import axios from 'axios'
import partial from 'lodash/partial'
import Vue from 'vue'
import interceptors from './interceptors'

let instance

/**
 * Create an instance of Axios client.
 * @kind {function}
 * @return {AxiosInstance}
 */
const createInstance = (app) => {
  instance = axios.create({
    baseURL: `${process.env.API_URL}${process.env.API_URL_BROWSER}`,
    headers: {
      common: {
        'Accept': process.env.API_ACCEPT_HEADER,
        'Cache-Control': 'no-cache',
      },
    },
    withCredentials: true,
  })

  const rejector = error => Promise.reject(error)
  const resolver = response => Promise.resolve(response)
  // Setup auth interceptor.
  instance.interceptors.request.use(interceptors.request.authToken.bind(app), rejector)
  // Setup POST params re-write interceptor.
  instance.interceptors.request.use(interceptors.request.outgoingToSnakeCase.bind(app), rejector)

  // Setup automatic token refreshing interceptor.
  instance.interceptors.response.use(resolver, partial(
    interceptors.response.refreshToken,
    partial.placeholder,
    app,
    instance,
  ))

  return instance
}

/**
 * Get instance of Axios client. If it doesn't exist - create one (singleton pattern).
 * @kind {function}
 * @return {AxiosInstance}
 */
export const getInstance = () => instance

/**
 * HTTP plugin definition for Vue.
 * @type {{install: (function(Vue))}}
 */
const plugin = {
  /**
   * Installer function. Sets up a pre-configured instance of Axios in each component.
   * @param {Vue} Vue
   */
  install (Vue) {
    Vue.prototype.$http = getInstance()
  },
}

export default ({ app }) => {
  app.http = instance = createInstance(app)

  // Install HTTP plugin.
  Vue.use(plugin, { app })
}
