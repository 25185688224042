/** @module modules/grants/admin/store */

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

/**
 * @typedef {Object} Grants~AdminStore
 * @property {Boolean} namespaced
 * @property {Grants~AdminStoreActions} actions
 * @property {Grants~AdminStoreGetters} getters
 * @property {Grants~AdminStoreMutations} mutations
 * @property {Grants~AdminStoreState} state
 */
/**
 * @type {Grants~AdminStore}
 */
const store = {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
}

export default store
