import Pagination from '~/entities/pagination'

export const MICROGRANTS_SEARCH_PAGINATION_DEFAULTS = {
  count: 0,
  currentPage: 1,
  perPage: 15,
  total: 0,
  totalPages: 1,
}

export const MICROLOANS_SEARCH_PAGINATION_DEFAULTS = {
  count: 0,
  currentPage: 1,
  perPage: 15,
  total: 0,
  totalPages: 1,
}

export const TRANSACTIONS_DEFAULT_PAGINATION = new Pagination({
  currentPage: 1,
  perPage: 50,
  total: 0,
  totalPages: 0,
})
