import AuthUserProperties from '@auth/entities/user-properties'
import merge from 'lodash/merge'

export default class AdminUserProperties extends AuthUserProperties {
  _transformers () {
    return merge({}, super._transformers(), {
      isSpam: Boolean,
      isTest: Boolean,
      hasMessage: Boolean,
      isFraud: Boolean,
      isSuspicious: Boolean,
    })
  }
}
