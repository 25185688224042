import cloudinary from 'cloudinary-core'

let instance

const createInstance = () => {
  return new cloudinary.Cloudinary({
    cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
    secure: true,
  })
}

export function getCloudinaryURL(publicID, params) {
  if (typeof instance === 'undefined') {
    instance = factory()
  }
  if (typeof instance.url !== 'function') {
    return null
  }
  return instance.url(publicID, params)
}

export default function factory () {
  if (!instance) instance = createInstance()

  return instance
}
