import { Transformer } from '~/contracts/entity'
import MicroloanGridItem from '~/modules/loans/entities/frontend/microloan-grid-item'
import Team from '~/modules/teams/entities/frontend/team'
import Transaction from './transaction'

export default class MicroloanTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'type', {
      enumerable: true,
      value: 'microloan',
    })
  }

  _transformers () {
    const transformers = super._transformers()

    return Object.assign({}, transformers, {
      loan: Transformer.item(MicroloanGridItem),
      team: { default: null, type: Transformer.item(Team) },
    })
  }
}
