/**
 * @typedef {object} Updates~Config~ApiRoutes
 * @property {object} admin
 * @property {string} admin.create
 * @property {string} admin.get
 * @property {string} admin.index
 * @property {string} admin.indexScoped
 * @property {string} admin.updatesConfig
 * @property {string} admin.update
 */
export const apiRoutes = {
  admin: {
    config: '/admin/loan/updates/options',
    create: '/admin/loan/<%= loanId %>/updates',
    get: '/admin/loan/<%= loanId %>/updates/<%= updateId %>',
    index: '/admin/loan/updates',
    indexScoped: '/admin/loan/<%= loanId %>/updates',
    messages: {
      create: '/admin/loan/<%= loanId %>/updates/<%= updateId %>/messages',
    },
    update: '/admin/loan/<%= loanId %>/updates/<%= updateId %>',
    updateStatus: '/admin/loan/<%= loanId %>/updates/<%= updateId %>/status',
  },
  frontend: {
    fetch: '/updates/<%= updateId %>',
    index: '/updates',
  },
}

/**
 * The prefix to images' public IDs.
 * @const {string}
 */
export const imagePublicIdPrefix = 'lwc/updates'

/**
 * @const {string}
 */
export const imageUploadPresetName = 'update_photo'

/**
 * @typedef {Object} Updates~Config
 * @property {Updates~Config~ApiRoutes} apiRoutes
 * @property {string} imagePublicIdPrefix
 * @property {string} imageUploadPresetName
 */
/**
 * @type {Updates~Config}
 */
export default {
  apiRoutes,
  imagePublicIdPrefix,
  imageUploadPresetName,
}
