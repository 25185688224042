import get from 'lodash/get'
import merge from 'lodash/merge'
import UserStats from '~/modules/users/entities/frontend/user-stats'
import { apiRoutes } from '~/modules/vouchers/config'
import Voucher from '~/modules/vouchers/entities/voucher'
import { getInstance } from '~/plugins/http'

const vouchersState = {
  purchased: [],
  received: [],
}

export default {
  namespaced: true,

  state: {
    vouchers: merge({}, vouchersState),
  },

  mutations: {
    SET_VOUCHERS: (state, vouchers) => {
      state.vouchers.purchased = get(vouchers, 'purchased', [])
      state.vouchers.received = get(vouchers, 'received', [])
    },
    SET_VOUCHERS_PURCHASED: (state, vouchers) => { state.vouchers.purchased = vouchers },
    SET_VOUCHERS_RECEIVED: (state, vouchers) => { state.vouchers.received = vouchers },
  },

  getters: {
    vouchers: state => get(state, 'vouchers', merge({}, vouchersState)),
    vouchersPurchased: state => get(state, 'vouchers.purchased', []),
    vouchersReceived: state => get(state, 'vouchers.received', []),
  },

  actions: {
    /**
     * Fetch vouchers purchased and received by currently authenticated user.
     *
     * @param {Store} store
     * @return {Promise<*>}
     */
    async fetchVouchers (store) {
      try {
        const response = await getInstance().get(apiRoutes.frontend.myAccount.vouchers)
        const statsData = get(response.data, 'data.stats.data', {})
        const vouchersPurchasedData = get(response.data, 'data.vouchersPurchased.data', [])
        const vouchersReceivedData = get(response.data, 'data.vouchersReceived.data', [])

        store.commit('SET_VOUCHERS_PURCHASED', vouchersPurchasedData.map(voucher => new Voucher(voucher)))
        store.commit('SET_VOUCHERS_RECEIVED', vouchersReceivedData.map(voucher => new Voucher(voucher)))

        store.commit('users/myAccount/SET_STATS', new UserStats(statsData), { root: true })

        return store.getters['myAccount/vouchers']
      } catch (err) {
        console.error(err)
        this.$sentry.captureException(err)
      }
    },
  },
}
