import admin from './admin'
import frontend from './frontend'

/**
 * @typedef {Object} Grants~Store
 */
/**
 * @type {Grants~Store}
 */
export default {
  namespaced: true,

  modules: {
    admin: {
      namespaced: true,
      ...admin,
    },
  },

  ...frontend,
}
