import get from 'lodash/get'
import { Transformer } from '~/contracts/entity'
import Voucher from '~/modules/vouchers/entities/voucher'
import Transaction from './transaction'

function mapVoucherTransactionType (originalType) {
  switch (originalType) {
    default:
      return null
    case 'giftVoucherPurchased':
      return 'purchased'
    case 'giftVoucherRedeemed':
      return 'redeemed'
  }
}

export default class GiftVoucherPurchasedTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    const originalType = get(data, 'type', null)

    Object.defineProperties(this, {
      subtype: {
        enumerable: true,
        value: mapVoucherTransactionType(originalType),
      },
      type: {
        enumerable: true,
        value: 'voucher',
      },
    })
  }

  _transformers () {
    const transformers = super._transformers()

    return Object.assign({}, transformers, {
      voucher: { default: null, type: Transformer.item(Voucher) },
    })
  }

  _apiMapping () {
    return {
      giftVoucher: 'voucher',
    }
  }
}
