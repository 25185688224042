import Pagination from '~/entities/pagination'
import { TRANSACTIONS_DEFAULT_PAGINATION } from '~/modules/users/store/frontend/my-account/support'
import { itemToEntity } from '~/utils/framework'
import { transformUserTransactions } from '~/modules/users/utils'

function SET_TRANSACTIONS (state, transactions = null) {
  if (!Array.isArray(transactions)) {
    state.transactions = null
    return
  }

  state.transactions.items = transformUserTransactions(transactions)
}

function SET_TRANSACTIONS_PAGINATION (state, pagination) {
  const entity = itemToEntity(pagination, Pagination)
  state.transactions.pagination = entity instanceof Pagination
    ? entity
    : TRANSACTIONS_DEFAULT_PAGINATION
}

export default {
  SET_BONUS_STATS: (state, bonusStats) => { state.bonusStats = bonusStats },
  SET_FUNDABLE_LOANS: (state, fundableLoans) => { state.fundableLoans = fundableLoans },
  SET_FUNDABLE_LOANS_COUNT: (state, count) => { state.fundableLoansCount = count },
  SET_REPAYMENTS: (state, repayments) => { state.repayments = repayments },
  SET_STATS: (state, stats) => { state.stats = stats },
  SET_TEAMS: (state, teams) => { state.teams = teams },
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_PAGINATION,
  SET_USER_MICROGRANTS: (state, microgrants) => { state.microgrants = microgrants },
  SET_USER_MICROLOANS: (state, microloans) => { state.microloans = microloans },
  SET_USER_MICROGRANTS_SEARCH_RESULTS: (state, results) => { state.micrograntsSearch = results },
  SET_USER_MICROLOANS_SEARCH_RESULTS: (state, results) => { state.microloansSearch = results },
  SET_WITHDRAWAL: (state, withdrawal) => { state.withdrawal = withdrawal },
}
