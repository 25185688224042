import Transaction from './transaction'

export default class WithdrawalTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'type', {
      enumerable: true,
      value: 'withdrawal',
    })
  }
}
