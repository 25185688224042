import isDate from 'date-fns/is_date'
import parse from 'date-fns/parse'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import pick from 'lodash/pick'
import set from 'lodash/set'

/**
 * Return an object with all possible properties and assign "undefined"
 * to each of them.
 *
 * *MUST* be called *AFTER* defining transformers.
 *
 * @return {{}}
 */
export const stateFactory = function () {
  const state = {}
  const keys = Object.keys(this._t)

  keys.forEach(key => {
    const defaultValue = typeof this._t[key] === 'function'
      ? undefined
      : get(this._t[key], 'default')
    set(state, key, defaultValue)
  }, this)

  return state
}

/**
 * Base class for handling entities received from the API.
 */
export default class Entity {
  constructor (data = {}) {
    // Store transformers mapping as a "private" property.
    Object.defineProperty(this, '_t', {
      value: this._transformers(),
    })
    // Prepare a non-enumerable object for keeping values.
    Object.defineProperty(this, '_v', {
      value: stateFactory.call(this),
    })
    Object.defineProperty(this, '_m', {
      value: this._apiMapping(),
    })

    this._prepGettersAndSetters()
    this._populate(data)
  }

  _populate (data) {
    if (isNil(data)) return

    // Populate object.
    const entries = Object.entries(data)

    for (let i = 0; i < entries.length; i++) {
      let [name, value] = entries[i]

      if (typeof this._t[name] === 'undefined') {
        const mapping = get(this._m, name)

        if (typeof mapping === 'undefined') continue

        // Try looking up mapping between possible API-returned data keys
        // and our desired transformed keys. Important: it's possible that
        // the name is in fact a comma-separated path, so we need to possibly
        // split the name by comma, and then operate with the first array item.
        const rootMapping = mapping.split('.')[0]

        if (typeof this._t[rootMapping] === 'undefined') continue

        // There was a mapping defined between a key within the "data" object
        // and our desired property name, so let's use the mapped prop name
        // to set the value.
        name = this._m[name]
      }

      set(this, name, value)
    }
  }

  _prepGettersAndSetters () {
    // Automatically cast all primitive types.
    Object.entries(this._t).forEach(([propName, propDefinition]) => {
      Object.defineProperty(this, propName, {
        enumerable: true,
        get () {
          return get(this, ['_v', propName])
        },
        set (value) {
          // If value is nil (undefined or null) and `func` is not a constructor
          // for a scalar value, call the constructor with whatever value it may be.
          // Also do it if actual value is present.
          const factory = typeof propDefinition === 'function'
            ? propDefinition
            : get(propDefinition, 'type')

          if (typeof factory === 'undefined') {
            throw new Error('Could not determine property factory.')
          }

          if (
            !isNil(value) ||
            ![Boolean, Number, String].includes(factory)
          ) {
            set(this._v, propName, factory(value))
          } else {
            // Trying to pass nil value to a scalar constructor? Nope!
            set(this._v, propName, value)
          }

          return this
        },
      })
    }, this)
  }

  /**
   * Map possible API response keys to transformed keys.
   *
   * For example, for a user object API will return the following structure (partial):
   * {
   *   data: {
   *     firstname: 'John',
   *     lastname: 'Doe',
   *   },
   * }
   *
   * I consider having "firstname" and "lastname" as props a bit icky, I'd rather have them
   * mapped to "firstName", "lastName". In order to achieve that, I'd define the returned object
   * as such:
   * {
   *   firstname: 'firstName',
   *   lastname: 'lastName',
   * }
   *
   * The constructor will map the value from the response to the correct transformed field.
   *
   * @returns {{}}
   * @private
   */
  _apiMapping () {
    return {}
  }

  /**
   * Return an object where:
   * - keys are entity property names;
   * - values are functions used to transform data received from the API
   *   to their desired types.
   * @return {{}}
   * @private
   */
  _transformers () {
    return {}
  }
}

export class Transformer {
  static item (Ctor) {
    return function (data) {
      if (
        has(data, 'data') &&
        isPlainObject(data.data) &&
        Object.keys(data).length === 1
      ) {
        // It means the item is wrapped inside API's "data" object.
        data = data.data
      }

      try {
        return new Ctor(data)
      } catch (e) {
        console.error(e)
        return new Ctor()
      }
    }
  }

  static itemFromPrimitive (Ctor, propName) {
    return function (value) {
      if (isPlainObject(value) && has(value, propName)) {
        return new Ctor(pick(value, [propName]))
      }

      if (value instanceof Ctor) {
        return value
      }

      return new Ctor({
        [propName]: value,
      })
    }
  }

  static date () {
    return function (value) {
      if (isDate(value)) return value

      if (isPlainObject(value) && value.hasOwnProperty('date')) {
        if (isDate(value.date)) return value

        if (typeof value.date === 'string') {
          return parse(value.date)
        }
      }

      if (typeof value === 'string') {
        return parse(value)
      }

      return null
    }
  }

  static collection (Ctor) {
    return function (data) {
      const arrayAccess = isArray(data)
      const objectAccess = isPlainObject(data)

      if (!arrayAccess && !objectAccess) return []

      if (objectAccess) {
        if (!isArray(data.data)) return []

        data = data.data
      }

      const collection = []

      for (let item of data) {
        try {
          const ALLOWED_NATIVE_TYPES = [String, Number, Boolean]
          collection.push(ALLOWED_NATIVE_TYPES.includes(Ctor) ? item : new Ctor(item))
        } catch (e) {
          console.error(e)
        }
      }

      return collection
    }
  }

  static isArray (value) {
    return isArray(value)
  }

  static isDate (value) {
    return isDate(value)
  }

  static isPlainObject (value) {
    return isPlainObject(value)
  }
}
