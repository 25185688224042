import {
  SET_BREADCRUMBS,
  SET_DASHBOARD_FIGURES,
  SET_DASHBOARD_STATS,
  SET_PAGE_HEADING,
  SET_PAGE_SUBHEADING,
  TOGGLE_MOBILE_SIDEBAR,
  TOGGLE_SIDEBAR_HIDDEN,
  TOGGLE_SIDEBAR_MINIMISED,
} from './mutation-types'

export default {
  /**
   * Set breadcrumbs in state.
   * @param {AdminStore~State} state
   * @param {(Array|null)} breadcrumbs
   */
  [SET_BREADCRUMBS] (state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  /**
   * Set dashboard data in state.
   * @param {AdminStore~State} state
   * @param {{}|null} data
   */
  [SET_DASHBOARD_FIGURES] (state, data) {
    state.dashboard.figures = data
  },
  /**
   * Set dashboard data in state.
   * @param {AdminStore~State} state
   * @param {{}|null} data
   */
  [SET_DASHBOARD_STATS] (state, data) {
    state.dashboard.stats = data
  },
  /**
   * Set current page heading.
   * @param {State} state
   * @param {string|null} heading
   */
  [SET_PAGE_HEADING] (state, heading = null) {
    state.pageHeading = heading
  },
  /**
   * Set current page subheading.
   * @param {State} state
   * @param {string|null} heading
   */
  [SET_PAGE_SUBHEADING] (state, subheading = null) {
    state.pageSubheading = subheading
  },
  /**
   * Toggle sidebar's "hidden" state.
   * @param {AdminStore~State} state
   */
  [TOGGLE_SIDEBAR_HIDDEN] (state) {
    state.sidebar.hidden = !state.sidebar.hidden
  },
  /**
   * Toggle sidebar's "minimised" state.
   * @param {AdminStore~State} state
   */
  [TOGGLE_SIDEBAR_MINIMISED] (state) {
    state.sidebar.minimised = !state.sidebar.minimised
  },
  /**
   * Toggle mobile sidebar's "hidden" state.
   * @param {AdminStore~State} state
   */
  [TOGGLE_MOBILE_SIDEBAR] (state) {
    state.mobileSidebar = !state.mobileSidebar
  },
}
