/** @module Auth */

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

const config = state => state.config

/**
 * Return {true} if user has administrative privileges (is an HO admin or an MFI admin),
 * {false} otherwise.
 * @kind {function}
 * @param {Auth~AuthUser} user
 * @return {boolean}
 */
const isAdmin = ({ user }) => {
  const roles = get(user, 'roles', [])

  if (isEmpty(roles)) return false

  return roles.some(role => role === 'admin')
}

/**
 * Return {true} if user has an administrative role related to Head Office.
 * @param {Auth~AuthUser} user
 * @return {boolean}
 */
const isHOAdmin = ({ user }) => {
  const roles = get(user, 'roles', [])
  const re = /^ho-/

  for (let i = 0; i < roles.length; i++) {
    if (re.test(roles[i])) return true
  }

  return false
}

/**
 * Return {true} if user has an administrative role related to MFIs.
 * @param {Auth~AuthUser} user
 * @return {boolean}
 */
const isMFIAdmin = ({ user }) => {
  const roles = get(user, 'roles', [])
  const re = /^mfi-/

  for (let i = 0; i < roles.length; i++) {
    if (re.test(roles[i])) return true
  }

  return false
}

/**
 * Return {true} if authenticated user record is set in the store, {false} otherwise.
 * @kind {function}
 * @param {User~State} state
 * @return {boolean}
 */
const isAuthenticated = state => (
  !isNil(state.user) &&
  typeof state.user.uuid === 'string' &&
  state.user.uuid.length === 36 &&
  typeof state.token === 'string' &&
  state.token.length > 0
)

export default {
  config,
  isLoggingOut: state => get(state, 'loggingOut', false),
  isAdmin,
  isAuthenticated,
  isHOAdmin,
  isMFIAdmin,
  mfis: state => get(state, 'user.mfis', []),
  settingsConfig: state => state.settingsConfig,
  user: state => state.user,
}
