import Repayment from './repayment'

export default class BufferRepayment extends Repayment {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'isBufferRepayment', {
      configurable: true,
      enumerable: true,
      value: true,
      writable: false,
    })
  }
}
