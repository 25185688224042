import castArray from 'lodash/castArray'

export default {
  CLEAR (state) {
    state.jobs = []
  },
  CLEAR_CURRENT (state) {
    state.current = null
  },
  QUEUE (state, jobs) {
    jobs = castArray(jobs)
    state.jobs = [...state.jobs, ...jobs]
  },
  SHIFT (state) {
    state.current = state.jobs.shift()
  },
}
