const state = () => ({
  contactURI: '/info/about-us/contact-us',
  social: [
    {
      alias: 'youtube',
      icon: 'youtube',
      url: 'https://www.youtube.com/playlist?list=PLfyr8v_N1PyrMtjrEcVqPSec9LJt5bfkW',
    },
    {
      alias: 'facebook',
      icon: 'facebook-f',
      url: 'https://www.facebook.com/lendwithcare',
    },
    {
      alias: 'x',
      icon: 'x-twitter',
      url: 'https://x.com/lendwithcare',
    },
    {
      alias: 'linkedin',
      icon: 'linkedin-in',
      url: 'https://www.linkedin.com/company/lendwithcare',
    },
    {
      alias: 'instagram',
      icon: 'instagram',
      url: 'https://www.instagram.com/lendwithcare',
    },
  ],
})

const mutations = {}

const actions = {}

const getters = {
  socialProviders: state => state.social,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
