/** @module modules/admin/locales/en */

export default {
  nav: {
    business: `Business`,
    campaigns: `Campaigns`,
    contentAndSettings: `Content & Settings`,
    cms: `CMS`,
    entrepreneurs: `Entrepreneurs`,
    grants: `Grants`,
    income: `Income`,
    lending: `Lending`,
    loans: `Loans`,
    loanMessages: {
      index: `Loan messages`,
    },
    offsetting: {
      archive: `Offsetting Archive`,
      current: `Current Offset Report | Current Offset Report | Current Offset Reports`,
      section: `Offset Reports`,
    },
    grantReporting: {
      archive: `Grant Report Archive`,
      current: `Current Grant Report | Current Grant Report | Current Grant Reports`,
      section: `Grant Reports`,
    },
    grantRepayments: {
      archive: `Archive`,
      due: `Due`,
      section: `Grant Repayments`,
    },
    repayments: {
      archive: `Archive`,
      index: `Pending`,
      section: `Repay Lenders`,
    },
    reporting: {
      donations: `Donations`,
      entrepreneurAndLoanData: `Entrepreneur & Loan Data`,
      giftaidData: `GiftAid Data`,
      reconciliation: `Reconciliation Report`,
      section: `Reporting`,
      userChanges: `User Changes`,
      userData: `User Data`,
    },
    siteSettings: `Site Settings`,
    updates: `Updates`,
    users: {
      bulkCreate: `Bulk Create Users`,
      reUrnBulkUpdate: `Bulk Update RE URNs`,
      search: `Users`,
      section: `Users`,
    },
    mfis: {
      mfiInfoMessages: `MFI Info Messages`,
      section: `MFIs`,
    },
    vouchers: {
      bulkPurchases: `Bulk Purchases`,
      listing: `Gift Vouchers`,
      section: `Gift Vouchers`,
    },
    withdrawals: {
      batches: `Batches`,
      completed: `Completed`,
      pending: `Pending`,
      requested: `Requested`,
      section: `Withdrawals`,
    },
  },
  pages: {
    index: {
      heading: 'Welcome{name}',
    },
  },
}
