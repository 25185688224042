import Entity from '~/contracts/entity'

export default class UserSettings extends Entity {
  _transformers () {
    return {
      careNewsletter: Boolean,
      donateRepayments: Boolean,
      emailContact: Boolean,
      giftAid: Boolean,
      loanUpdateEmail: Boolean,
      lwcNewsletter: Boolean,
      postContact: Boolean,
      smsContact: Boolean,
      telContact: Boolean,
    }
  }

  _apiMapping () {
    return {
      care_newsletter: 'careNewsletter',
      donate_repayments: 'donateRepayments',
      email_contact: 'emailContact',
      gift_aid: 'giftAid',
      giftaid: 'giftAid',
      loan_update_email: 'loanUpdateEmail',
      lwc_newsletter: 'lwcNewsletter',
      post_contact: 'postContact',
      sms_contact: 'smsContact',
      tel_contact: 'telContact',
    }
  }
}
