import Entity, { Transformer } from '~/contracts/entity'

export default class AuthConfig extends Entity {
  _transformers () {
    return {
      counties: Transformer.item(Object),
      countries: Transformer.collection(Object),
      displayAs: Transformer.collection(Object),
      genders: Transformer.collection(Object),
      sources: Transformer.collection(Object),
      titles: Transformer.collection(Object),
      types: Transformer.collection(Object),
    }
  }

  _apiMapping () {
    return {
      ukCounties: 'counties',
    }
  }
}
