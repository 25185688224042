import Entity, { Transformer } from '~/contracts/entity'
import GrantImage from './grant-image'

export default class MicrograntGridItem extends Entity {
  _transformers () {
    return {
      activity: String,
      amount: Number,
      createdAt: Transformer.date(),
      entrepreneurDisplayName: String,
      entrepreneurGender: String,
      entrepreneurId: Number,
      entrepreneurType: String,
      error: Boolean,
      errorMessage: String,
      id: Number,
      image: Transformer.itemFromPrimitive(GrantImage, 'publicId'),
      grantId: Number,
      country: String,
      teamId: Number,
      teamName: String,
      teamUuid: String,
    }
  }

  get entrepreneur () {
    return this.entrepreneurDisplayName
  }

  get isAttributed () {
    return this.teamId !== null
  }

  get location () {
    return this.country
  }

  get grantRoute () {
    return {
      name: 'grants:frontend:show',
      params: {
        grantId: this.grantId,
      },
    }
  }
}
