import _distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import _format from 'date-fns/format'
import _get from 'lodash/get'
import _isValidDate from 'date-fns/is_valid'
import _defaults from 'lodash/defaults'
import _isNil from 'lodash/isNil'
import _toNumber from 'lodash/toNumber'
import _toSafeInteger from 'lodash/toSafeInteger'
import _truncate from 'lodash/truncate'
import { money as _money } from '~/utils/format'

export function boolean (value) {
  switch (value) {
    case true:
    case 'true':
    case 'True':
    case 1:
    case 'yes':
    case 'Yes':
      return 'Yes'
    case 'false':
    case 'False':
    case false:
    case 0:
    case 'no':
    case 'No':
      return 'No'
  }

  return ''
}

/**
 * Format date object as string.
 * @param {Date} value - Date to format.
 * @param {{}} options
 * @param {string} options.format
 * @return {string}
 */
export function date (value, options = {}) {
  try {
    if (!_isValidDate(value)) return ''
  } catch (err) {
    return ''
  }

  const format = options.format || 'D MMM YYYY'

  return _format(value, format)
}

/**
 * Formats integer as monetary amount.
 * @param {number} value - Value to format - *MUST* be an integer.
 * @param {{}} options
 * @param {string} [options.currency='GBP']
 * @param {string} [options.locale='en-GB']
 * @returns {string}
 */
export function money (value, options = {}) {
  const currency = options.currency || 'GBP'
  const locale = options.locale || 'en-GB'
  value = _toSafeInteger(value)

  return _money(value, currency, locale)
}

/**
 * Format number according to user's locale.
 * @param {number\*} value
 * @param options
 * @param options.locale
 * @return {*}
 */
export function number (value, options = {}) {
  const num = _toNumber(value)
  const locale = options.locale || 'en-GB'

  if (Number.isNaN(num)) return value

  return num.toLocaleString(locale)
}

/**
 * Return a stats item label as a string.
 * @param {{}} item
 * @param {string|*} item.label
 * @return {string}
 */
export function statsItemLabel (item) {
  const label = _get(item, 'label', '')

  return typeof label === 'string' ? label : ''
}

export function statsItemPercentage (item) {
  const value = _toNumber(_get(item, 'percentage', 0))

  return Number.isNaN(value) ? '0%' : number(value) + '%'
}

/**
 * Return stats item value as a string, based on the value type.
 * Available types:
 * - amount;
 * - number.
 * If the "type" is "amount", it is expected that the item also has "currency"
 * property ("GBP" by default).
 * @param {{}} item
 * @param {string} [item.currency=GBP]
 * @param {number} [item.value=0]
 * @param {string} [item.type=number]
 * @return {string}
 */
export function statsItemValue (item) {
  const type = _get(item, 'type')

  function _getValueAsNumber (item) {
    const casted = _toNumber(_get(item, 'value', 0))

    return Number.isNaN(casted) ? 0 : casted
  }

  function _renderAmount (item) {
    const value = _getValueAsNumber(item)
    const currency = _get(item, 'currency', 'GBP')

    return money(value, { currency })
  }

  function _renderNumber (item) {
    const value = _getValueAsNumber(item)

    return (typeof value !== 'number' || Number.isNaN(value)) ? '0' : number(value)
  }

  switch (type) {
    case 'amount':
      return _renderAmount(item)
    case 'number':
      return _renderNumber(item)
    default:
      return ''
  }
}

/**
 * Return the distance between the `value` date and now in natural language.
 * @param {Date} value
 * @return {string}
 */
export function timeSince (value) {
  if (!(value instanceof Date)) return ''

  return _distanceInWordsToNow(value, { addSuffix: true })
}

/**
 * Truncates string if it's longer than the given maximum string length.
 * @see https://lodash.com/docs/4.17.10#truncate
 * @param {*} value
 * @param {{}} [options={}]
 * @param {number} [options.length=250] - The maximum string length.
 * @param {string} [options.omission=' […]'] - The string to indicate text is omitted.
 * @param {RegExp|string} [options.separator=/,? +/] - The separator pattern to truncate to.
 * @return {string}
 */
export function truncate (value, options = {}) {
  if (_isNil(value)) return value

  const defaults = {
    length: 250,
    omission: ' […]',
    separator: /,? +/,
  }

  return _truncate(String(value), _defaults(options, defaults))
}

export default {
  boolean,
  date,
  money,
  number,
  statsItemLabel,
  statsItemPercentage,
  statsItemValue,
  timeSince,
  truncate,
}
