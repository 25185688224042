import { getInstance } from '~/plugins/http'
import { apiRoutes } from '~/modules/repayments/config'

export default {
  namespaced: true,

  actions: {
    async exclude (store, data = []) {
      await getInstance().post(apiRoutes.admin.exclude, { data })
    },
    async include (store, data = []) {
      await getInstance().post(apiRoutes.admin.include, { data })
    },
    async release (store) {
      await getInstance().post(apiRoutes.admin.release)
    },
  },
  state () {
    return {}
  },
}
