import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    search: {
      config: {
        loaded: false,
        perPage: 15,
        page: 1,

        activities: [],
        locations: [],
        genders: [],
        statuses: [],
      },
      defaults: {
        activity: [],
        gender: null,
        location: null,
        page: 1,
        q: null,
        sort: 'random',
        status: 'fundable',
      },
      params: null,
      results: {
        currentPage: 1,
        items: [],
        pages: 1,
        total: 0,
      },
      inProgress: false,
    },
    current: null,
  },

  actions,
  getters,
  mutations,
}
