export const apiRoutes = {
  donations: {
    fetchConfig: '/admin/reporting/donations/options',
    fetch: '/admin/reporting/donations/stats/<%= period %>',
  },
  entrepreneursAndLoans: {
    config: '/admin/reporting/loans/options',
    download: '/admin/reporting/loans/downloads/data/<%= mfiId %>/<%= year %>-<%= month %>',
  },
  giftAid: {
    config: '/admin/reporting/giftaid/options',
    download: '/admin/reporting/giftaid/downloads/changes/<%= period %>',
  },
  reconciliation: {
    fetchConfig: '/admin/reporting/reconciliation/options',
    fetch: '/admin/reporting/reconciliation/<%= period %>',
    download: {
      download: '/admin/reporting/reconciliation/downloads/month-end/<%= period %>',
      request: '/admin/reporting/reconciliation/generate',
    },
  },
  stats: {
    business: '/admin/reporting/stats/business',
    loans: '/admin/reporting/stats/loans',
    users: '/admin/reporting/stats/lenders',
    offsetting: '/admin/reporting/stats/offsetting',
  },
  users: {
    changes: {
      config: '/admin/reporting/users/changes/options',
      download: '/admin/reporting/users/downloads/changes/<%= period %>',
    },
    data: {
      download: '/admin/reporting/users/downloads/data',
      generate: '/admin/reporting/users/data/generate',
      status: '/admin/reporting/users/data/status',
    },
  },
}

/**
 * User permissions to check when rendering navigation or before performing actions
 * @type {{mfi: string, ho: string}}
 */
export const permissions = {
  ho: 'ho-business-reporting',
  mfi: 'mfi-reporting',
}
