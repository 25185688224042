import Entity, { Transformer } from '~/contracts/entity'
import MFI from '~/modules/mfis/entities/mfi'
import format from 'date-fns/format'
import isValidDate from 'date-fns/is_valid'
import Lwc2mfiPaymentUsd from './lwc2mfi-payment-usd'
import FundedBatch from './funded-batch'
import ReportRate from './grant-report-rate'
import Payment from './payment'
import ReportTotals from './grant-report-totals'

export default class GrantReport extends Entity {
  _transformers () {
    return {
      id: Number,
      grantMonth: Transformer.date(),
      localCurrency: String,
      exchangeRate: Transformer.item(ReportRate),
      fundedBatch: Transformer.item(FundedBatch),
      fundedBatchId: Number,
      completed: Boolean,
      completedAt: Transformer.date(),
      direction: String,
      lwc2mfiPaymentUsd: Transformer.item(Lwc2mfiPaymentUsd),
      mfi: Transformer.item(MFI),
      payment: Transformer.item(Payment),
      totals: Transformer.item(ReportTotals),
    }
  }

  get month () {
    return isValidDate(this.reportMonth)
      ? format(this.reportMonth, 'MMMM')
      : null
  }

  _apiMapping () {
    return {
      grantFundedBatch: 'fundedBatch',
      grantFundedBatchId: 'fundedBatchId',
    }
  }
}
