import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { DEFAULT_CREDIT_CONFIG } from '~/modules/users/store/admin/constants'

export default {
  namespaced: true,

  actions,
  getters,
  mutations,

  state: () => ({
    credit: {
      config: Object.assign({}, DEFAULT_CREDIT_CONFIG),
    },
    manageableRoles: null,
    transactions: null,
    user: null,
    users: null,
    usersPagination: null,
  }),
}
