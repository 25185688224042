/**
 * @const {string}
 */
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS'

/**
 * @const {string}
 */
export const SET_DASHBOARD_FIGURES = 'SET_DASHBOARD_FIGURES'

/**
 * @const {string}
 */
export const SET_DASHBOARD_STATS = 'SET_DASHBOARD_STATS'

/**
 * @const {string}
 */
export const SET_PAGE_HEADING = 'SET_PAGE_HEADING'

/**
 * @const {string}
 */
export const SET_PAGE_SUBHEADING = 'SET_PAGE_SUBHEADING'

/**
 * @const {String}
 */
export const TOGGLE_SIDEBAR_HIDDEN = 'TOGGLE_SIDEBAR_HIDDEN'

/**
 * @const {String}
 */
export const TOGGLE_SIDEBAR_MINIMISED = 'TOGGLE_SIDEBAR_MINIMISED'

/**
 * @const {String}
 */
export const TOGGLE_MOBILE_SIDEBAR = 'TOGGLE_MOBILE_SIDEBAR'
