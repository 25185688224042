module.exports = resolve => [
  {
    name: 'auth:register',
    path: '/register/:referralCode?',
    component: resolve(__dirname, 'pages/register'),
  },
  {
    name: 'auth:login',
    path: '/login',
    component: resolve(__dirname, 'pages/login'),
  },
  {
    name: 'auth:forgot-password',
    path: '/forgot-password',
    component: resolve(__dirname, 'pages/forgot-password'),
  },
  {
    name: 'auth:reset-password',
    path: '/reset-password',
    component: resolve(__dirname, 'pages/reset-password'),
  },
]
