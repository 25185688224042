import get from 'lodash/get'
import merge from 'lodash/merge'
import Vue from 'vue'

const plugin = {
  install (Vue) {
    Vue.prototype.$get = get
    Vue.prototype.$merge = merge
  },
}

export default (context) => {
  context.lodash = {
    get,
    merge,
  }

  Vue.use(plugin)
}
