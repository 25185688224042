import merge from 'lodash/merge'
import set from 'lodash/set'

/**
 * Mutations available in all environments.
 * @type {{SET_CONFIG: mutations.SET_CONFIG, SET_VOUCHER: mutations.SET_VOUCHER}}
 */
let mutations = {
  SET_CONFIG: (state, config = null) => { state.config = config },
  SET_VOUCHER: (state, voucher = null) => { state.current = voucher },
}

/**
 * Mutations available only in development mode.
 */
if (process.env.NODE_ENV === 'development') {
  mutations = merge({}, mutations, {
    DEV_TURN_BOGOF_OFF: state => { set(state, 'config.settings.bogof', false) },
    DEV_TURN_BOGOF_ON: state => { set(state, 'config.settings.bogof', true) },
  })
}

export default mutations
