import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    config: null,
    current: null,
  },

  actions,
  getters,
  mutations,
}
