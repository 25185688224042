export const apiRoutes = {
  admin: {
    impersonate: '/admin/users/<%= userId %>/impersonate',
    stop: '/admin/users/<%= userId %>/stop-impersonating',
  },
}

export default {
  apiRoutes,
}
