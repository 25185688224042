/** @module modules/basket/config */

/**
 * @typedef {object} Basket~Config~ApiRoutes
 * @property {string} add
 *
 * @property {{}} admin.repayments
 * @property {string} admin.repayments.index
 */
export const apiRoutes = {
  frontend: {
    addItem: '/basket/add-item',
    get: '/basket/<%= basketUuid %>',
    removeItem: '/basket/remove-item',
    setDonation: '/basket/set-donation',
    setTeam: '/basket/<%= basketUuid %>/set-team',
    setUser: '/basket/<%= basketUuid %>/set-user',
    applyBonus: '/basket/<%= basketUuid %>/apply-bonus',
    removeBonus: '/basket/<%= basketUuid %>/remove-bonus',
  },
}

/**
 * How long should the interval be between the last basket "fetch" action
 * and the new one.
 * @const {number}
 */
export const cacheTtl = 2000

/**
 * Key under which basket UUID should be stored in local storage.
 * @const {string}
 */
export const localStorageId = 'basketUuid'

/**
 * Shared basket queue name.
 * @const {string}
 */
export const queueName = 'basket'

export default {
  apiRoutes,
  cacheTtl,
  localStorageId,
  queueName,
}
