/** @module modules/entrepreneurs/locales/en */

export default {
  config: {
    genders: {
      m: 'Male',
      f: 'Female',
      x: 'Mixed',
    },
  },
  pages: {
    dashboard: {
      breadcrumb: 'Entrepreneurs',
      heading: 'Entrepreneurs',
    },
    add: {
      breadcrumb: 'Add Entrepreneur',
      heading: 'Add Entrepreneur',
    },
    edit: {
      breadcrumb: 'Edit Entrepreneur',
      heading: 'Edit Entrepreneur',
    },
  },
  breadcrumbs: {
    admin: {
      create: 'Add entrepreneur',
      edit: 'Edit entrepreneur',
      index: 'Entrepreneurs',
      selectMfi: 'Select MFI',
    },
  },
}
