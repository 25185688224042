import Entity, { Transformer } from '~/contracts/entity'

export default class UserEmailChanges extends Entity {
  _transformers () {
    return {
      id: Number,
      emailBefore: String,
      email: String,
      ip: String,
      createdAt: Transformer.date(),
    }
  }
}
