/**
 * Perform a full page reload is user is coming from the admin area
 * to the fronted, or the other way round.
 *
 * This is a workaround for vue-style-loader default behaviour, that
 * leaves behind stylesheets defined in components, unless they are
 * defined as "scoped". Unfortunately, with CSS frameworks it's not
 * that easy, as scoped stylesheet won't affect styles of layout's
 * children.
 *
 * In order to get rid of those leftover stylesheets, the Router needs
 * to force the browser to do a full reload, therefore fetching
 * the layout only for the particular site area.
 *
 * @param context
 */
export default function (context) {
  if (typeof window === 'undefined') return

  // Copy path strings to new strings (without referencing them).
  const prev = context.from.fullPath.slice(0)
  const current = context.route.fullPath.slice(0)
  const prefix = '/admin'

  if (
    (prev.startsWith(prefix) && !current.startsWith(prefix)) ||
    (!prev.startsWith(prefix) && current.startsWith(prefix))
  ) {
    window.location.href = context.route.fullPath
  }
}
