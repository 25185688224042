import DonationsSubmodule from './submodules/donations'
import ReconciliationSubmodule from './submodules/reconciliation'
import StatsSubmodule from './submodules/stats'

const reportingStore = {
  namespaced: true,

  modules: {
    donations: DonationsSubmodule,
    reconciliation: ReconciliationSubmodule,
    stats: StatsSubmodule,
  },

  state: {
    installed: true,
  },
}

export default reportingStore
