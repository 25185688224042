import round from 'lodash/round'
import EventBus from '~/bus'
import {
  EVENT_JOB_ADDED,
  EVENT_JOB_FINISHED,
  EVENT_JOB_STARTED,
  EVENT_QUEUE_FINISHED,
} from './events'
import Job from './job'
import { error, warn } from '~/utils/errors'

export default class Queue {
  constructor (name, runner, priority = 10) {
    this.name = name
    this.priority = _normalizePriority(priority)
    this.jobs = []
    this.completed = []
    this.failed = []
    this.isRunning = false
    this.runner = runner
    this.running = []

    EventBus.$on(EVENT_JOB_STARTED, _onJobStarted.bind(this))
    EventBus.$on(EVENT_JOB_FINISHED, _onJobFinished.bind(this))
  }

  /**
   * This getter will return "false" at soonest after the first job is
   * either completed or failed.
   * @return {boolean}
   */
  get safeIsRunning () {
    return this.isRunning || (
      !this.completed.length && !this.failed.length
    )
  }

  get store () {
    return this.runner.$store
  }

  /**
   * Push a new job object to the queue.
   * @param definition
   * @return {Job}
   */
  addJob (definition) {
    try {
      const job = new Job(definition, this)
      this.jobs.push(job)

      EventBus.$emit(EVENT_JOB_ADDED, job)

      return job
    } catch (e) {
      error(e)
    }
  }

  destroy () {
    if (this.name === 'default') {
      warn('You cannot destroy the default queue.')
      return
    }

    this.runner.removeQueue(this.name)
  }
}

/**
 * Set self as "running" on job start.
 *
 * @param {Job} job
 * @private
 */
function _onJobStarted (job) {
  if (job.queue === this) {
    this.isRunning = true
    this.running.push(job)
    this.jobs = this.jobs.filter(j => j !== job)
  }
}

/**
 * React to job's finished event.
 * @param {Job} job
 * @private
 */
function _onJobFinished (job) {
  if (job.queue === this) {
    this.running = this.running.filter(j => j !== job)

    const target = job.isSuccessful ? this.completed : this.failed
    target.push(job)

    this.isRunning = this.running.length > 0 || this.jobs.length > 0

    if (!this.jobs.length) {
      this.runner.$emit(EVENT_QUEUE_FINISHED, this)
    }
  }
}

/**
 * Normalize passed in priority argument. It needs to be a positive integer. In case
 * a string was passed in, try to cast the value to integer. If all else fails, return
 * a default priority of 10.
 *
 * @param {number|string} priority
 * @return {number}
 * @private
 */
function _normalizePriority (priority) {
  let defaultPriority = 10

  if (typeof priority === 'number') {
    if (Number.isNaN(priority)) {
      return defaultPriority
    }
    if (priority < 1) {
      return 1
    }

    return priority
  }

  if (typeof priority === 'string') {
    const casted = round(parseInt(priority), 0)

    return Number.isNaN(casted)
      ? defaultPriority
      : casted
  }

  return defaultPriority
}
