import AdminWithdrawal from '~/modules/withdrawals/entities/admin/withdrawal'
import { itemToEntity } from '~/utils/framework'

export const SET_PENDING = 'SET_PENDING'

export const SET_REQUESTED = 'SET_REQUESTED'

export default {
  [SET_PENDING] (state, items) {
    if (!Array.isArray(items)) state.pending = null

    state.pending = items.map(item => itemToEntity(item, AdminWithdrawal))
  },
  [SET_REQUESTED] (state, items) {
    if (!Array.isArray(items)) state.requested = null

    state.requested = items.map(item => itemToEntity(item, AdminWithdrawal))
  },
}
