export const apiRoutes = {
  admin: {
    bulkPublish: '/admin/grants/publish',
    bulkStatus: '/admin/grants/status',
    create: '/admin/grants',
    get: '/admin/grants/<%= grantId %>',
    index: '/admin/grants',
    grantsConfig: '/admin/grants/options',
    messages: {
      create: '/admin/grants/<%= grantId %>/messages',
    },
    status: '/admin/grants/<%= grantId %>/status',
    template: '/admin/grants/download/template',
    update: '/admin/grants/<%= grantId %>',
    grantAttachment: {
      create: '/admin/grants/<%= grantId %>/attachment',
      get: '/admin/grants/<%= grantId %>/attachment',
      download: '/admin/grants/<%= grantId %>/attachment/download',
      delete: '/admin/grants/<%= grantId %>/attachment',
    },
  },
  frontend: {
    get: '/grants/<%= grantId %>',
    index: '/grants',
    searchConfig: '/grants/search-config',
  },
}

/**
 * The prefix to images' public IDs.
 * @type {string}
 */
export const imagePublicIdPrefix = 'lwc/grants'

/**
 * The prefix to location map images' public IDs.
 * @type {string}
 */
export const locationMapImagePublicIdPrefix = 'lwc'

/**
 * Preset name for uploading a grant image to Cloudinary.
 * @const {string}
 */
export const imageUploadPresetName = 'grant_photo'

/**
 * Available amounts for a "quick lend" action.
 * @type {number[]}
 */
export const pricePoints = [
  1500, 3000, 4500, 6000, 7500, 10000, 15000, 20000, 25000, 30000, 50000, 100000,
]

/**
 * Module permissions to use when displaying navigation items
 * @type {{ho: string}}
 */
export const permissions = {
  ho: 'ho-grants',
  mfi: 'mfi-grants',
}

/**
 * This is the URL to show the users if they'd like to read more about grants.
 * At the time of writing this it's only used in an info box in the grant
 * details page.
 * @type {string}
 */
export const grantsKnowledgeBaseArticleURL = '/info/help-centre/grants-help-centre'

/**
 * @typedef {Object} Grants~Config
 * @property {Grants~Config~ApiRoutes} apiRoutes
 * @property {string} imageUploadPresetName
 */
/**
 * @type {Grants~Config}
 */
export default {
  apiRoutes,
  imageUploadPresetName,
  imagePublicIdPrefix,
  pricePoints,
}
