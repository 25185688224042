import get from 'lodash/get'
import { apiRoutes } from '~/libs/currencies/config'
import { getInstance } from '~/plugins/http'

/**
 * @typedef {object} CurrencyExchangeRateAPIObject
 * @property {number} id
 * @property {string} fromCurrency - Source 3-letter currency code.
 * @property {string} toCurrency - Target 3-letter currency code.
 * @property {string} rate - Source to target exchange rate; string representation of a decimal.
 * @property {string} inverseRate - Target to source exchange rate; string representation of a decimal.
 */
/**
 * Perform a call to the API and fetch exchange rates for the day.
 * @returns {{}}
 */
async function fetchCurrentExchangeRates ({ commit, state }) {
  const http = getInstance()
  const config = {
    params: {
      latest: true,
    },
  }
  const response = await http.get(apiRoutes.admin.index, config)
  /** @type {CurrencyExchangeRateAPIObject[]} */
  const rates = get(response, 'data.data', [])

  // Remove all previous mappings.
  commit('CLEAR')

  // Set each currency exchange rate object in the state.
  rates.forEach(rate => {
    commit('SET', {
      from: rate.fromCurrency,
      to: rate.toCurrency,
      rate: rate.rate,
      inversed: rate.inverseRate,
    })
  })

  return state
}

export default {
  fetchCurrentExchangeRates,
}
