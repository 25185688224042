/** @module modules/auth/store */

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import AuthUser from '~/modules/auth/entities/auth-user'

/**
 * @typedef {object} AuthStore
 * @property {boolean} namespaced
 * @property {AuthStore~State} state
 * @property {AuthStore~Getters} getters
 * @property {AuthStore~Actions} actions
 * @property {AuthStore~Mutations} mutations
 */

/**
 * @type {AuthStore}
 */
export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  /** @type {{}} AuthStore~State */
  state: {
    config: {},
    loggingOut: false,
    settingsConfig: {},
    token: null,
    user: null,
  },
  persist: [
    { name: 'token', transform: data => String(data) },
    { name: 'user', transform: data => new AuthUser(data) },
  ],
}
