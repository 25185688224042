/** @module modules/mfis */

export default {
  /**
   * Get current MFI from the state.
   * @param {MFIs~State} state
   * @returns {(MFIs~MFI|null)}
   */
  mfi: state => state.current,

  /**
   * Get current MFI info message from the state.
   * @param {MFIs~State} state
   * @returns {(MFIs~MFI|null)}
   */
  mfiInfoMessage: state => state.current,
}
