import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'

const CURRENCY = 'GBP'

export default class BonusAvailability extends Entity {
  _transformers () {
    return {
      bonusType: String,
      amount: Number,
      applied: Boolean,
      amountApplied: Number
    }
  }

  get formattedAmount () {
    return formatAmount(this.amount, CURRENCY, true)
  }

  get formattedAmountApplied () {
    return formatAmount(this.amountApplied, CURRENCY, true)
  }
}
