import { Transformer } from '~/contracts/entity'
import MicrograntGridItem from '~/modules/grants/entities/frontend/microgrant-grid-item'
import Team from '~/modules/teams/entities/frontend/team'
import Transaction from './transaction'

export default class MicrograntTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'type', {
      enumerable: true,
      value: 'microgrant',
    })
  }

  _transformers () {
    const transformers = super._transformers()

    return Object.assign({}, transformers, {
      grant: Transformer.item(MicrograntGridItem),
      team: { default: null, type: Transformer.item(Team) },
    })
  }
}
