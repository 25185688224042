/** @module entrepreneurs/config */

/**
 * API URI for fetching entrepreneurs configuration options.
 * @const {string}
 */
export const fetchConfigURI = '/admin/entrepreneur/options'

/**
 * @typedef {Object} Entrepreneurs~Config~Gender
 * @property {string} value
 */
/**
 * Gender choices available for entrepreneurs. "mixed" option should be allowed
 * only in group context.
 * @type {Entrepreneurs~Config~Gender[]}
 */
export const genders = [
  { value: 'f' },
  { value: 'm' },
  { value: 'x' },
]

/**
 * @typedef {object} Entrepreneurs~Config~ApiRoutes
 * @property {object} admin
 * @property {string} admin.get
 * @property {string} admin.index
 * @property {string} admin.store
 * @property {string} admin.template
 * @property {string} admin.update
 */
export const apiRoutes = {
  admin: {
    config: '/admin/entrepreneur/options',
    get: '/admin/entrepreneur/<%= entrepreneurId %>',
    index: '/admin/entrepreneur',
    store: '/admin/entrepreneur',
    template: '/admin/entrepreneur/download/template',
    update: '/admin/entrepreneur/<%= entrepreneurId %>',
  },
}

/**
 * @typedef {Object} Entrepreneurs~Config
 * @property {Entrepreneurs~Config~ApiRoutes} apiRoutes
 * @property {Entrepreneurs~Config~Gender[]} genders
 */
/**
 * @type {Entrepreneurs~Config}
 */
export default {
  apiRoutes,
  genders,
}
