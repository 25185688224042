import get from 'lodash/get'
import { Transformer } from '~/contracts/entity'
import MicrograntGridItem from '~/modules/grants/entities/frontend/microgrant-grid-item'
import MicroloanGridItem from '~/modules/loans/entities/frontend/microloan-grid-item'
import Transaction from './transaction'

/** @const {string} Repayment type used to identify regular loan repayments */
export const REPAYMENT_TYPE_LOAN = 'loan'
/** @const {string} Repayment type used to identify grant buffer repayments */
export const REPAYMENT_TYPE_GRANT_BUFFER = 'grantBuffer'
/** @const {string} Repayment type used to identify repayments made due to grant cancellations */
export const REPAYMENT_TYPE_GRANT_CANCELLATION = 'grantCancellation'
/** @const {string} Repayment type used to identify loan buffer repayments */
export const REPAYMENT_TYPE_LOAN_BUFFER = 'loanBuffer'
/** @const string} Repayment type used to identify repayments made due to loan cancellations */
export const REPAYMENT_TYPE_LOAN_CANCELLATION = 'loanCancellation'

export const repaymentTypes = {
  REPAYMENT_TYPE_LOAN,
  REPAYMENT_TYPE_GRANT_BUFFER,
  REPAYMENT_TYPE_GRANT_CANCELLATION,
  REPAYMENT_TYPE_LOAN_BUFFER,
  REPAYMENT_TYPE_LOAN_CANCELLATION,
}

/**
 * Map a repayment type received from the API to a standardized value,
 * re-usable across the system.
 * @param {string} originalType Type received from the API
 * @returns {string|null}
 */
function mapLoanRepaymentType (originalType) {
  switch (originalType) {
    case 'loan_repay':
      return REPAYMENT_TYPE_LOAN
    case 'buffer_repay':
      return REPAYMENT_TYPE_LOAN_BUFFER
    case 'grant_buffer_re':
      return REPAYMENT_TYPE_GRANT_BUFFER
    case 'grant_canc_re':
      return REPAYMENT_TYPE_GRANT_CANCELLATION
    case 'loan_canc_repay':
      return REPAYMENT_TYPE_LOAN_CANCELLATION
    default:
      return null
  }
}

export default class RepaymentTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    const originalRepaymentType = get(data, 'repaymentType', null)

    Object.defineProperties(this, {
      repaymentType: {
        enumerable: true,
        value: mapLoanRepaymentType(originalRepaymentType),
      },
      type: {
        enumerable: true,
        value: 'repayment',
      },
    })
  }

  _transformers () {
    return Object.assign({}, super._transformers(), {
      grant: Transformer.item(MicrograntGridItem),
      loan: Transformer.item(MicroloanGridItem),
    })
  }
}
