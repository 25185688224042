import Entity from '~/contracts/entity'

export default class GrantReportTotalsGrants extends Entity {
  _transformers () {
    return {
      excluded: Number,
      included: Number,
    }
  }
}
