/** @module modules/updates/store/getters */

import get from 'lodash/get'

/**
 * @typedef {Object} Updates~Store~Getters
 * @property {function} update
 * @property {function} config
 */
/**
 * @type {Updates~Store~Getters}
 */
export default {
  update: state => state.current,
  config: state => state.config,
  statuses: state => get(state.config, 'updateStatuses'),
}
