/** @module users/store/getters */

/**
 * @param {Users~State} state
 * @return {Loan~Microloan[]|null}
 */
const microloans = state => state.microloans

/**
 * @param {Users~State} state
 * @return {Users~User|null}
 */
const user = state => state.current

/**
 * @typedef {{}} Users~Frontend~State
 * @property {function} microloans
 * @property {function} user
 */
export default {
  microloans,
  user,
}
