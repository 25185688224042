import template from 'lodash/template'
import { apiRoutes } from '~/modules/vouchers/config'
import { getInstance } from '~/plugins/http'

export default {
  namespaced: true,

  actions: {
    async resend (store, { voucherId }) {
      const uri = template(apiRoutes.admin.resend)({ voucherId })

      await getInstance().post(uri)

      return true
    },
  },
  state () {
    return {}
  },
}
