import BaseImage from '~/entities/image'
import { imagePublicIdPrefix } from '~/modules/updates/config'
import { getCloudinaryURL } from '~/utils/cloudinary'

const defaultImage = '/lwc/updates/avatar_single_update.gif'

export default class UpdateImage extends BaseImage {
  /**
   * Getter for the update image's public ID prefix.
   */
  get prefix () {
    return imagePublicIdPrefix
  }

  /**
   * Return a URL to the image resized to 400x400 px.
   * @returns {string}
   */
  get small () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 400,
    })
  }

  /**
   * Return a URL to the default image resized to 400x400 px.
   * @returns {string}
   */
  get smallDefault () {
    return getCloudinaryURL(defaultImage, {
      ...this.cloudinaryParams,
      width: 400,
    })
  }

  /**
   * Return a URL to the image resized to 500x500 px.
   * @returns {string}
   */
  get medium () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 500,
    })
  }

  /**
   * Return a URL to the default image resized to 500x500 px.
   * @returns {string}
   */
  get mediumDefault () {
    return getCloudinaryURL(defaultImage, {
      ...this.cloudinaryParams,
      width: 500,
    })
  }

  /**
   * Return a URL to the image resized to 800x800 px.
   * @returns {string}
   */
  get large () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 800,
    })
  }
}
