import get from 'lodash/get'
import set from 'lodash/set'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/reporting/config'
import { getInstance } from '~/plugins/http'

export default {
  namespaced: true,

  actions: {
    async fetch (store, period) {
      store.commit('SET_REPORT', null)

      const URI = template(apiRoutes.reconciliation.fetch)({ period })
      const response = await getInstance().get(URI)

      store.commit('SET_FILE_STATUS', get(response, 'data.data.fileStatus', 'empty'))
      store.commit('SET_REPORT', get(response, 'data.data.monthEnd', null))

      return store.getters.report
    },
    async fetchConfig (store) {
      const URI = apiRoutes.reconciliation.fetchConfig

      const response = await getInstance().get(URI)

      store.commit('SET_PERIODS', get(response, 'data.data.config.months', []))

      return store.getters.periods
    },
    async requestFile (store, { period }) {
      const response = await getInstance().post(apiRoutes.reconciliation.download.request, {
        month: period,
      })
      const code = get(response, 'status', 500)

      if (code === 204) {
        store.commit('SET_FILE_STATUS', 'pending')
      }

      return store.getters['fileStatus']
    },
  },
  getters: {
    fileStatus: state => get(state, 'fileStatus', null),
    periods: state => get(state, 'config.periods'),
    report: state => get(state, 'report'),
  },
  mutations: {
    SET_FILE_STATUS: (state, fileStatus) => set(state, 'fileStatus', fileStatus),
    SET_PERIODS: (state, periods) => set(state, 'config.periods', periods),
    SET_REPORT: (state, report) => set(state, 'report', report),
  },
  state: {
    config: {
      periods: null,
    },
    report: null,
    /**
     * Available file statuses served by the API:
     * - "empty",
     */
    fileStatus: null,
  },
}
