import get from 'lodash/get'
import Entity, { Transformer } from '~/contracts/entity'
import Loan from '~/modules/loans/entities/frontend/loan'
import UpdateImage from './update-image'

export default class LoanUpdate extends Entity {
  get showRoute () {
    const params = {
      loanId: Number(get(this.loan, 'id')),
      updateId: Number(get(this, 'id')),
    }

    if (Object.values(params).some(param => Number.isNaN(param))) {
      this.$sentry.captureMessage('Unable to construct loan update frontend display route', {
        extra: {
          update: this,
          params,
        },
      })
      return ''
    }

    return {
      name: 'updates:frontend:show',
      params,
    }
  }

  _transformers () {
    return {
      approvedAt: Transformer.date(),
      body: String,
      createdAt: Transformer.date(),
      id: Number,
      image: Transformer.itemFromPrimitive(UpdateImage, 'publicId'),
      loan: Transformer.item(Loan),
      status: String,
      title: String,
      updatedAt: Transformer.date(),
      video: String,
    }
  }

  _apiMapping () {
    return {
      loanId: 'loan.id',
    }
  }
}
