import get from 'lodash/get'

export function createCmsInfoFlatMap (structure) {
  if (!structure) return null

  const mapped = {}

  function extract (item) {
    mapped[item.uri] = item.id

    item.children.forEach(child => extract(child))
  }

  extract(structure)

  return mapped
}

export function parseCmsInfoStructure (articles) {
  /**
   * Step 1. Find root.
   */
  const root = articles.find(article => article.type === 'root')

  // If no root found, we really shouldn't proceed.
  if (!root) return {}

  return new ArticleStructure(root, null, articles)
}

function findChildren (item, articles) {
  return articles
    .filter(childItem => get(childItem, 'data.parent.id') === item.id)
    .map(childItem => new ArticleStructure(childItem, item, articles))
}

class ArticleStructure {
  constructor (data, parent, articles) {
    const parentUri = get(parent, 'uri', '')

    this.editingUrl = data.href
    this.id = data.id
    this.level = parent ? parent.level + 1 : 1
    this.slug = data.uid
    this.title = get(data, 'data.title.0.text', data.uid)
    this.uri = [parentUri, data.uid].join('/')

    this.children = findChildren(this, articles)

    Object.defineProperty(this, 'parent', {
      value: parent,
    })
  }

  hasChildren () {
    return Array.isArray(this.children) && this.children.length > 0
  }
}
