import Basket from '~/modules/basket/entities/basket'
import { clearUuid } from '~/modules/basket/utils'

export default {
  RESET: state => {
    clearUuid()
    state.frontend = new Basket()
  },
  SET: (state, data) => {
    state.frontend = data
  },
}
