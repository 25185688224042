export const apiRoutes = {
  admin: {
    due : {
      index: '/admin/grant-repayments/due/mfis',
      mfi: '/admin/grant-repayments/due/mfis/<%= mfiId %>',
    },
    archive: {
      index: '/admin/grant-repayments/repaid/mfis',
      mfi: '/admin/grant-repayments/repaid/mfis/<%= mfiId %>',
    },
    repay: '/admin/grant-repayments/<%= grantRepaymentId %>/repay',
    download: '/admin/grant-repayments/mfis/<%= mfiId %>/downloads/due',
    upload: '/admin/grant-repayments/mfis/<%= mfiId %>/uploads/due',
  },
}

/**
 * User permissions to check when rendering navigation and before performing
 * actions
 *
 * @type {{ho: string}}
 * @type {{mfi: string}}
 */
export const permissions = {
  ho: 'ho-business-grant-reports',
  mfi: 'mfi-grant-reports',
}

export default {
  apiRoutes,
}
