/** @module plugins/http/interceptors/request/refreshToken */

import get from 'lodash/get'
import { afterLogoutUri, apiRoutes } from '~/modules/auth/config'

/**
 * Try to re-authenticate current user.
 *
 * Intercept incoming error response. If the response status is 401,
 * Axios config is available and the request is not already trying
 * to refresh authentication token, send an automated request to
 * the authentication endpoint. It should contain a cookie with refresh token,
 * that would force API to issue a new authentication token.
 *
 * The authentication operation is performed by auth module's store action.
 * It handles storing the updated auth token, so we don't have to worry
 * about that in the interceptor.
 *
 * @param {AxiosError} err
 * @param {object} err.config - Axios' configuration object.
 * @param {Request} err.request - Axios' request object.
 * @param {Response} err.response - Axios' response object.
 * @param {Nuxt} app - Nuxt's application context.
 * @param {VueRouter} app.router - Instance of Vue Router.
 * @param {Store} app.store - Vuex store.
 * @param {Axios} axios - Axios instance.
 * @throws AxiosError
 * @return {Promise}
 */
export async function refreshTokenInterceptor (err, app, axios) {
  const status = get(err, 'response.status', 200)

  if (
    status === 401 &&
    err.config &&
    !isApiAuthRequest(err) &&
    !err.config.__isRetryRequest &&
    app.store.getters['auth/isAuthenticated'] &&
    app.store.state.auth.token !== null
  ) {
    try {
      await refreshAccessToken(app)
    } catch (err) {
      return logoutAndRedirect(app, err)
    }

    err.config.__isRetryRequest = true
    return axios(err.config)
  }

  throw err
}

/**
 * Check if current request is related to user authentication within the API. Returns `true`
 * if request URL points to any of those actions:
 * - login;
 * - logout;
 * - refresh token.
 *
 * @param {AxiosError} err
 * @returns {boolean}
 */
function isApiAuthRequest (err) {
  const url = get(err, 'config.url', '')

  return url.includes(apiRoutes.refreshToken) ||
    url.includes(apiRoutes.login) ||
    url.includes(apiRoutes.logout)
}

/**
 * @param {Nuxt} app
 * @param {VueRouter} app.router
 * @param {Store} app.store
 * @param {AxiosError} err
 * @returns {Promise<void>}
 */
async function logoutAndRedirect ({ router, store }, err) {
  const intended = get(err, 'config.url', null)

  if (intended) {
    store.commit('global/SET_INTENDED', intended)
  }

  return store.dispatch('auth/logout', { redirectTo: afterLogoutUri })
}

/**
 * Perform an action refreshing access token via API.
 * @param {Nuxt} app
 * @param {Store} app.store
 * @returns {Promise<void>}
 */
async function refreshAccessToken ({ store }) {
  return store.dispatch('auth/authenticate', { refresh: true })
}

export default refreshTokenInterceptor
