/** @module modules/loans/store/mutations */

import set from 'lodash/set'

/**
 * @typedef {Object} Loans~Store~Mutations
 * @property {Function} SET_CONFIG
 * @property {Function} SET_LOAN
 * @property {Function} SET_LOAN_FORM
 * @property {Function} SET_SCHEDULE_FORM
 */
/**
 * @type {Loans~Store~Mutations}
 */
export default {
  SET_CONFIG: (state, config) => {
    state.config = config
  },
  SET_DATAGRID: (state, { name, instance }) => {
    state.datagrids[name] = instance
  },
  SET_LOAN: (state, loan) => {
    state.current = loan
  },
  SET_LOAN_FORM: (state, model) => {
    state.forms.loan = model
  },
  SET_LOAN_MESSAGES: (state, messages) => {
    set(state, 'current.messages.data', messages)
  },
  SET_SCHEDULE_FORM: (state, model) => {
    state.forms.schedule = model
  },
}
