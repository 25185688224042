import Entity, { Transformer } from '~/contracts/entity'
import RepaymentRate from './repayment-rate'

export default class RepaymentBatch extends Entity {
  _transformers () {
    return {
      createdAt: Transformer.date(),
      id: Number,
      isSet: Boolean,
      offsetReportId: Number,
      rates: Transformer.collection(RepaymentRate),
      setAt: Transformer.date(),
      userId: Number,
    }
  }
}
