import Entity, { Transformer } from '~/contracts/entity'

export default class OffsetReportTotalsRepayments extends Entity {
  _transformers () {
    return {
      expected: Transformer.item(Object),
      numExpected: Number,
      numReceived: Number,
      received: Transformer.item(Object),
    }
  }
}
