import get from 'lodash/get'
import merge from 'lodash/merge'
import Entrepreneur from '../frontend/entrepreneur'

export default class AdminEntrepreneur extends Entrepreneur {
  _transformers () {
    return merge({}, super._transformers(), {
      clientId: String,
    })
  }

  get editRoute () {
    const mfiId = get(this.mfi, 'id', null)
    const entrepreneurId = this.id

    if (!entrepreneurId || !mfiId) return null

    return {
      name: 'entrepreneurs:admin:edit',
      params: { mfiId, entrepreneurId, },
    }
  }

  get addLoanRoute () {
    const mfiId = get(this.mfi, 'id', null)
    const entrepreneurId = this.id

    if (!entrepreneurId || !mfiId) return null

    return {
      name: 'loans:admin:create',
      params: { mfiId, entrepreneurId, },
    }
  }
}
