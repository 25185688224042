import get from 'lodash/get'

const locales = ['en']
const messages = {}

locales.forEach(locale => {
  messages[locale] = {
    libs: {
      forms: get(require('~/libs/forms/locales'), ['default', locale]),
      notifications: get(require('~/libs/notifications/locales'), ['default', locale]),
    },
    modules: {
      admin: get(require('~/modules/admin/locales'), ['default', locale]),
      entrepreneurs: get(require('~/modules/entrepreneurs/locales'), ['default', locale]),
      grants: get(require('~/modules/grants/locales'), ['default', locale]),
      loans: get(require('~/modules/loans/locales'), ['default', locale]),
      mfi: get(require('~/modules/mfis/locales'), ['default', locale]),
      offsetting: get(require('~/modules/offsetting/locales'), ['default', locale]),
      updates: get(require('~/modules/updates/locales'), ['default', locale]),
    },

    breadcrumbs: get(require('./breadcrumbs'), ['default', locale]),
    errors: get(require('./errors'), ['default', locale]),
    menus: get(require('./menus'), ['default', locale]),
    subheader: get(require('./subheader'), ['default', locale]),
  }
})

export default messages
