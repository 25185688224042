import Entity from '~/contracts/entity'

export default class ReportRate extends Entity {
  _transformers () {
    return {
      fromCurrency: String,
      toCurrency: String,
      rate: Number,
      inverseRate: Number,
    }
  }
}
