import Lender from '~/modules/users/entities/frontend/lender'
import merge from 'lodash/merge'
import { Transformer } from '~/contracts/entity'
import Membership from './team-membership'

export default class TeamMember extends Lender {
  _transformers () {
    const transformers = super._transformers()

    return merge({}, transformers, {
      membership: Transformer.item(Membership),
    })
  }
}
