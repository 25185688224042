import get from 'lodash/get'
import set from 'lodash/set'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/reporting/config'
import { getInstance } from '~/plugins/http'

export default {
  namespaced: true,

  actions: {
    async fetch (store, period) {
      store.commit('SET_REPORT', null)

      const URI = template(apiRoutes.donations.fetch)({ period })
      const response = await getInstance().get(URI)

      store.commit('SET_REPORT', get(response, 'data.data', null))

      return store.getters.report
    },
    async fetchConfig (store) {
      const URI = apiRoutes.donations.fetchConfig

      const response = await getInstance().get(URI)

      store.commit('SET_CONFIG', get(response, 'data.data.config', []))

      return store.getters.config
    },
  },
  getters: {
    config: state => get(state, 'config'),
    report: state => get(state, 'report'),
  },
  mutations: {
    SET_CONFIG: (state, config) => set(state, 'config', config),
    SET_REPORT: (state, report) => set(state, 'report', report),
  },
  state: {
    config: null,
    report: null,
  },
}
