import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'

const CURRENCY = 'GBP'

export default class Donation extends Entity {
  _transformers () {
    return {
      // Primitives.
      amount: Number,
      appealCode: String,
      appealSlug: String,
      appealType: String,
      createdAt: Transformer.date(),
      error: Boolean,
      errorMessage: String,
      giftAidAmount: Number,
      id: Number,
      userSet: Boolean,
    }
  }

  get amountFormatted () {
    return formatAmount(this.amount, CURRENCY, true)
  }

  get giftAidAmountFormatted () {
    return formatAmount(this.giftAidAmount, CURRENCY, true)
  }

  get type () {
    return 'donation'
  }
}
