import admin from './admin'
import frontend from './frontend'
import myAccount from './frontend/my-account'

export default {
  namespaced: true,

  modules: {
    admin: {
      namespaced: true,
      ...admin,
    },
    myAccount: {
      namespaced: true,
      ...myAccount,
    },
  },

  ...frontend,
}
