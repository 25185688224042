import get from 'lodash/get'
import template from 'lodash/template'
import Entity, { Transformer } from '~/contracts/entity'
import { apiRoutes } from '~/modules/vouchers/config'
import { getInstance } from '~/plugins/http'
import { removeURLDoubleSlashes } from '~/utils/format'

export default class Voucher extends Entity {
  _transformers () {
    return {
      amount: Number,
      buyer: String,
      createdAt: Transformer.date(),
      delivery: String,
      error: String,
      errorMessage: String,
      id: Number,
      isFree: Boolean,
      key: String,
      message: String,
      productId: Number,
      productName: String,
      productThumbnail: String,
      recipientEmail: String,
      recipientName: String,
      redeemed: Boolean,
      scheduled: Boolean,
      scheduledFor: Transformer.date(),
    }
  }

  _apiMapping () {
    return {
      buyer_name: 'buyer',
      is_free: 'isFree',
      recipient_name: 'recipientName',
    }
  }

  get downloadURL () {
    const http = getInstance()
    const baseURL = get(http, 'defaults.baseURL', '')

    const url = template(apiRoutes.download)({
      host: baseURL,
      key: this.key,
    })

    return removeURLDoubleSlashes(url)
  }

  getPreviewURL (basketUuid) {
    const http = getInstance()
    const baseURL = get(http, 'defaults.baseURL', '')
    const uri = get(apiRoutes, 'frontend.preview', '')

    const url = removeURLDoubleSlashes(`${baseURL}/${uri}`)

    return template(url)({ basketUuid, key: this.key })
  }

  get isRedeemed () {
    return this.redeemed
  }

  get isScheduled () {
    return this.scheduled
  }

  get thumbnailUrl () {
    return `/img/gift-vouchers/${this.productThumbnail}`
  }

  get type () {
    return 'voucher'
  }
}
