import Entity, { Transformer } from '~/contracts/entity'
import { money } from '~/utils/filters'
import isSafeInteger from 'lodash/isSafeInteger'

export default class Transaction extends Entity {
  _transformers () {
    return {
      amount: Number,
      id: Number,
      processedAt: Transformer.date(),
    }
  }

  get currency () {
    return 'GBP'
  }

  get amountFormatted () {
    if (!isSafeInteger(this.amount)) return null

    return money(this.amount, this.currency)
  }
}
