/** @module modules/entrepreneurs/store */

import actions from './actions'
import admin from './admin'
import getters from './getters'
import mutations from './mutations'
import state from './state'

/**
 * @typedef {object} EntrepreneursStore
 * @property {boolean} namespaced
 * @property {EntrepreneursStore~Actions} actions
 * @property {EntrepreneursStore~Getters} getters
 * @property {EntrepreneursStore~Mutations} mutations
 * @property {EntrepreneursStore~State} state
 */
/**
 * @type {EntrepreneursStore}
 */
export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,

  modules: {
    admin,
  },
}
