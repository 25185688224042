/** @module modules/entrepreneurs/store/mutationTypes */

/**
 * @const {string}
 */
export const SET_CONFIG = 'SET_CONFIG'

/**
 * @const {string}
 */
export const SET_ENTREPRENEUR = 'SET_ENTREPRENEUR'

/**
 * @typedef {object} EntrepreneurStore~MutationTypes
 * @property {string} SET_CONFIG
 * @property {string} SET_ENTREPRENEUR
 */
export default {
  SET_CONFIG,
  SET_ENTREPRENEUR,
}
