import merge from 'lodash/merge'
import BaseImage from './image'

/**
 * Base class for avatar-related entities.
 */
export default class Avatar extends BaseImage {
  /**
   * {@inheritdoc}
   */
  _transformers () {
    return {
      publicId: String,
    }
  }

  /**
   * Getter for Cloudinary params.
   * @returns {{crop: string, gravity: string, responsive: boolean}}
   */
  get cloudinaryParams () {
    return {
      crop: 'thumb',
      gravity: 'faces',
      responsive: true,
    }
  }

  /**
   * Abstract getter for the default.
   * @returns {string}
   */
  get defaultImage () {
    throw new Error('Extending class should override this method.')
  }

  /**
   * Getter for the full avatar public ID.
   * @returns {string}
   */
  get fullPublicId () {
    const fullPublicId = super.fullPublicId

    return fullPublicId || this.defaultImage
  }

  /**
   * Return a URL to the image resized to 200x200 px.
   * @returns {string}
   */
  get small () {
    if (!this._cl) {
      return ''
    }

    return this._cl.url(this.fullPublicId, merge({}, this.cloudinaryParams, {
      height: 200,
      width: 200,
    }))
  }

  /**
   * Return a URL to the image resized to 400x400 px.
   * @returns {string}
   */
  get medium () {
    if (!this._cl) {
      return ''
    }

    return this._cl.url(this.fullPublicId, merge({}, this.cloudinaryParams, {
      height: 400,
      width: 400,
    }))
  }

  /**
   * Return a URL to the image resized to 800x800 px.
   * @returns {string}
   */
  get large () {
    if (!this._cl) {
      return ''
    }

    return this._cl.url(this.fullPublicId, merge({}, this.cloudinaryParams, {
      height: 800,
      width: 800,
    }))
  }
}
