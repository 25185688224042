import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'

export default class Appeal extends Entity {
  _transformers () {
    return {
      id: Number,
      slug: String,
      appealCode: String,
      restricted: Boolean,
      target: Number,
      totalRaised: Number,
      matchPotAmount: Number,
      matchPotRemaining: Number,
      matchPotLive: Boolean,
      subHeader: String,
      descriptionHeader: String,
      description: String,
      smallPrint: String,
      pricePoints: Array,
      inactive: Transformer.date(),
      appealSteps: Array,
    }
  }

  get formattedTarget () {
    var amount = formatAmount(this.target, 'GBP', false)
    return '£'+parseInt(amount).toLocaleString('en-GB')
  }

  get formattedTotalRaised () {
    var amount = formatAmount(this.totalRaised, 'GBP', false)
    return '£'+parseInt(amount).toLocaleString('en-GB')
  }

  get formattedMatchPot () {
    if (this.matchPotAmount === null) return '£0'

    var amount = formatAmount(this.matchPotAmount, 'GBP', false)
    return '£'+parseInt(amount).toLocaleString('en-GB')
  }

  get formattedMatchPotRemaining () {
    if (this.matchPotRemaining === null) return '£0'

    var amount = formatAmount(this.matchPotRemaining, 'GBP', false)
    return '£'+parseInt(amount).toLocaleString('en-GB')
  }

  get formattedLoansProvided () {
    var amount = formatAmount(this.totalRaised * 9, 'GBP', false)
    return '£'+parseInt(amount).toLocaleString('en-GB')
  }
}
