import Vue from 'vue'

const currentTheme = 'xmas2'
let theme

const installer = {
  async install (Vue) {
    const themes = {
      angels: () => import('~/themes/angels'),
      appeal: () => import('~/themes/appeal'),
      default: () => import('~/themes/default'),
      futurefund: () => import('~/themes/futurefund'),
      grants: () => import('~/themes/grants'),
      referrals: () => import('~/themes/referrals'),
      xmas: () => import('~/themes/xmas'),
      xmas2: () => import('~/themes/xmas2'),
      xmas2bonus: () => import('~/themes/xmas2bonus'),
      xmasbonus: () => import('~/themes/xmasbonus'),
    }
    const importer = themes[currentTheme] || themes['default']
    await importer().then(module => {
      const ThemeCtor = module.default
      theme = new ThemeCtor()
    })

    Vue.prototype.$theme = theme
  },
}

Vue.use(installer)

export default function ({ app }) {
  app.$theme = theme
}
