import ActionRequiredError from '~/libs/payments/errors/stripe/action-required-error'
import Basket from '~/modules/basket/entities/basket'
import get from 'lodash/get'
import template from 'lodash/template'
import { apiRoutes } from '~/libs/payments/config'
import { getInstance } from '~/plugins/http'

export default {
  namespaced: true,

  state () {
    return {
      amount: null,
      isPaymentBeingProcessed: false,
    }
  },

  mutations: {
    SET_AMOUNT (state, amount) {
      if (amount === null) {
        state.amount = null
        return
      }

      if (!Number.isInteger(amount) || !amount || Number.isNaN(amount)) {
        throw new Error('Invalid amount.')
      }

      state.amount = amount
    },
    SET_IS_PAYMENT_BEING_PROCESSED (state, value) {
      if (typeof value !== 'boolean') return

      state.isPaymentBeingProcessed = value
    },
  },

  actions: {
    /**
     * Order API to use current user's credit to cover the basket.
     * @param {Store} store
     * @param {string} uuid - Basket UUID
     * @return {Promise<Basket>}
     */
    async useCredit (store, uuid) {
      const uri = template(apiRoutes.frontend.useCredit)({ basketUuid: uuid })
      const response = await getInstance().post(uri)
      const basketData = get(response, 'data.data')

      store.commit('basket/SET', new Basket(basketData), { root: true })
      await store.dispatch('auth/authenticate', { refresh: true }, { root: true })

      return store.rootGetters['basket/basket']
    },
  },

  modules: {
    paypal: {
      namespaced: true,

      actions: {
        async setup (store, { amount, type }) {
          const data = { amount }
          let uri

          switch (type) {
            case 'basket':
              uri = template(apiRoutes.frontend.paypal.basket.setup)({
                basketUuid: get(store.rootGetters['basket/basket'], 'uuid', null),
              })
              break
            case 'topup':
              uri = apiRoutes.frontend.paypal.topup.setup
              break
          }

          const response = await getInstance().post(uri, data)

          return get(response, 'data.data.id')
        },
        async execute (store, { paymentId, type }) {
          const data = { paymentId }

          let uri

          switch (type) {
            case 'basket':
              uri = template(apiRoutes.frontend.paypal.basket.execute)({
                basketUuid: get(store.rootGetters['basket/basket'], 'uuid', null),
              })
              break
            case 'topup':
              uri = apiRoutes.frontend.paypal.topup.execute
              break
          }

          const response = await getInstance().post(uri, data)

          if (response.status === 204) {
            await store.dispatch('auth/authenticate', { refresh: true }, { root: true })
          } else if (response.status === 200) {
            await store.dispatch('auth/authenticate', { refresh: true }, { root: true })

            if (type === 'basket') {
              store.commit('basket/SET', new Basket(get(response, 'data.data')), { root: true })
            }

            if (type === 'topup') {
              store.commit('basket/SET', new Basket({ 'creditTopUp': get(response, 'data.data')}), { root: true })
            }
          }
        },
      },
    },
    stripe: {
      namespaced: true,

      actions: {
        async confirm (store, { amount, paymentIntentId, type }) {
          const data = { amount, paymentIntentId }
          let uri

          switch (type) {
            case 'basket':
              uri = template(apiRoutes.frontend.stripe.basket.confirm)({
                basketUuid: get(store.rootGetters['basket/basket'], 'uuid', null),
              })
              break
            case 'topup':
              uri = apiRoutes.frontend.stripe.topup.confirm
              break
          }
          const response = await getInstance().post(uri, data)

          if (response.status === 204) {
            await store.dispatch('auth/authenticate', { refresh: true }, { root: true })
          } else if (response.status === 200) {
            await store.dispatch('auth/authenticate', { refresh: true }, { root: true })

            if (type === 'basket') {
              store.commit('basket/SET', new Basket(get(response, 'data.data')), { root: true })
            }

            if (type === 'topup') {
              store.commit('basket/SET', new Basket({ 'creditTopUp': get(response, 'data.data')}), { root: true })
            }
          }
        },
        async execute (store, { amount, paymentMethodId, type }) {
          let uri
          const data = {
            amount,
            paymentMethod: paymentMethodId,
          }

          switch (type) {
            case 'basket':
              uri = template(apiRoutes.frontend.stripe.basket.execute)({
                basketUuid: get(store.rootGetters['basket/basket'], 'uuid', null),
              })
              break
            case 'topup':
              uri = apiRoutes.frontend.stripe.topup.execute
              break
          }
          const response = await getInstance().post(uri, data)

          // 202 means everything went OK, without verification.
          // 200 means an additional action is required.
          if (response.status === 200 && response.data.data.requiresAction === true) {
            throw new ActionRequiredError(response.data.data.paymentIntentClientSecret)
          }

          // Response should contain data to setup a new basket.
          await store.dispatch('auth/authenticate', { refresh: true }, { root: true })

          if (type === 'basket') {
            store.commit('basket/SET', new Basket(get(response, 'data.data')), { root: true })
          }

          if (type === 'topup') {
            store.commit('basket/SET', new Basket({ 'creditTopUp': get(response, 'data.data')}), { root: true })
          }
        },
      },
    },
  },
}
