import Donation from './donation'
import Dormant from './dormant'
import Microgrant from './microgrant'
import Microloan from './microloan'
import ReclaimedBonus from './reclaimed-bonus'
import Repayment from './repayment'
import TopUp from './top-up'
import Transaction from './transaction'
import Voucher from './voucher'
import Withdrawal from './withdrawal'

export default {
  Donation,
  Dormant,
  Microgrant,
  Microloan,
  ReclaimedBonus,
  Repayment,
  TopUp,
  Transaction,
  Voucher,
  Withdrawal,
}
