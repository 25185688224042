import Transaction from './transaction'

export default class DormantTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'type', {
      enumerable: true,
      value: 'dormant',
    })
  }
}
