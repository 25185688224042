import get from 'lodash/get'
import isInteger from 'lodash/isInteger'
import merge from 'lodash/merge'
import template from 'lodash/template'
import throttle from 'lodash/throttle'
import { apiRoutes, cacheTtl } from '~/modules/basket/config'
import Basket from '~/modules/basket/entities/basket'
import { getDefaultBasketDefinition } from '~/modules/basket/store/frontend'
import { clearUuid, getUuid, storeUuid } from '~/modules/basket/utils'
import { getInstance } from '~/plugins/http'
import { error } from '~/utils/errors'

/**
 * Inject a property with basket's UUID into the data object.
 * If there is not basket UUID set in state, or a UUID already exists
 * in the data argument and is a string, return the object without mutating
 * it.
 *
 * The method doesn't mutate the data argument.
 *
 * @param {{}} data
 * @return {{}}
 * @private
 */
const _injectUuid = (data) => {
  if (typeof data.uuid === 'string') return data

  const uuid = getUuid()

  if (typeof uuid === 'string') {
    return merge({}, data, { uuid })
  }

  return data
}

/**
 * Extract basket data from Axios response and store it in state.
 *
 * If no basket data exists in the response object, store a plain definition
 * of the basket.
 *
 * @param {function} commit - Vuex's commit method.
 * @param {AxiosResponse} response
 * @private
 */
const _updateBasketState = (commit, response) => {
  const basketData = get(response, 'data.data', getDefaultBasketDefinition())
  const basket = new Basket(basketData)

  if (basket.isCompleted === true) {
    commit('RESET')
    return
  }

  basket.fetchedAt = new Date()

  commit('SET', basket)

  storeUuid(basket.uuid)
}

/**
 * Handle errors reported by API when fetching data.
 * @param {AxiosResponse} response
 * @param {function} commit
 * @private
 * @return {void}
 */
const _handleBasketFetchError = (response, commit) => {
  const status = get(response, 'status')

  if (isInteger(status)) {
    switch (status) {
      default:
      case 404:
        clearUuid()
        commit('RESET')
        break
    }
  }
}

/**
 * Send a request to the API, based on the "type" argument.
 * @param {Store} store
 * @param {number} amount
 * @param {number} id
 * @param {string} type
 * @return {Promise<Basket~Store~Frontend>}
 */
const add = async ({ commit, dispatch, getters }, { amount, id, type }) => {
  function addTypedItem (type) {
    let choices = {
      microgrant () {
        return dispatch('addMicrogrant', { amount, grantId: id })
      },
      microloan () {
        return dispatch('addMicroloan', { amount, loanId: id })
      },
      voucher () {
        return dispatch('addVoucher', { amount, voucherId: id })
      },
    }
    return choices[type]()
  }

  await addTypedItem(type)

  return getters['basket']
}

/**
 * Perform a request to the API, adding a microgrant to basket.
 * @param {Store} store
 * @param {Getters} store.getters
 * @param {Basket~State~Basket} store.getters.basket
 * @param {number} amount - Microgrant amount,
 * @param {number} grantId - Grant ID.
 * @return {Promise<Basket~Store~Frontend>}
 */
const addMicrogrant = async (store, { amount, grantId }) => {
  const microgrants = get(store.getters['basket'], 'microgrants', [])

  if (microgrants.some(m => m.id === grantId)) {
    await store.dispatch('remove', { id: grantId, type: 'microgrant' })
  }

  const data = _injectUuid({
    amount,
    id: grantId,
    type: 'microgrant',
  })
  const response = await getInstance().post(apiRoutes.frontend.addItem, data)

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

/**
 * Perform a request to the API, adding a microloan to basket.
 * @param {Store} store
 * @param {Getters} store.getters
 * @param {Basket~State~Basket} store.getters.basket
 * @param {number} amount - Microloan amount,
 * @param {number} loanId - Loan ID.
 * @return {Promise<Basket~Store~Frontend>}
 */
const addMicroloan = async (store, { amount, loanId }) => {
  const microloans = get(store.getters['basket'], 'microloans', [])

  if (microloans.some(m => m.id === loanId)) {
    await store.dispatch('remove', { id: loanId, type: 'microloan' })
  }

  const data = _injectUuid({
    amount,
    id: loanId,
    type: 'microloan',
  })
  const response = await getInstance().post(apiRoutes.frontend.addItem, data)

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

/**
 * Perform a request to the API, adding a gift voucher to basket.
 * @param {Store} store
 * @param {Getters} store.getters
 * @param {Basket~State~Basket} store.getters.basket
 * @param {number} amount - Microloan amount,
 * @param {number} loanId - Loan ID.
 * @return {Promise<Basket~Store~Frontend>}
 */
const addVoucher = async (store, { amount, voucherId }) => {
  const vouchers = get(store.getters['basket'], 'vouchers', [])

  if (vouchers.some(m => m.id === voucherId)) {
    await store.dispatch('remove', { id: voucherId, type: 'giftVoucher' })
  }

  const data = _injectUuid({
    amount,
    id: voucherId,
    type: 'giftVoucher',
  })
  const response = await getInstance().post(apiRoutes.frontend.addItem, data)

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

/**
 * Fetch items currently in the basket.
 * @param {Store} store
 * @return {Promise<Basket~Store~Frontend>}
 */
const fetch = async ({ commit, getters }) => {
  const uuid = getUuid()

  if (typeof uuid === 'string' && uuid.length === 36) {
    const uri = template(apiRoutes.frontend.get)({ basketUuid: uuid })
    try {
      const response = await getInstance().get(uri)
      _updateBasketState(commit, response)
    } catch (e) {
      _handleBasketFetchError(e.response, commit)
    }
  } else {
    commit('RESET')
  }

  return getters['basket']
}

/**
 * Fetch basket record from API using basket UUID.
 * @param {function} commit
 * @param {object} getters
 * @param {string} uuid
 * @returns {Promise<Basket>}
 */
const fetchByUUID = async ({ commit, getters }, uuid) => {
  const uri = template(apiRoutes.frontend.get)({ basketUuid: uuid })

  const response = await getInstance().get(uri)
  const basketData = get(response, 'data.data')

  return new Basket(basketData)
}

/**
 * Remove an item from the basket in the API.
 * @param {Store} store
 * @param {number} id
 * @param {string} type
 * @return {Promise<Basket~State~Basket>}
 */
const remove = async (store, { id, type }) => {
  // console.log(`id: ${id}, type: ${type}`)
  const data = _injectUuid({ id, type })
  const response = await getInstance().post(apiRoutes.frontend.removeItem, data)

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

/**
 * Update donation amount in basket.
 * @param {Store} store
 * @param {number} amount
 * @param {number|null} appealId
 * @return {Promise<void>}
 */
const setDonation = async (store, { amount, appealId = 0 }) => {
  const data = _injectUuid({ amount, appealId })
  const response = await getInstance().post(apiRoutes.frontend.setDonation, data)

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

/**
 * Update the team ID given basket is attributed to.
 * @param {Store} store
 * @param {number} teamId
 * @return {Promise<void>}
 */
const setTeam = async (store, { teamId }) => {
  const uri = template(apiRoutes.frontend.setTeam)({
    basketUuid: getUuid(),
  })
  const response = await getInstance().put(uri, { teamId })

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

/**
 * Set currently logged in user as basket owner.
 * @param {Store} store
 * @return {Promise<Basket>}
 */
const setUser = async store => {
  try {
    const uri = template(apiRoutes.frontend.setUser)({
      basketUuid: getUuid(),
    })
    const response = await getInstance().put(uri)

    _updateBasketState(store.commit, response)

    return store.getters['basket']
  } catch (err) {
    const code = get(err, 'response.status', 500)

    switch (code) {
      case 400:
        error(err)
        break
      case 401:
      case 409:
        break
      default:
        throw err
    }
  }
}

/**
 * Apply the given bonus type to the basket.
 * @param {Store} store
 * @param {string} bonusType
 * @return {Promise<void>}
 */
const applyBonus = async (store, { bonusType }) => {
  const uri = template(apiRoutes.frontend.applyBonus)({
    basketUuid: getUuid(),
  })
  const response = await getInstance().put(uri, { bonusType })

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

/**
 * Remove the given bonus type from the basket.
 * @param {Store} store
 * @param {string} bonusType
 * @return {Promise<void>}
 */
const removeBonus = async (store, { bonusType }) => {
  const uri = template(apiRoutes.frontend.removeBonus)({
    basketUuid: getUuid(),
  })
  const response = await getInstance().put(uri, { bonusType })

  _updateBasketState(store.commit, response)

  return store.getters['basket']
}

export default {
  add,
  addMicrogrant,
  addMicroloan,
  addVoucher,
  fetch: throttle(fetch, cacheTtl),
  fetchByUUID,
  remove,
  setDonation,
  setTeam,
  setUser,
  applyBonus,
  removeBonus,
}
