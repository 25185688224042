import { apiRoutes } from '~/libs/copycat/config'
import template from 'lodash/template'
import { getInstance } from '~/plugins/http'
import AuthUser from '~/modules/auth/entities/auth-user'
import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'

export default {
  namespaced: true,

  actions: {
    async impersonate (store, userId) {
      const URL = template(apiRoutes.admin.impersonate)({ userId })

      const response = await getInstance().post(URL)
      const accessToken = get(response, 'data.data.accessToken', null)
      const restorationToken = get(response, 'data.data.restorationToken', null)
      const userData = get(response, 'data.data.user.data', null)

      if (
        typeof accessToken === 'string' &&
        typeof restorationToken === 'string' &&
        isPlainObject(userData)
      ) {
        store.commit('SET_RESTORATION_TOKEN', restorationToken)
        store.commit('auth/SET_AUTH_USER', new AuthUser(userData), { root: true })
        store.commit('auth/SET_TOKEN', accessToken, { root: true })
      }
    },
    async stop (store) {
      const currentUser = store.rootGetters['auth/user']
      const userId = get(currentUser, 'id', null)
      // Make restoration token the current one - this should effectively
      // revert permissions to the original admin user.
      store.commit('auth/SET_TOKEN', store.state.restorationToken, { root: true })

      // Make a request to stop impersonating in the authentication layer.
      const URL = template(apiRoutes.admin.stop)({ userId })
      const response = await getInstance().post(URL, {
        accessToken: store.rootState.auth.token,
      })

      const accessToken = get(response, 'data.data.accessToken', null)
      const userData = get(response, 'data.data.user.data', null)

      if (typeof accessToken === 'string' && isPlainObject(userData)) {
        // Restore original admin user entity.
        store.commit('auth/SET_AUTH_USER', new AuthUser(userData), { root: true })
        // Restore regular admin access token.
        store.commit('auth/SET_TOKEN', accessToken, { root: true })
        // Clear restoration token.
        store.commit('SET_RESTORATION_TOKEN', null)
      }
    },
  },

  getters: {
    impersonating: state => {
      return (
        typeof state.restorationToken === 'string' &&
        state.restorationToken.length > 0
      )
    },
  },

  mutations: {
    SET_RESTORATION_TOKEN: (state, restorationToken) => { state.restorationToken = restorationToken },
  },

  state: {
    restorationToken: null,
  },

  persist: [
    { name: 'restorationToken', transform: data => String(data) },
  ],
}
