import { getRouteForPermissionDeniedRedirect as getRoute } from '@auth/utils/auth'

/**
 * Raise an error if user is not a Head Office administrator.
 * @param {Vue} app
 * @param {function} redirect
 * @param {Store} store
 * @return {boolean}
 */
export default async function ({ app, redirect, store }) {
  const isAdmin = store.getters['auth/isHOAdmin']

  if (!isAdmin) {
    app.flash({ type: 'error', text: `Only signed in Head Office administrators can access this page.` })

    return redirect(getRoute(store))
  }

  return true
}
