import { EssentialTeam } from '~/modules/teams/entities/frontend/team'
import merge from 'lodash/merge'
import { Transformer } from '~/contracts/entity'
import UserTeamStats from './user-team-stats'
import TeamStats from '~/modules/teams/entities/frontend/team-stats'

export default class UserTeam extends EssentialTeam {
  _transformers () {
    return merge({}, super._transformers(), {
      location: String,
      stats: Transformer.item(TeamStats),
      userStats: Transformer.item(UserTeamStats),
    })
  }

  get isManageable () {
    return this.membership.isAdmin
  }
}
