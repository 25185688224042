import get from 'lodash/get'
import merge from 'lodash/merge'
import toString from 'lodash/toString'
import { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'
import AdminEntrepreneur from '~/modules/entrepreneurs/entities/admin/entrepreneur'
import Loan from '../frontend/loan'

function formattedAmountUsdPropFactory (loan) {
  return {
    enumerable: true,
    get () {
      try {
        return formatAmount(loan.amountUsd, 'USD', true)
      } catch (err) {
        return toString(loan.amountUsd)
      }
    },
  }
}

function formattedAmountPropFactory (loan) {
  return {
    enumerable: true,
    /**
     * @return {string}
     */
    get () {
      try {
        return formatAmount(this.amount, this.currency, true)
      } catch (err) {
        return toString(this.amount)
      }
    },
  }
}

export default class AdminLoan extends Loan {
  constructor (data = {}) {
    super(data)

    Object.defineProperties(this, {
      formattedAmount: formattedAmountPropFactory(this),
      formattedAmountUsd: formattedAmountUsdPropFactory(this),
    })
  }

  _transformers () {
    return merge({}, super._transformers(), {
      amountUsd: Number,
      amountUsdWithBuffer: Number,
      clientId: String,
      currencyUsdRate: Number,
      entrepreneur: Transformer.item(AdminEntrepreneur),
      fundedBatchId: Number,
      hasMessages: Boolean,
      mfiLoanRef: String,
      published: Boolean,
      reserved: Boolean,
      totalRepaid: Number,
      totalOutstanding: Number,
    })
  }

  /**
   * Get a route to the loan's edit page.
   *
   * @return {null|Route}
   */
  get editRoute () {
    const entrepreneurId = get(this.entrepreneur, 'id', null)
    const mfiId = get(this.entrepreneur, 'mfi.id', null)

    if (!entrepreneurId || !mfiId || !this.id) return null

    return {
      name: 'loans:admin:edit',
      params: {
        mfiId,
        entrepreneurId,
        loanId: this.id,
      },
    }
  }
}
