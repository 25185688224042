import Entity, { Transformer } from '~/contracts/entity'
import LoanImage from '~/modules/loans/entities/frontend/loan-image'

export default class Microloan extends Entity {
  _transformers () {
    return {
      activity: String,
      amount: Number,
      createdAt: Transformer.date(),
      entrepreneurDisplayName: String,
      entrepreneurGender: String,
      entrepreneurId: Number,
      entrepreneurType: String,
      error: Boolean,
      errorMessage: String,
      id: Number,
      image: Transformer.itemFromPrimitive(LoanImage, 'image'),
      loanId: Number,
      country: String,
      teamId: Number,
      teamName: String,
      teamUuid: String,
    }
  }

  get showUrl () {
    if (!this.loanId) return { name: 'loans:frontend:search' }

    return {
      name: 'loans:frontend:show',
      params: {
        loanId: this.loanId,
      },
    }
  }

  get type () {
    return 'microloan'
  }
}
