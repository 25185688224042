import Entity, { Transformer } from '~/contracts/entity'
import FundedBatchRate from './funded-batch-rate'

export default class FundedBatch extends Entity {
  _transformers () {
    return {
      createdAt: Transformer.date(),
      id: Number,
      isSet: Boolean,
      offsetReportId: Number,
      rates: Transformer.collection(FundedBatchRate),
      setAt: Transformer.date(),
      userId: Number,
    }
  }
}
