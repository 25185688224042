import get from 'lodash/get'

export default function ({ route }) {
  const params = get(route, 'params', {})
  const keys = Object.keys(params)

  if (!keys.length) return true

  keys.forEach(key => {
    if (key.endsWith('Id')) {
      route.params[key] = parseInt(route.params[key])
    }
  })
}
