import Entity, { Transformer } from '~/contracts/entity'
import AdminUser from '~/modules/users/entities/admin/user'

export default class AdminWithdrawal extends Entity {
  _transformers () {
    return {
      amount: Number,
      batchId: Number,
      completedAt: Transformer.date(),
      createdAt: Transformer.date(),
      donationAmount: Number,
      dueAt: Transformer.date(),
      id: Number,
      ip: String,
      paypalEmail: String,
      risk: Number,
      isSuccessful: Boolean,
      transactionRef: String,
      unsuccessfulReason: String,
      user: Transformer.item(AdminUser),
    }
  }

  _apiMapping () {
    return {
      success: 'isSuccessful',
      withdrawalBatchId: 'batchId',
    }
  }

  /**
   * This property will be used with BootstrapVue's <b-table> component.
   * @private
   */
  get _rowVariant () {
    if (typeof this.risk === 'number') {
      return 'risk-' + String(this.risk)
    }

    return null
  }
}
