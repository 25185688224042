import partial from 'lodash/partial'
import noop from 'lodash/noop'
import EventBus from '~/bus'
import {
  EVENT_JOB_FAILED,
  EVENT_JOB_FINISHED,
  EVENT_JOB_STARTED,
  EVENT_JOB_SUCCESSFUL,
} from './events'

export default class Job {
  constructor (definition, queue) {
    this.addedAt = new Date()
    this.definition = definition
    this.error = undefined
    this.isFinished = false
    this.isRunning = false
    this.isSuccessful = false
    this.onError = definition.onError || noop,
    this.onSuccess = definition.onSuccess || noop,
    this.queue = queue
    this.handler = _createJobHandler(definition, queue.store.dispatch)
  }

  async run () {
    this.isRunning = true
    EventBus.$emit(EVENT_JOB_STARTED, this)

    try {
      const result = await this.handler()
      this.isSuccessful = true
      EventBus.$emit(EVENT_JOB_SUCCESSFUL, this)
      this.onSuccess(this, result)
    } catch (err) {
      this.isSuccessful = false
      this.error = err
      EventBus.$emit(EVENT_JOB_FAILED, this)
      this.onError(this, err)
    } finally {
      this.isFinished = true
      this.isRunning = false
      EventBus.$emit(EVENT_JOB_FINISHED, this)
    }
  }
}

/**
 * A job definition can have three possible forms:
 * - a function - in this case all a queue needs to do is to run it;
 * - a string - which will be considered to be a Vuex action name, in which case
 *              a new "partialled" function will be created and returned;
 * - an object - which *MUST* contain an "action" string-type property, that will be
 *               treated as a Vuex action name. It also *MAY* include a "payload" property,
 *               which will be passed into the "partialled" Vuex action as it's arguments.
 * @param {function|string|{}} definition
 * @param {function} dispatch - Store action dispatcher.
 * @return {function}
 * @private
 */
function _createJobHandler (definition, dispatch) {
  const type = typeof definition

  switch (type) {
    case 'function':
      return definition
    case 'string':
      return partial(dispatch, definition)
    case 'object':
      return _handlerFromObject(definition, dispatch)
  }
}

function _handlerFromObject(definition, dispatch) {
  if (typeof definition['action'] !== 'string') {
    console.warn('A job definition in object form *MUST* have an "action" property pointing to a Vuex action.')
    return function () {}
  }

  return partial(dispatch, definition.action, definition.payload)
}
