import merge from 'lodash/merge'

/**
 * Update individual report values.
 *
 * @param {OffsettingStore~State} state
 * @param {Offsetting~Report} data
 * @constructor
 */
const PATCH_REPORT = (state, data) => {
  state.current = merge({}, state.current, data)
}

/**
 * Set a value as a current report in the store.
 * @kind function
 * @param {OffsettingStore~State} state
 * @param {Offsetting~Report} report
 * @return {void}
 */
const SET_REPORT = (state, report) => {
  state.current = report
}

/**
 * @typedef {{}} OffsettingStore~Mutations
 * @property {function} SET_REPORT
 */
/**
 * @type {OffsettingStore~Mutations}
 */
export default {
  PATCH_REPORT,
  SET_REPORT,
}
