import { getInstance } from '~/plugins/http'
import { apiRoutes } from '~/modules/admin/config'
import get from 'lodash/get'

export default {
  async fetchDashboardData (store) {
    const response = await getInstance().get(apiRoutes.dashboard)

    store.commit('SET_DASHBOARD_FIGURES', get(response, 'data.data', null))

    return store.getters.dashboard
  },
}
