/** @module plugins/http/interceptors/request/outgoingToSnakeCase */

import { convertObjectKeys } from '~/utils/convert'
import has from 'lodash/has'
import isPlainObject from 'lodash/isPlainObject'

/**
 * Convert all keys in request query parameters or POST-ed form data to snake_case.
 * @param {AxiosRequestConfig} config
 * @return {AxiosRequestConfig}
 */
export default function (config) {
  const targets = ['data']

  targets.forEach(t => {
    if (has(config, t) && isPlainObject(config[t])) {
      config[t] = convertObjectKeys(config[t], 'snakeCase')
    }
  })

  return config
}
