/** @module plugins/http/interceptors/request/authToken */

import { getToken } from '~/modules/auth/utils/auth'

/**
 * Axios interceptor attaching a JWT token in a request header.
 * @param {AxiosRequestConfig} config
 */
export function authTokenInterceptor (config) {
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

export default authTokenInterceptor
