import get from 'lodash/get'
import has from 'lodash/has'
import isNil from 'lodash/isNil'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import set from 'lodash/set'

/**
 * @returns {Function}
 */
export default (prefix = 'lwc') => {
  let persistables

  const persist = (mutation, state) => {
    const snapshot = pick(state, Object.keys(persistables))

    window.localStorage.setItem(prefix, JSON.stringify(snapshot))
  }

  const restore = store => {
    let stored = null
    if (typeof window !== 'undefined' && window.localStorage) {
      stored = window.localStorage.getItem(prefix)
    }

    if (typeof stored === 'string') {
      try {
        let snapshot
        try {
          snapshot = JSON.parse(stored)
        } catch (e) {
          // console.error('Error parsing JSON snapshot:', e)
          snapshot = {}
        }
        const restored = {}

        Object.keys(persistables).forEach(key => {
          try {
            const data = get(snapshot, key)

            if (!isNil(data)) {
              const transform = persistables[key]
              const value = transform(data)

              set(restored, key, value)
            }
          } catch (e) {
            console.error(e)
          }
        })

        // Now merge restored data with current state.
        store.replaceState(merge({}, store.state, restored))
      } catch (e) {
        localStorage.removeItem(prefix)
        store.$sentry.captureException(e)

        if (process.env.NODE_ENV !== 'production') {
          console.error('Could not parse JSON snapshot string from local storage. This error has been logged in Sentry.')
        }
      }
    }
  }

  /**
   * Vuex plugin must return a function taking store as
   * a single argument.
   */
  return (store) => {
    // Extract modules' paths that require persisting.
    persistables = extractPersistables('root', store._modules.root)
    restore(store)

    store.subscribe(persist)
  }
}

const isValidPersistingTarget = item => {
  return (
    has(item, 'name') &&
    typeof item.name === 'string' &&
    has(item, 'transform') &&
    typeof item.transform === 'function'
  )
}

const extendPrefix = (prefix, path) => prefix === 'root' ? path : `${prefix}.${path}`

/**
 * Iterate over the tree of store modules and submodules and extract properties defined
 * as persistables in each individual module.
 * @param {string} prefix - When root equals to "root", otherwise it's a comma-delimited module path.
 * @param {Module} module - Vuex module
 */
const extractPersistables = (prefix, module) => {
  const items = {}
  /** @type {Array|undefined} persist */
  const persist = get(module, ['_rawModule', 'persist'])

  if (Array.isArray(persist)) {
    persist.forEach(item => {
      if (isValidPersistingTarget(item)) {
        const path = extendPrefix(prefix, item.name)
        items[path] = item.transform
      }
    })
  }

  const hasChildren = Object.keys(module._children).length > 0

  if (hasChildren) {
    Object.entries(module._children)
      .map(([submoduleName, submodule]) => {
        const extracted = extractPersistables(extendPrefix(prefix, submoduleName), submodule)

        Object.entries(extracted).forEach(([path, transform]) => { items[path] = transform })
      })
  }

  return items
}
