import '~/modules/offsetting/types'

/**
 * @typedef {{}} OffsettingStore~State
 * @property {Offsetting~OffsetReport} [current=null]
 */
/**
 * @return {OffsettingStore~State}
 */
export default {
  current: null,
}
