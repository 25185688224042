/** @module modules/entrepreneurs/store/state */

/**
 * @typedef {object} Entrepreneur
 * @property {number} id
 * @property {string} type
 * @property {(string|null)} firstname
 * @property {(string|null)} lastname
 * @property {(string|null)} groupName
 * @property {string} gender
 * @property {string} title
 * @property {string} description
 * @property {array} loans
 */
/**
 * @typedef {Object} EntrepreneursStore~State
 * @property {Object} config
 * @property {Object[]} config.statuses
 * @property {Object[]} config.titles
 * @property {Object[]} config.types
 * @property {(Entrepreneur|null)} current
 * @property {(Entrepreneur[])} loaded
 */
/**
 * @type {EntrepreneursStore~State}
 */
export default {
  config: {},
  current: null,
  loaded: [],
}
