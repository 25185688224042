import get from 'lodash/get'
import Transaction from './transaction'

export default class ReclaimedBonusTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'type', {
      enumerable: true,
      value: 'reclaimedBonus',
    })
  }
}
