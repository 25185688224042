import { afterLoginUri } from '~/modules/auth/config'

export default function ({ app, isServer, store, redirect }) {
  return new Promise(function (resolve) {
    if (
      store.getters['auth/isLoggingOut'] ||
      (store.getters['auth/isAuthenticated'] && !store.state.auth.token)
    ) {
      store.dispatch('auth/destroyUserAuthData')
    }

    if (store.getters['auth/isAuthenticated']) {
      if (isServer) {
        redirect(afterLoginUri)
        return resolve()
      }

      return app.router.push(afterLoginUri, function () {
        return resolve()
      })
    }

    return resolve()
  })
}
