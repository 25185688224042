import { mapActions } from 'vuex'
import SubHeader from '~/components/frontend/layout/sub-header/sub-header'

export default {
  components: {
    SubHeader,
  },
  computed: {
    subheader: () => ({
      heading: '',
      extraClasses: '',
    }),
  },
  methods: {
    ...mapActions({
      setupSubheader: 'global/setupSubheader',
    }),
  },
  watch: {
    subheader: {
      immediate: true,
      handler () {
        this.setupSubheader(this.subheader)
      },
    },
  },
  beforeUpdate () {
    this.setupSubheader(this.subheader)
  },
  beforeMount () {
    this.setupSubheader(this.subheader)
  },
}
