export default {
  errors: {
    400: `Your browser sent incorrect data to the server. Please try again. If the problem persists, please contact a Head Office administrator.`,
    401: `You are not logged in.`,
    403: `It seems like you are not permitted to perform this action.`,
    404: `This URL doesn't exist.`,
    409: `We couldn't complete this action because of an internal data conflict. Please contact a Head Office administrator.`,
    422: `The data in your form is invalid. Please fix the errors and try again.`,
    423: `This resource is currently locked, which means there's another process running a lengthy update on it. Please try again later.`,
    500: `Lendwithcare servers are experiencing some technical problems right now. Please try again in a few minutes. If the problem persists, please contact a Head Office administrator.`,
    '5xx': `<p>Lendwithcare servers are experiencing some technical problems at the moment. Please try again later.</p><p>If the problem persists please contact Lendwithcare.</p>`,
    network: `We couldn't connect to Lendwithcare server. Please check your connection and try again.`,
  },
}
