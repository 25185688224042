import Entity, { Transformer } from '~/contracts/entity'

export default class MfiInfoMessage extends Entity {
  _transformers () {
    return {
      id: Number,
      mfiId: Number,
      mfiName: String,
      message: String,
      live: Boolean,
    }
  }
}
