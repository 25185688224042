import Entity from '~/contracts/entity'
import { default as clFactory } from '~/utils/cloudinary'

const cloudinary = clFactory()

/**
 * Base class for avatar-related entities.
 */
export default class BaseImage extends Entity {
  /**
   * {@inheritdoc}
   */
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, '_cl', {
      get () {
        return cloudinary
      },
    })
  }

  /**
   * {@inheritdoc}
   */
  _transformers () {
    return {
      publicId: String,
    }
  }

  /**
   * Getter for Cloudinary params.
   * @returns {{}}
   */
  get cloudinaryParams () {
    return {
      crop: 'fit',
      dpr: 'auto',
      fetch_format: 'auto',
    }
  }

  /**
   * Abstract getter for Cloudinary prefix for the avatar.
   */
  get prefix () {
    throw new Error('Extending class should override this method.')
  }

  /**
   * Getter for the full avatar public ID.
   * @returns {*}
   */
  get fullPublicId () {
    if (typeof this.publicId === 'string' && this.publicId.length) {
      if (this.publicId.startsWith(this.prefix)) return this.publicId

      return `${this.prefix}/${this.publicId}`
    }
  }

  /**
   * Get URL to full uploaded image.
   * @returns {string}
   */
  get url () {
    return this._cl.url(this.fullPublicId)
  }
}
