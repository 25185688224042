import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'

export default class Repayment extends Entity {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'isBufferRepayment', {
      configurable: true,
      enumerable: true,
      value: false,
      writable: false,
    })
  }

  _transformers () {
    return {
      dueAt: Transformer.date(),
      dueToUsersAt: Transformer.date(),
      expected: Number,
      id: Number,
      received: Number,
      repaidToUsersAt: Transformer.date(),
      repaidAt: Transformer.date(),
    }
  }

  get formattedExpected () {
    return formatAmount(this.expected || 0, 'GBP', true)
  }

  get formattedReceived () {
    return formatAmount(this.received || 0, 'GBP', true)
  }

  get isDue () {
    return this.dueToUsersAt > new Date()
  }

  _apiMapping () {
    return {
      fundingAmountExpected: 'expected',
      amountToUsers: 'received',
    }
  }
}
