import BonusAvailability from '~/modules/basket/entities/bonus-availability'
import BonusEligibility from '~/modules/basket/entities/bonus-eligibility'
import CreditTopUp from '~/modules/basket/entities/credit-top-up'
import Donation from '~/modules/donations/entities/frontend/donation'
import Entity, { Transformer } from '~/contracts/entity'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import Microgrant from '~/modules/grants/entities/frontend/microgrant'
import Microloan from '~/modules/loans/entities/frontend/microloan'
import Team from '~/modules/teams/entities/frontend/team'
import Voucher from '~/modules/vouchers/entities/voucher'
import { formatAmount } from '~/libs/currencies/utils'

const CURRENCY = 'GBP'

export default class Basket extends Entity {
  _transformers () {
    return {
      bonusAvailability: Transformer.collection(BonusAvailability),
      bonusEligibility: Transformer.item(BonusEligibility),
      completedAt: Transformer.date(),
      creditTopUp: Transformer.item(CreditTopUp),
      donation: Transformer.item(Donation),
      fetchedAt: Transformer.date(),
      firstPurchase: Boolean,
      id: Number,
      itemsTotal: Number,
      microgrants: Transformer.collection(Microgrant),
      microloans: Transformer.collection(Microloan),
      requiredTopUp: Number,
      team: Transformer.item(Team),
      teamId: Number,
      total: Number,
      userCredit: Number,
      uuid: String,
      vouchers: Transformer.collection(Voucher),
    }
  }

  _apiMapping () {
    return {
      giftVouchers: 'vouchers',
    }
  }

  /**
   * Check whether the basket is marked as completed.
   * @return {boolean}
   */
  get isCompleted () {
    return this.completedAt !== null && this.completedAt instanceof Date
  }

  get items () {
    const items = []

    if (this.donation instanceof Donation) {
      items.push(this.donation)
    }

    if (isArray(this.microgrants) && this.microgrants.every(m => m instanceof Microgrant)) {
      items.push(...this.microgrants)
    }

    if (isArray(this.microloans) && this.microloans.every(m => m instanceof Microloan)) {
      items.push(...this.microloans)
    }

    if (isArray(this.vouchers) && this.vouchers.every(voucher => voucher instanceof Voucher)) {
      items.push(...this.vouchers)
    }

    return items
  }

  /**
   * Get the required top-up (amount missing between user's current credits amount and
   * the total value of the basket) formatted as a string.
   * @return {string}
   */
  get requiredTopUpFormatted () {
    return formatAmount(get(this, 'requiredTopUp', 0), CURRENCY, true)
  }

  /**
   * Get the total amount of all the items in the basket formatted as a string.
   * @return {string}
   */
  get itemsTotalFormatted () {
    return formatAmount(get(this, 'itemsTotal', 0), CURRENCY, true)
  }

  /**
   * Get the basket total formatted as a string.
   * @return {string}
   */
  get totalFormatted () {
    return formatAmount(get(this, 'total', 0), CURRENCY, true)
  }

  /**
   * The the current amount of user credits formatted as a string.
   * @return {string}
   */
  get userCreditFormatted () {
    return formatAmount(get(this, 'currentCredit', 0), CURRENCY, true)
  }
}
