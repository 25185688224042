import BaseAvatar from '~/entities/avatar'
import { defaultLenderAvatar, imagePublicIdPrefix } from '~/modules/users/config'

export default class Avatar extends BaseAvatar {
  /**
   * Get default prefix for avatars in users module.
   */
  get prefix () {
    return imagePublicIdPrefix
  }

  /**
   * Abstract getter for the default user avatar.
   * @returns {string}
   */
  get defaultImage () {
    return defaultLenderAvatar
  }
}
