/** @module modules/auth/store/actions */

import Cookies from 'js-cookie'
import get from 'lodash/get'
import EventBus, { AUTH_TOKEN_INVALIDATED } from '~/bus'
import { apiRoutes } from '~/modules/auth/config'
import AuthUser from '~/modules/auth/entities/auth-user'
import Config from '~/modules/auth/entities/config'
import UserSettingsConfig from '~/modules/auth/entities/user-settings-config'
import {
  getResetPasswordURL,
  login as requestLogin,
  logout as requestLogout,
} from '~/modules/auth/utils/auth'
import { getInstance } from '~/plugins/http'

/**
 * Perform a user sign-in or token refresh request, based on the "refresh" parameter.
 * @param {Store} store - Vuex store.
 * @param {Object} [options] - Action options.
 */
async function authenticate (store, options) {
  // Determine which options were passed in a dispatch request.
  const email = get(options, 'email', null)
  const password = get(options, 'password', null)
  const refresh = get(options, 'refresh', false)
  // If we were asked to refresh access token, credentials are not taken under consideration.
  const credentials = refresh ? null : { email, password }
  // Call a utility function performing a request and store token and user object.
  let { token, userData } = await requestLogin(credentials, refresh)

  // Store received access token in store.
  store.commit('SET_TOKEN', token)
  // Store received user object in store.
  store.commit('SET_AUTH_USER', new AuthUser(userData))

  this.$sentry.configureScope(scope => {
    scope.setUser(store.getters.user)
  })
}

/**
 * Fetch configuration required for registration and updating user records.
 * @param {function} commit
 * @param {{}} getters
 * @return {Promise<{config: *, settings: *}>}
 */
async function fetchConfig ({ commit, getters }) {
  try {
    const response = await getInstance().get(apiRoutes.config)
    const configData = get(response.data, 'data.config', {})
    const settingsData = get(response.data, 'data.settings', {})

    commit('SET_CONFIG', new Config(configData))
    commit('SET_SETTINGS_CONFIG', new UserSettingsConfig(settingsData))
  } catch (err) {
    this.$sentry.captureException(err)
  }

  return {
    config: getters.config,
    settingsConfig: getters.settingsConfig,
  }
}

/**
 * Perform a logout request to the API, then clear out locally stored authentication data.
 * @param {Store} store - Vuex store.
 * @param {{}} payload - Action payload
 * @param {string} payload.redirectTo - URI to redirect to after logging out.
 * @param {Function} store.commit - Vuex store mutation commit method.
 */
async function logout ({ commit, dispatch, getters }, payload) {
  try {
    commit('SET_LOGGING_OUT', true)
    // Perform a logout request.
    await requestLogout()
  } catch (err) {
    // Don't do anything. We will destroy all authenticatable data
    // outside of this block anyway. If the user hasn't really been
    // logged out in the API, we'll force them to sign in again anyway.
  }

  commit('SET_TOKEN', null)
  EventBus.$emit(AUTH_TOKEN_INVALIDATED)

  this.$sentry.configureScope(scope => {
    scope.setUser(undefined)
  })
}

function destroyUserAuthData ({ commit }) {
  // Clear out token.
  commit('SET_TOKEN', null)
  // Unset "refreshToken" cookie.
  Cookies.remove('refreshToken')
  // Clear out user record.
  commit('SET_AUTH_USER', null)
  // Clear logging out status.
  commit('SET_LOGGING_OUT', false)
}

/**
 * Request sending an email with a password reset token.
 * @param {Store} store
 * @param {string} email - User email to reset.
 * @return {Promise<string>}
 */
async function requestPasswordReset (store, email) {
  if (typeof window === 'undefined') {
    throw new Error('This action can only be dispatched in a browser environment.')
  }

  if (typeof apiRoutes.resetPassword === 'undefined') {
    throw new Error('Full URL to password reset page must be specified.')
  }

  const passwordResetURL = getResetPasswordURL()

  const response = await getInstance().post(apiRoutes.requestPasswordReset, { email }, {
    headers: {
      'password-reset-url': passwordResetURL,
    },
  })

  if (response.status === 200) {
    return get(response.data, 'message')
  }

  throw new Error('Unexpected response from the API in the password reset trigger request.')
}

/**
 * Set new user password.
 *
 * @param {Store} store
 * @param {{}} data
 * @param {string} data.email
 * @param {string} data.password
 * @param {string} data.passwordConfirmation
 * @param {string} data.token
 * @return {Promise<boolean>}
 */
async function resetPassword (store, data) {
  const response = await getInstance().post(apiRoutes.resetPassword, data)

  if (response.status === 200) {
    const token = get(response, 'data.data.accessToken', null)
    const userData = get(response, 'data.data.user.data', null)

    // Store received access token in store.
    store.commit('SET_TOKEN', token)
    // Store received user object in store.
    store.commit('SET_AUTH_USER', new AuthUser(userData))

    return true
  }

  throw new Error('Unexpected response from the API when trying to set new user password.')
}

/**
 * @typedef {Object} AuthStore~Actions
 * @property {Function} authenticate
 * @property {Function} destroyUserAuthData
 * @property {function} fetchConfig
 * @property {Function} logout
 * @property {Function} requestPasswordReset
 * @property {Function} resetPassword
 */
/**
 * @type {AuthStore~Actions}
 */
export default {
  authenticate,
  destroyUserAuthData,
  fetchConfig,
  logout,
  requestPasswordReset,
  resetPassword,
}
