import Entity from '~/contracts/entity'

export default class UserProperties extends Entity {
  _transformers () {
    return {
      hasDonated: Boolean,
      hasGranted: Boolean,
      hasGifted: Boolean,
      hasLent: Boolean,
      isAngel: Boolean,
    }
  }
}
