import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0f6f1f98 = () => interopDefault(import('../src/pages/foo.vue' /* webpackChunkName: "pages/foo" */))
const _1109844e = () => interopDefault(import('../src/pages/ie/index.js' /* webpackChunkName: "pages/ie/index" */))
const _40035746 = () => interopDefault(import('../src/pages/ie/ie.vue' /* webpackChunkName: "pages/ie/ie" */))
const _5cbef5c0 = () => interopDefault(import('../src/modules/admin/pages/index' /* webpackChunkName: "" */))
const _6f798f76 = () => interopDefault(import('../src/modules/angels/pages/frontend/index' /* webpackChunkName: "" */))
const _504a9f60 = () => interopDefault(import('../src/modules/angels/pages/frontend/thank-you' /* webpackChunkName: "" */))
const _0d9fb9dd = () => interopDefault(import('../src/modules/appeals/pages/frontend/show' /* webpackChunkName: "" */))
const _443afaa4 = () => interopDefault(import('../src/modules/auth/pages/register' /* webpackChunkName: "" */))
const _ef4fe184 = () => interopDefault(import('../src/modules/auth/pages/login' /* webpackChunkName: "" */))
const _240b4b9a = () => interopDefault(import('../src/modules/auth/pages/forgot-password' /* webpackChunkName: "" */))
const _319cedb8 = () => interopDefault(import('../src/modules/auth/pages/reset-password' /* webpackChunkName: "" */))
const _2a38e6c2 = () => interopDefault(import('../src/modules/basket/pages/frontend/index' /* webpackChunkName: "" */))
const _5e6e149a = () => interopDefault(import('../src/modules/basket/pages/frontend/index/basket' /* webpackChunkName: "" */))
const _2d4273b9 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment' /* webpackChunkName: "" */))
const _4e01802e = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment-details' /* webpackChunkName: "" */))
const _3371fc48 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/login' /* webpackChunkName: "" */))
const _c6ae982a = () => interopDefault(import('../src/modules/basket/pages/frontend/index/receipt' /* webpackChunkName: "" */))
const _13164e5a = () => interopDefault(import('../src/modules/cms/pages/index' /* webpackChunkName: "" */))
const _5e0643e4 = () => interopDefault(import('../src/modules/cms/pages/news/index' /* webpackChunkName: "" */))
const _27c62e57 = () => interopDefault(import('../src/modules/cms/pages/news/item' /* webpackChunkName: "" */))
const _00ebbca8 = () => interopDefault(import('../src/modules/donations/pages/frontend/donate' /* webpackChunkName: "" */))
const _1599d0cb = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/index' /* webpackChunkName: "" */))
const _d8c80fba = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/create' /* webpackChunkName: "" */))
const _7c8f62f1 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/edit' /* webpackChunkName: "" */))
const _4292dd46 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/select-mfi' /* webpackChunkName: "" */))
const _71ffd557 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-index' /* webpackChunkName: "" */))
const _383ca096 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-mfi' /* webpackChunkName: "" */))
const _6d1d5ea5 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-index' /* webpackChunkName: "" */))
const _2bcd3483 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-mfi' /* webpackChunkName: "" */))
const _90c206e4 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/index' /* webpackChunkName: "" */))
const _25aac07e = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/show' /* webpackChunkName: "" */))
const _114ef750 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _a9329504 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/archive' /* webpackChunkName: "" */))
const _e915a100 = () => interopDefault(import('../src/modules/grants/pages/frontend/home' /* webpackChunkName: "" */))
const _d5cb07ee = () => interopDefault(import('../src/modules/grants/pages/frontend/search' /* webpackChunkName: "" */))
const _e90bd4c4 = () => interopDefault(import('../src/modules/grants/pages/frontend/show' /* webpackChunkName: "" */))
const _d7245054 = () => interopDefault(import('../src/modules/grants/pages/admin/index' /* webpackChunkName: "" */))
const _64a9a6ce = () => interopDefault(import('../src/modules/grants/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _216e5b10 = () => interopDefault(import('../src/modules/grants/pages/admin/create' /* webpackChunkName: "" */))
const _ee2e62f4 = () => interopDefault(import('../src/modules/grants/pages/admin/edit' /* webpackChunkName: "" */))
const _de827326 = () => interopDefault(import('../src/modules/homepage/pages/index' /* webpackChunkName: "" */))
const _446b6a47 = () => interopDefault(import('../src/modules/loans/pages/frontend/search' /* webpackChunkName: "" */))
const _dbe50948 = () => interopDefault(import('../src/modules/loans/pages/frontend/show' /* webpackChunkName: "" */))
const _277b9514 = () => interopDefault(import('../src/modules/loans/pages/admin/index' /* webpackChunkName: "" */))
const _44e740e8 = () => interopDefault(import('../src/modules/loans/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _841a860c = () => interopDefault(import('../src/modules/loans/pages/admin/create' /* webpackChunkName: "" */))
const _a2a11cf0 = () => interopDefault(import('../src/modules/loans/pages/admin/edit' /* webpackChunkName: "" */))
const _f7a9d46c = () => interopDefault(import('../src/modules/loans/pages/admin/messages' /* webpackChunkName: "" */))
const _ee27f010 = () => interopDefault(import('../src/modules/marketing/pages/frontend/shop' /* webpackChunkName: "" */))
const _32a5a1ea = () => interopDefault(import('../src/modules/marketing/pages/frontend/welcome-answer' /* webpackChunkName: "" */))
const _dbe4ff7c = () => interopDefault(import('../src/modules/mfis/pages/admin/index' /* webpackChunkName: "" */))
const _25bc689a = () => interopDefault(import('../src/modules/mfis/pages/admin/edit' /* webpackChunkName: "" */))
const _bd1a04d8 = () => interopDefault(import('../src/modules/mfis/pages/admin/admins' /* webpackChunkName: "" */))
const _4f16953e = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/index' /* webpackChunkName: "" */))
const _e9cad8ca = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/edit' /* webpackChunkName: "" */))
const _c6d18e00 = () => interopDefault(import('../src/modules/offsetting/pages/admin/index' /* webpackChunkName: "" */))
const _33419d70 = () => interopDefault(import('../src/modules/offsetting/pages/admin/archive' /* webpackChunkName: "" */))
const _3ee00d0f = () => interopDefault(import('../src/modules/offsetting/pages/admin/show' /* webpackChunkName: "" */))
const _2e2618c2 = () => interopDefault(import('../src/modules/offsetting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _77fb64ec = () => interopDefault(import('../src/modules/referrals/pages/frontend/make-referral' /* webpackChunkName: "" */))
const _814cd422 = () => interopDefault(import('../src/modules/referrals/pages/frontend/referral' /* webpackChunkName: "" */))
const _1483839f = () => interopDefault(import('../src/modules/repayments/pages/admin/index' /* webpackChunkName: "" */))
const _6145bc4f = () => interopDefault(import('../src/modules/repayments/pages/admin/archive' /* webpackChunkName: "" */))
const _03047bdb = () => interopDefault(import('../src/modules/reporting/pages/reporting' /* webpackChunkName: "" */))
const _f5265426 = () => interopDefault(import('../src/modules/reporting/pages/reporting/donations' /* webpackChunkName: "" */))
const _689570d2 = () => interopDefault(import('../src/modules/reporting/pages/reporting/reconciliation' /* webpackChunkName: "" */))
const _59b56f16 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-changes' /* webpackChunkName: "" */))
const _3a7c3738 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-data' /* webpackChunkName: "" */))
const _1d8d9264 = () => interopDefault(import('../src/modules/reporting/pages/reporting/gift-aid-data' /* webpackChunkName: "" */))
const _1660fb71 = () => interopDefault(import('../src/modules/reporting/pages/reporting/entrepreneur-and-loan-data' /* webpackChunkName: "" */))
const _cb943c8c = () => interopDefault(import('../src/modules/site-settings/pages/index' /* webpackChunkName: "" */))
const _033f1ee3 = () => interopDefault(import('../src/modules/site-settings/pages/index/loan-activity-types' /* webpackChunkName: "" */))
const _6078442a = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources' /* webpackChunkName: "" */))
const _44ea1e42 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/list' /* webpackChunkName: "" */))
const _b80f94c0 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/create' /* webpackChunkName: "" */))
const _44e6dbae = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/edit' /* webpackChunkName: "" */))
const _343f44e4 = () => interopDefault(import('../src/modules/teams/pages/frontend/create' /* webpackChunkName: "" */))
const _13f54c12 = () => interopDefault(import('../src/modules/teams/pages/frontend/manage' /* webpackChunkName: "" */))
const _49cd9200 = () => interopDefault(import('../src/modules/teams/pages/frontend/index' /* webpackChunkName: "" */))
const _f3294152 = () => interopDefault(import('../src/modules/teams/pages/frontend/profile' /* webpackChunkName: "" */))
const _0819eca8 = () => interopDefault(import('../src/modules/updates/pages/frontend/index' /* webpackChunkName: "" */))
const _39b176e3 = () => interopDefault(import('../src/modules/updates/pages/frontend/show' /* webpackChunkName: "" */))
const _e5b2734a = () => interopDefault(import('../src/modules/updates/pages/admin/index' /* webpackChunkName: "" */))
const _72e9fc2c = () => interopDefault(import('../src/modules/updates/pages/admin/select-loan' /* webpackChunkName: "" */))
const _0dadb493 = () => interopDefault(import('../src/modules/updates/pages/admin/create' /* webpackChunkName: "" */))
const _2875093e = () => interopDefault(import('../src/modules/updates/pages/admin/edit' /* webpackChunkName: "" */))
const _a387db4e = () => interopDefault(import('../src/modules/users/pages/admin/index' /* webpackChunkName: "" */))
const _6117cb34 = () => interopDefault(import('../src/modules/users/pages/admin/index/user-search' /* webpackChunkName: "" */))
const _7f1e17c0 = () => interopDefault(import('../src/modules/users/pages/admin/index/re-bulk-update' /* webpackChunkName: "" */))
const _58d37361 = () => interopDefault(import('../src/modules/users/pages/admin/index/bulk-create' /* webpackChunkName: "" */))
const _25bd0688 = () => interopDefault(import('../src/modules/users/pages/admin/mfi-index' /* webpackChunkName: "" */))
const _70ff3564 = () => interopDefault(import('../src/modules/users/pages/admin/user' /* webpackChunkName: "" */))
const _74a447d5 = () => interopDefault(import('../src/modules/users/pages/admin/user/edit' /* webpackChunkName: "" */))
const _ba4a0d9e = () => interopDefault(import('../src/modules/users/pages/admin/user/gift-aid-history' /* webpackChunkName: "" */))
const _408374da = () => interopDefault(import('../src/modules/users/pages/admin/user/email-change-history' /* webpackChunkName: "" */))
const _8461ac24 = () => interopDefault(import('../src/modules/users/pages/admin/transactions' /* webpackChunkName: "" */))
const _a2890f78 = () => interopDefault(import('../src/modules/users/pages/admin/roles' /* webpackChunkName: "" */))
const _70fb621c = () => interopDefault(import('../src/modules/users/pages/admin/mfis' /* webpackChunkName: "" */))
const _0f41f252 = () => interopDefault(import('../src/modules/users/pages/admin/credit' /* webpackChunkName: "" */))
const _567b1470 = () => interopDefault(import('../src/modules/users/pages/frontend/my-account' /* webpackChunkName: "" */))
const _54a11886 = () => interopDefault(import('../src/modules/users/pages/frontend/my-loans' /* webpackChunkName: "" */))
const _37240434 = () => interopDefault(import('../src/modules/users/pages/frontend/my-grants' /* webpackChunkName: "" */))
const _d199f4bc = () => interopDefault(import('../src/modules/users/pages/frontend/my-vouchers' /* webpackChunkName: "" */))
const _d1661e1c = () => interopDefault(import('../src/modules/users/pages/frontend/my-transactions' /* webpackChunkName: "" */))
const _4cd7db09 = () => interopDefault(import('../src/modules/users/pages/frontend/add-credit' /* webpackChunkName: "" */))
const _338d2438 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit' /* webpackChunkName: "" */))
const _5d469dfe = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/index/index' /* webpackChunkName: "" */))
const _2853f934 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/completed' /* webpackChunkName: "" */))
const _36021ec5 = () => interopDefault(import('../src/modules/users/pages/frontend/profile' /* webpackChunkName: "" */))
const _6a9d4e6e = () => interopDefault(import('../src/modules/users/pages/frontend/edit' /* webpackChunkName: "" */))
const _19e9b918 = () => interopDefault(import('../src/modules/users/pages/frontend/close-account' /* webpackChunkName: "" */))
const _e270b7ba = () => interopDefault(import('../src/modules/vouchers/pages/frontend/index' /* webpackChunkName: "" */))
const _572e6cfb = () => interopDefault(import('../src/modules/vouchers/pages/frontend/free' /* webpackChunkName: "" */))
const _5909a86b = () => interopDefault(import('../src/modules/vouchers/pages/frontend/redeem' /* webpackChunkName: "" */))
const _4e2123fe = () => interopDefault(import('../src/modules/vouchers/pages/admin' /* webpackChunkName: "" */))
const _d9d45714 = () => interopDefault(import('../src/modules/vouchers/pages/admin/listing' /* webpackChunkName: "" */))
const _5a595b36 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases' /* webpackChunkName: "" */))
const _50ddd64c = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/listing' /* webpackChunkName: "" */))
const _6bada3e6 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/create' /* webpackChunkName: "" */))
const _44c54568 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/completed' /* webpackChunkName: "" */))
const _7c7e32ea = () => interopDefault(import('../src/modules/withdrawals/pages/admin/requested' /* webpackChunkName: "" */))
const _769d2076 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/batches' /* webpackChunkName: "" */))
const _a0700818 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/pending' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/foo",
    component: _0f6f1f98,
    name: "foo"
  }, {
    path: "/ie",
    component: _1109844e,
    name: "ie"
  }, {
    path: "/ie/ie",
    component: _40035746,
    name: "ie-ie"
  }, {
    path: "/admin",
    component: _5cbef5c0,
    name: "admin:index"
  }, {
    path: "/angels",
    component: _6f798f76,
    name: "angels:frontend:index"
  }, {
    path: "/angels/thank-you",
    component: _504a9f60,
    name: "angels:frontend:thank-you"
  }, {
    path: "/appeals/:appealSlug",
    component: _0d9fb9dd,
    name: "appeals:frontend:show"
  }, {
    path: "/register/:referralCode?",
    component: _443afaa4,
    name: "auth:register"
  }, {
    path: "/login",
    component: _ef4fe184,
    name: "auth:login"
  }, {
    path: "/forgot-password",
    component: _240b4b9a,
    name: "auth:forgot-password"
  }, {
    path: "/reset-password",
    component: _319cedb8,
    name: "auth:reset-password"
  }, {
    path: "/basket",
    component: _2a38e6c2,
    children: [{
      path: "",
      component: _5e6e149a,
      name: "basket:frontend:index"
    }, {
      path: "payment",
      component: _2d4273b9,
      name: "basket:frontend:payment"
    }, {
      path: "payment-details",
      component: _4e01802e,
      name: "basket:frontend:payment-details"
    }, {
      path: "login",
      component: _3371fc48,
      name: "basket:frontend:login"
    }, {
      path: "receipt/:uuid",
      component: _c6ae982a,
      name: "basket:frontend:receipt"
    }]
  }, {
    path: "/info",
    component: _13164e5a
  }, {
    path: "/info/:uri*",
    component: _13164e5a,
    name: "cms:article"
  }, {
    path: "/news/latest",
    component: _5e0643e4,
    name: "cms:news"
  }, {
    path: "/news/post/:id",
    component: _27c62e57,
    name: "cms:news-item"
  }, {
    path: "/donate",
    component: _00ebbca8,
    name: "donations:frontend:donate"
  }, {
    path: "/admin/mfis/entrepreneurs",
    component: _1599d0cb,
    name: "entrepreneurs:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/create",
    component: _d8c80fba,
    name: "entrepreneurs:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)",
    component: _7c8f62f1,
    name: "entrepreneurs:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/select-mfi",
    component: _4292dd46,
    name: "entrepreneurs:admin:select-mfi"
  }, {
    path: "/admin/grant-repayments/mfis/due",
    component: _71ffd557,
    name: "grant-repayments:admin:mfis:due:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/due",
    component: _383ca096,
    name: "grant-repayments:admin:mfis:due:mfi"
  }, {
    path: "/admin/grant-repayments/mfis/archive",
    component: _6d1d5ea5,
    name: "grant-repayments:admin:archive:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/archive",
    component: _2bcd3483,
    name: "grant-repayments:admin:archive:mfi"
  }, {
    path: "/admin/mfis/grant-reports",
    component: _90c206e4,
    name: "grantReporting:admin:current"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/:reportId(\\d+)",
    component: _25aac07e,
    name: "grantReporting:admin:show"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/no-current-report",
    component: _114ef750,
    name: "grantReporting:admin:no-current-report"
  }, {
    path: "/admin/mfis/grant-reports/archive",
    component: _a9329504,
    name: "grantReporting:admin:archive"
  }, {
    path: "/grants",
    component: _e915a100,
    name: "grants:frontend:home"
  }, {
    path: "/grants/search",
    component: _d5cb07ee,
    name: "grants:frontend:search"
  }, {
    path: "/grants/:grantId(\\d+)",
    component: _e90bd4c4,
    name: "grants:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/grants",
    component: _d7245054,
    name: "grants:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/grants/select-entrepreneur",
    component: _64a9a6ce,
    name: "grants:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/create",
    component: _216e5b10,
    name: "grants:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/:grantId(\\d+)",
    component: _ee2e62f4,
    name: "grants:admin:edit"
  }, {
    path: "/",
    component: _de827326,
    name: "homepage:index"
  }, {
    path: "/search",
    component: _446b6a47,
    name: "loans:frontend:search"
  }, {
    path: "/loans/:loanId(\\d+)",
    component: _dbe50948,
    name: "loans:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans",
    component: _277b9514,
    name: "loans:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/select-entrepreneur",
    component: _44e740e8,
    name: "loans:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/create",
    component: _841a860c,
    name: "loans:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)",
    component: _a2a11cf0,
    name: "loans:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/messages",
    component: _f7a9d46c,
    name: "loans:admin:messages:index"
  }, {
    path: "/info/about-us/lendwithcare-shop",
    component: _ee27f010,
    name: "marketing:frontend:shop"
  }, {
    path: "/welcome-answer/:answer",
    component: _32a5a1ea,
    name: "marketing:user-journey:answer"
  }, {
    path: "/admin/mfis",
    component: _dbe4ff7c,
    name: "mfis:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)",
    component: _25bc689a,
    name: "mfis:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/administrators",
    component: _bd1a04d8,
    name: "mfis:admin:administrators"
  }, {
    path: "/admin/mfis/mfi-info-messages",
    component: _4f16953e,
    name: "mfis:admin:mfiInfoMessages"
  }, {
    path: "/admin/mfis/mfi-info-messages/:mfiInfoMessageId(\\d+)",
    component: _e9cad8ca,
    name: "mfis:admin:editMfiInfoMessages"
  }, {
    path: "/admin/mfis/offset-reports",
    component: _c6d18e00,
    name: "offsetting:admin:current"
  }, {
    path: "/admin/mfis/offset-reports/archive",
    component: _33419d70,
    name: "offsetting:admin:archive"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/:offsetReportId(\\d+)",
    component: _3ee00d0f,
    name: "offsetting:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/no-current-report",
    component: _2e2618c2,
    name: "offsetting:admin:no-current-report"
  }, {
    path: "/my-account/make-referral",
    component: _77fb64ec,
    name: "referral:frontend:make-referral"
  }, {
    path: "/referral/:referralCode?",
    component: _814cd422,
    name: "referral:frontend:referral"
  }, {
    path: "/admin/repayments",
    component: _1483839f,
    name: "repayments:admin:index"
  }, {
    path: "/admin/repayments/archive",
    component: _6145bc4f,
    name: "repayments:admin:archive"
  }, {
    path: "/admin/reporting",
    component: _03047bdb,
    children: [{
      path: "donations",
      component: _f5265426,
      name: "reporting:donations"
    }, {
      path: "reconciliation",
      component: _689570d2,
      name: "reporting:reconciliation"
    }, {
      path: "user-changes",
      component: _59b56f16,
      name: "reporting:user-changes-download"
    }, {
      path: "user-data",
      component: _3a7c3738,
      name: "reporting:user-data-download"
    }, {
      path: "gift-aid",
      component: _1d8d9264,
      name: "reporting:gift-aid-data-download"
    }, {
      path: "entrepreneur-and-loan-data",
      component: _1660fb71,
      name: "reporting:entrepreneur-and-loan-data-download"
    }]
  }, {
    path: "/admin/site-settings",
    component: _cb943c8c,
    name: "site-settings:index",
    children: [{
      path: "/admin/site-settings/loan-activity-types",
      component: _033f1ee3,
      name: "site-settings:loan-activity-types"
    }, {
      path: "/admin/site-settings/user-sources",
      component: _6078442a,
      children: [{
        path: "",
        component: _44ea1e42,
        name: "site-settings:user-sources:list"
      }, {
        path: "create",
        component: _b80f94c0,
        name: "site-settings:user-sources:create"
      }, {
        path: ":userSourceId(\\d+)",
        component: _44e6dbae,
        name: "site-settings:user-sources:edit"
      }]
    }]
  }, {
    path: "/my-account/teams/create",
    component: _343f44e4,
    name: "teams:frontend:create"
  }, {
    path: "/my-account/teams/:uuid",
    component: _13f54c12,
    name: "teams:frontend:manage"
  }, {
    path: "/teams",
    component: _49cd9200,
    name: "teams:frontend:index"
  }, {
    path: "/teams/:slug",
    component: _f3294152,
    name: "teams:frontend:profile"
  }, {
    path: "/updates",
    component: _0819eca8,
    name: "updates:frontend:index"
  }, {
    path: "/updates/:updateId(\\d+)",
    component: _39b176e3,
    name: "updates:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates",
    component: _e5b2734a,
    name: "updates:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates/create/select-loan",
    component: _72e9fc2c,
    name: "updates:admin:select-loan"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/create",
    component: _0dadb493,
    name: "updates:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/:updateId(\\d+)",
    component: _2875093e,
    name: "updates:admin:edit"
  }, {
    path: "/admin/users",
    component: _a387db4e,
    children: [{
      path: "",
      component: _6117cb34,
      name: "users:admin:index"
    }, {
      path: "raisers-edge",
      component: _7f1e17c0,
      name: "users:admin:re-bulk-update"
    }, {
      path: "bulk-create",
      component: _58d37361,
      name: "users:admin:bulk-create"
    }]
  }, {
    path: "/admin/mfi-users",
    component: _25bd0688,
    name: "users:admin:mfi-index"
  }, {
    path: "/admin/users/:userId(\\d+)",
    component: _70ff3564,
    children: [{
      path: "",
      component: _74a447d5,
      name: "users:admin:edit"
    }, {
      path: "gift-aid-history",
      component: _ba4a0d9e,
      name: "users:admin:gift-aid-history"
    }, {
      path: "email-change-history",
      component: _408374da,
      name: "users:admin:email-change-history"
    }]
  }, {
    path: "/admin/users/:userId(\\d+)/transactions",
    component: _8461ac24,
    name: "users:admin:transactions"
  }, {
    path: "/admin/users/:userId(\\d+)/roles",
    component: _a2890f78,
    name: "users:admin:roles"
  }, {
    path: "/admin/users/:userId(\\d+)/mfis",
    component: _70fb621c,
    name: "users:admin:mfis"
  }, {
    path: "/admin/users/:userId(\\d+)/credit",
    component: _0f41f252,
    name: "users:admin:credit"
  }, {
    path: "/my-account",
    component: _567b1470,
    name: "users:frontend:my-account"
  }, {
    path: "/my-account/my-loans",
    component: _54a11886,
    name: "users:frontend:my-loans"
  }, {
    path: "/my-account/my-grants",
    component: _37240434,
    name: "users:frontend:my-grants"
  }, {
    path: "/my-account/my-gift-vouchers",
    component: _d199f4bc,
    name: "users:frontend:my-vouchers"
  }, {
    path: "/my-account/my-transactions",
    component: _d1661e1c,
    name: "users:frontend:my-transactions"
  }, {
    path: "/my-account/add-credit",
    component: _4cd7db09,
    name: "users:frontend:add-credit"
  }, {
    path: "/my-account/withdraw-credit",
    component: _338d2438,
    children: [{
      path: "",
      component: _5d469dfe,
      name: "users:frontend:withdraw-credit"
    }, {
      path: "completed",
      component: _2853f934,
      name: "users:frontend:withdrawal-complete"
    }]
  }, {
    path: "/profile/:userUuid",
    component: _36021ec5,
    name: "users:frontend:profile"
  }, {
    path: "/my-account/my-details",
    component: _6a9d4e6e,
    name: "users:frontend:edit-details"
  }, {
    path: "/my-account/my-profile",
    component: _6a9d4e6e,
    name: "users:frontend:edit-profile"
  }, {
    path: "/my-account/my-settings",
    component: _6a9d4e6e,
    name: "users:frontend:edit-settings"
  }, {
    path: "/my-account/close/:token",
    component: _19e9b918,
    name: "users:frontend:close-account"
  }, {
    path: "/gift-vouchers",
    component: _e270b7ba,
    name: "vouchers:frontend:index"
  }, {
    path: "/gift-vouchers/claim-free",
    component: _572e6cfb,
    name: "vouchers:frontend:free"
  }, {
    path: "/gift-vouchers/redeem",
    component: _5909a86b,
    name: "vouchers:frontend:redeem"
  }, {
    path: "/admin/vouchers",
    component: _4e2123fe,
    children: [{
      path: "",
      component: _d9d45714,
      name: "vouchers:admin:list"
    }, {
      path: "/admin/vouchers/bulk-purchases",
      component: _5a595b36,
      children: [{
        path: "",
        component: _50ddd64c,
        name: "vouchers:admin:bulk-purchases"
      }, {
        path: "new",
        component: _6bada3e6,
        name: "vouchers:admin:create-bulk-purchase"
      }]
    }]
  }, {
    path: "/admin/withdrawals/completed",
    component: _44c54568,
    name: "withdrawals:admin:completed"
  }, {
    path: "/admin/withdrawals/requested",
    component: _7c7e32ea,
    name: "withdrawals:admin:requested"
  }, {
    path: "/admin/withdrawals/batches",
    component: _769d2076,
    name: "withdrawals:admin:batches"
  }, {
    path: "/admin/withdrawals/pending",
    component: _a0700818,
    name: "withdrawals:admin:pending"
  }],

  parseQuery: function (queryString) {
      return window.qs.parse(queryString, {
        allowDots: true,
        arrayFormat: 'brackets',
        ignoreQueryPrefix: true
      });
    },
  stringifyQuery: function (queryObject) {
      return window.qs.stringify(queryObject, {
        addQueryPrefix: true,
        allowDots: true,
        arrayFormat: 'brackets',
        encode: false
      });
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
