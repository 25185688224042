import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'

const CURRENCY = 'GBP'

export default class BonusEligibility extends Entity {
  _transformers () {
    return {
      bonusCampaignId: Number,
      status: String,
      qualifyingAmount: Number,
      stillRequired: Number,
      bonusAmount: Number,
      bonusType: String,
      eligibleBasketItems: Array,
    }
  }

  get formattedQualifyingAmount () {
    return formatAmount(this.qualifyingAmount, CURRENCY, true)
  }

  get formattedStillRequired () {
    return formatAmount(this.stillRequired, CURRENCY, true)
  }

  get formattedBonusAmount () {
    return formatAmount(this.bonusAmount, CURRENCY, true)
  }

  get formattedEligibleBasketItems () {
    if (this.eligibleBasketItems === undefined) return undefined

    var types = []

    // Recreate the array of basket item types using user-facing names.
    this.eligibleBasketItems[0].forEach(function (item) {
      if(item == 'giftVouchers') types.push('gift vouchers')
      if(item == 'microloans') types.push('loans')
      if(item == 'microgrants') types.push('grants')
    });

    // Two items? Append ' or' to the first item.
    if(types.length == 2) types[0] += ' or'

    // Three items? Append ', ' to the first item and ', or' to the second.
    if(types.length == 3) {
      types[0] += ','
      types[1] += ', or'
    }

    return types.join(' ')
  }
}
