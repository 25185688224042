const middleware = {}

middleware['anonymous'] = require('../src/middleware/anonymous.js')
middleware['anonymous'] = middleware['anonymous'].default || middleware['anonymous']

middleware['authenticated'] = require('../src/middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['cast-ids-in-routes-params'] = require('../src/middleware/cast-ids-in-routes-params.js')
middleware['cast-ids-in-routes-params'] = middleware['cast-ids-in-routes-params'].default || middleware['cast-ids-in-routes-params']

middleware['force-reload-on-scope-change'] = require('../src/middleware/force-reload-on-scope-change.js')
middleware['force-reload-on-scope-change'] = middleware['force-reload-on-scope-change'].default || middleware['force-reload-on-scope-change']

middleware['is-admin'] = require('../src/middleware/is-admin.js')
middleware['is-admin'] = middleware['is-admin'].default || middleware['is-admin']

middleware['is-ho-admin'] = require('../src/middleware/is-ho-admin.js')
middleware['is-ho-admin'] = middleware['is-ho-admin'].default || middleware['is-ho-admin']

middleware['push-user-id-to-datalayer'] = require('../src/middleware/push-user-id-to-datalayer.js')
middleware['push-user-id-to-datalayer'] = middleware['push-user-id-to-datalayer'].default || middleware['push-user-id-to-datalayer']

middleware['redirect-if-ie'] = require('../src/middleware/redirect-if-ie.js')
middleware['redirect-if-ie'] = middleware['redirect-if-ie'].default || middleware['redirect-if-ie']

middleware['redirect-to-appeal23'] = require('../src/middleware/redirect-to-appeal23.js')
middleware['redirect-to-appeal23'] = middleware['redirect-to-appeal23'].default || middleware['redirect-to-appeal23']

middleware['redirect-to-donate-ff'] = require('../src/middleware/redirect-to-donate-ff.js')
middleware['redirect-to-donate-ff'] = middleware['redirect-to-donate-ff'].default || middleware['redirect-to-donate-ff']

middleware['redirect-to-lwc-shop'] = require('../src/middleware/redirect-to-lwc-shop.js')
middleware['redirect-to-lwc-shop'] = middleware['redirect-to-lwc-shop'].default || middleware['redirect-to-lwc-shop']

middleware['redirect-to-news'] = require('../src/middleware/redirect-to-news.js')
middleware['redirect-to-news'] = middleware['redirect-to-news'].default || middleware['redirect-to-news']

middleware['rewrite-underscores-to-dashes'] = require('../src/middleware/rewrite-underscores-to-dashes.js')
middleware['rewrite-underscores-to-dashes'] = middleware['rewrite-underscores-to-dashes'].default || middleware['rewrite-underscores-to-dashes']

export default middleware
