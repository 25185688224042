import merge from 'lodash/merge'

/**
 * @typedef {{}} TeamsSearchConfig
 * @property {DictionaryValue[]} categories
 * @property {boolean} loaded
 * @property {number} page
 * @property {number} perPage
 */
/**
 * @type {TeamsSearchConfig}
 */
const searchConfig = {
  categories: [],
  loaded: false,
  page: 1,
  perPage: 15,
}

/**
 * @typedef {{}} TeamsSearchDefaults
 * @property {null} category
 * @property {number} page
 * @property {number} perPage
 * @property {null} keywords
 * @property {string} sort
 */
/**
 * @type {TeamsSearchDefaults}
 */
const searchDefaults = {
  category: null,
  page: 1,
  perPage: 15,
  keywords: null,
  sort: 'loansValue',
}

/**
 * @typedef {{}} TeamsSearchParams
 * @property {string|null} category
 * @property {number} page
 * @property {number} perPage
 * @property {string|null} keywords
 * @property {string} sort
 */

/**
 * @typedef {{}} Teams~FrontendStore~State
 * @property {{}} search
 * @property {TeamsSearchConfig} search.config
 * @property {TeamsSearchDefaults} search.defaults
 * @property {TeamsSearchParams} search.params
 * @property {{}} search.results
 * @property {number} search.results.currentPage
 * @property {Team[]} search.results.items
 * @property {number} search.results.pages
 * @property {number} search.results.total
 * @property {boolean} inProgress
 * @property {Team|null} current
 */
export default function () {
  return {
    search: {
      config: merge({}, searchConfig),
      defaults: merge({}, searchDefaults),
      params: merge({}, searchDefaults),
      results: {
        currentPage: 1,
        items: [],
        pages: 1,
        total: 0,
      },
      inProgress: false,
    },
    editConfig: null,
    current: null,
  }
}
