export default {
  namespaced: true,

  getters: {
    isEnabled: state => state.UKAid,
  },
  state: {
    UKAid: process.env.UK_AID,
  },
}
