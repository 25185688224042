/** @module modules/loans/locales/en */

export default {
  errors: {
    invalidEntrepreneurIdInQuery: `The entrepreneur ID in the URL is invalid.`,
    entrepreneur404: `Sorry, we couldn't find an entrepreneur with ID {entrepreneurId}.`,
    entrepreneurLoadingFailed: `Sorry, there was a problem fetching entrepreneur with ID: {entrepreneurId}.`,
  },
  validators: {
    total: {
      message: `Total amount of scheduled repayments must equal the full loan amount.`,
    },
  },
  notifications: {
    loanCreated: 'Loan has been created.',
    loanCreateFailed: `Sorry, the data you submitted seem to be invalid.`,
    loanUpdated: 'Loan has been updated.',
    loanUpdateFailed: `Sorry, the data you submitted seem to be invalid.`,
    loanRejected: 'Loan has been rejected.',
  },
  pages: {
    index: {
      heading: 'Loans',
      breadcrumb: 'Loans',
    },
    create: {
      heading: 'Add a loan',
      breadcrumb: 'Add a loan',
      entrepreneurChosen: 'Please see the details of chosen entrepreneur in a side&ndash;box.',
      resetEntrepreneur: 'Change entrepreneur',
    },
  },
}
