export const apiRoutes = {
  admin: {
    bulkPublish: '/admin/loan/publish',
    bulkStatus: '/admin/loan/status',
    create: '/admin/loan',
    get: '/admin/loan/<%= loanId %>',
    index: '/admin/loan',
    loansConfig: '/admin/loan/options',
    messages: {
      index: '/admin/loan/messages',
      create: '/admin/loan/<%= loanId %>/messages',
    },
    status: '/admin/loan/<%= loanId %>/status',
    template: '/admin/loan/download/template',
    update: '/admin/loan/<%= loanId %>',
    loanAttachment: {
      create: '/admin/loan/<%= loanId %>/attachment',
      get: '/admin/loan/<%= loanId %>/attachment',
      download: '/admin/loan/<%= loanId %>/attachment/download',
      delete: '/admin/loan/<%= loanId %>/attachment',
    },
  },
  frontend: {
    get: '/loan/<%= loanId %>',
    index: '/loan',
    searchConfig: '/loan/search-config',
  },
}

/**
 * The prefix to images' public IDs.
 * @type {string}
 */
export const imagePublicIdPrefix = 'lwc/loans'

/**
 * Preset name for uploading a loan image to Cloudinary.
 * @const {string}
 */
export const imageUploadPresetName = 'loan_photo'

/**
 * Available amounts for a "quick lend" action.
 * @type {number[]}
 */
export const pricePoints = [
  1500, 3000, 4500, 6000, 7500, 10000, 15000, 20000, 25000, 30000, 50000, 100000,
]

/**
 * Permissions to check in navigation.
 * @type {{mfi: string, ho: string}}
 */
export const permissions = {
  ho: 'ho-loans',
  mfi: 'mfi-loans',
}

/**
 * @typedef {Object} Loans~Config
 * @property {Loans~Config~ApiRoutes} apiRoutes
 * @property {string} imageUploadPresetName
 */
/**
 * @type {Loans~Config}
 */
export default {
  apiRoutes,
  imageUploadPresetName,
  imagePublicIdPrefix,
  pricePoints,
}
