import sortBy from 'lodash/sortBy'
import Vue from 'vue'
import EventBus from '~/bus'
import events from './events'
import Job from './job'
import Queue from './queue'

const CONCURRENT = 2

/**
 * @extends {Component}
 * @property {Store} $store
 */
const QueueService = {
  data () {
    return {
      events,
      numRunning: 0,
      queueCollection: [
        new Queue('default', this),
      ],
    }
  },
  computed: {
    /**
     * Return true if the default queue is running.
     * @return {boolean}
     */
    isRunning () {
      return this.queues.default.isRunning
    },
    queues () {
      const queues = {}

      for (let queue of this.queueCollection) {
        queues[queue.name] = queue
      }

      return queues
    },
  },
  methods: {
    addJob (definition, queue = 'default') {
      if (typeof this.queues[queue] === 'undefined') {
        console.warn(`Auto-creating queue: "${queue}".`)
        this.addQueue(queue)
      }

      this.queues[queue].addJob(definition)
    },
    addQueue (name, priority = 10) {
      if (typeof this.queues[name] === 'undefined') {
        const newQueue = new Queue(name, this, priority)
        this.queueCollection.push(newQueue)

        EventBus.$emit(events.EVENT_QUEUE_ADDED, newQueue)

        return newQueue
      }

      return this.queues[name]
    },
    /**
     * Get an existing queue, or create and return a new one.
     * @param {string} name
     * @returns {Queue}
     */
    getOrCreate (name) {
      let queue = this.getQueue(name)

      if (!queue) {
        queue = this.addQueue(name)
      }

      return queue
    },
    /**
     * Get an instance of a queue
     * @param name
     * @return {*}
     */
    getQueue (name) {
      return this.queues[name] instanceof Queue ? this.queues[name] : undefined
    },
    onJobAdded () {
      this.$nextTick(function () {
        this.run()
      })
    },
    onJobFinished () {
      this.$nextTick(function () {
        this.run()
      })
      this.numRunning--
    },
    /**
     * Remove existing queue.
     */
    removeQueue (name) {
      if (name === 'default') {
        console.warn('You cannot remove the default queue.')
        return
      }

      if (typeof this.queues[name] !== 'undefined') {
        this.queueCollection = this.queueCollection.filter(q => q.name !== name)
        this.queues[name] = undefined
      }
    },
    /**
     * Run the queue.
     */
    async run () {
      if (this.numRunning >= CONCURRENT) return

      // Find valid queues with the highest priority.
      const validQueues = getPopulatedQueues(this.queueCollection)
      const prioritizedQueues = getHighestPriorityQueues(validQueues)
      const job = getOldestJob(prioritizedQueues)

      if (job instanceof Job && job.isRunning === false) {
        // Increment current number of running jobs.
        this.numRunning++
        // Mark the job as running.\
        job.isRunning = true
        // Run the job after variables update.
        this.$nextTick(async function () {
          await job.run()
        })
      }
    },
  },
  created () {
    EventBus.$on(events.EVENT_JOB_ADDED, this.onJobAdded)
    EventBus.$on(events.EVENT_JOB_FINISHED, this.onJobFinished)
  },
}

/**
 * Return the oldest job from the collection of queues.
 * @param {Queue[]} queues
 * @return {Job}
 */
function getOldestJob (queues) {
  const jobs = []

  queues.map(q => jobs.push(...q.jobs))

  return sortBy(jobs, 'addedAt')[0]
}

/**
 * Return only populated queues (with jobs).
 * @param {Queue[]} queues
 * @return {Queue[]}
 */
function getPopulatedQueues (queues) {
  return Object.values(queues).filter(q => q.jobs.length > 0)
}

/**
 * Return an array of queues with the highest priority.
 * @param {Queue[]} queues
 * @return {Queue[]}
 */
function getHighestPriorityQueues (queues) {
  let highestYet = Number.POSITIVE_INFINITY

  queues.forEach(q => {
    if (q.priority < highestYet) {
      highestYet = q.priority
    }
  }, this)

  return queues.filter(q => q.priority === highestYet)
}

export default function () {
  return new Vue(QueueService)
}
