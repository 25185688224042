import BaseImage from '~/entities/image'
import { locationMapImagePublicIdPrefix } from '~/modules/grants/config'
import { getCloudinaryURL } from '~/utils/cloudinary'

export default class LocationMapImage extends BaseImage {
  /**
   * Get default prefix.
   * @returns {string}
   */
  get prefix () {
    return locationMapImagePublicIdPrefix
  }

  /**
   * Return a URL to the image resized to 250px wide using the original aspect
   * ratio.
   * @returns {string}
   */
  get small () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 250,
    })
  }

  /**
   * Return a URL to the image resized to 500px wide using the original aspect
   * ratio.
   * @returns {string}
   */
  get medium () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 500,
    })
  }

  /**
   * Return a URL to the image resized to 1000px wide using the original aspect
   * ratio.
   * @returns {string}
   */
  get large () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 1000,
    })
  }
}
