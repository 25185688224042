/** @module modules/loans/store/state */

/**
 * @typedef {Object} Loans~StoreState
 * @property {Object} config
 * @property {Loan} current
 * @property {{}} forms
 * @property {{}|null} forms.loan
 * @property {{}|null} forms.schedule
 */
/**
 * @type {Loans~StoreState}
 */
export default {
  config: {},
  current: null,
  datagrids: {
    loans: null,
  },
  forms: {
    loan: null,
    schedule: null,
  },
}
