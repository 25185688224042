import get from 'lodash/get'
import isString from 'lodash/isString'
import { error } from '~/utils/errors'

/**
 * Iterate over an array of items and try converting them to entities of
 * the given constructor (`Ctor`) type. Returns an array of only those items
 * that could have been successfully converted or have been instances of
 * the `Ctor` from the start.
 * @param {*[]} items
 * @param {function} Ctor
 * @return {Ctor[]}
 */
export function collectionToEntities (items, Ctor) {
  if (!Array.isArray(items)) return null

  return items
    .map(item => itemToEntity(item, Ctor))
    .filter(item => item instanceof Ctor)
}

/**
 * Return current hostname based on window.location.
 * @return {string}
 */
export function getCurrentHost () {
  const defaultHost = ''

  if (typeof window !== 'undefined') {
    return get(window, 'location.origin', defaultHost)
  }

  return defaultHost
}

/**
 * Method for easy setting pages' titles and appending the
 * "Microfinance from CARE International UK" string to the end of the page
 * title, separated by a pipe character.
 *
 * This function is meant to be bound to the context of the calling Vue component!
 * Example (in component):
 * @example export default {
 *   layout: 'frontend',
 *   head: {
 *     title: getPageTitle.call(this, 'Page title'),
 *   },
 * }
 *
 * @param {string} title - Page title to set.
 * @param {boolean} [standalone=false] - Whether to append the suffix.
 */
export function getPageTitle (title, standalone = false) {
  const defaultTitle = get(this, '$root.$options.head.title', 'Microfinance from CARE International UK')

  if (isString(title) && title.length) {
    return standalone ? title : `${title} | ${defaultTitle}`
  }

  return defaultTitle
}

/**
 * Check if given target has a method by the given name.
 * @param {*} target
 * @param {string} method
 */
export function hasMethod (target, method) {
  const found = get(target, method)

  return typeof found === 'function'
}

/**
 * Return an item transformed to an entity of a given constructor (Ctor) type,
 * or `undefined` if transforming failed. If `item` is already an instance of
 * the given Ctor, return it immediately.
 * @param {*} item
 * @param {function} Ctor
 * @return {Ctor|undefined}
 */
export function itemToEntity (item, Ctor) {
  try {
    return item instanceof Ctor ? item : new Ctor(item)
  } catch (err) {
    error(err)
  }
}

/**
 * Return an array of collection items' fields.
 * @param {{}[]} collection - Array of objects.
 * @param {string} field - The name of a property to pluck from each object.
 * @return {[]}
 */
export function pluck (collection, field) {
  if (Array.isArray(collection)) {
    return collection
      .map(item => get(item, field, null))
      .filter(item => item !== null)
  }

  return []
}

/**
 * Return a standard response object returned by LWC API, and unwrap it from
 * all the "data" proxy properties.
 *
 * Example input:
 * {
 *   data: {
 *     id: 1,
 *     params: {
 *       data: [
 *         'foo',
 *         'bar',
 *         'baz',
 *       ],
 *     },
 *     child: {
 *       data: {
 *         childId: 2,
 *         childName: 'child',
 *       }
 *     }
 *   },
 * }
 * Output:
 * {
 *   id: 1,
 *   params: ['foo', 'bar', 'baz'],
 *   child: {
 *     childId: 2,
 *     childName: 'child',
 *   },
 * }
 * @param obj
 * @returns {{}|*|null}
 */
export function unwrapResponse (obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => unwrapResponse(item))
  }
  if (typeof obj === 'object' && obj !== null) {
    const transformed = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (key === 'data') {
          return unwrapResponse(value)
        } else {
          transformed[key] = unwrapResponse(value)
        }
      }
    }
    return transformed
  }
  if (obj === undefined) {
    return null // Convert undefined to null
  }
  return obj // Leave null values as they are
}

export default {
  collectionToEntities,
  getCurrentHost,
  getPageTitle,
  hasMethod,
  itemToEntity,
  pluck,
  unwrapResponse,
}
