export const apiRoutes = {
  admin: {
    archive: '/admin/repayments/users/repaid',
    exclude: '/admin/repayments/users/due/exclude',
    excluded: '/admin/repayments/users/due/excluded',
    include: '/admin/repayments/users/due/include',
    pending: '/admin/repayments/users/due',
    release: '/admin/repayments/users/release',
  },
}

/**
 * User permissions to check when rendering navigation and before performing actions
 * @type {{ho: string}}
 */
export const permissions = {
  ho: 'ho-business-repay-lenders',
}

export default {
  apiRoutes,
}
