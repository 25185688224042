import { get, isSafeInteger } from 'lodash'
import camelCase from 'lodash/camelCase'
import { imagePublicIdPrefix } from '~/modules/users/config'
import AdminUser from '~/modules/users/entities/admin/user'
import TransactionEntities from '~/modules/users/entities/frontend/transactions'
import { repaymentTypes } from '~/modules/users/entities/frontend/transactions/repayment'
import { warn } from '~/utils/errors'
import { itemToEntity } from '~/utils/framework'

/**
 * getDefaultAvatar
 * @param type
 * @returns {string}
 */
export function getDefaultAvatar (type) {
  let suffix

  switch (type) {
    default:
    case 'individual':
      suffix = 'avatar_single'
      break
    case 'joint':
      suffix = 'avatar_joint'
      break
    case 'organisation':
      suffix = 'avatar_organisation'
      break
  }

  return `${imagePublicIdPrefix}/${suffix}`
}

/**
 * userPageValidator extracts user ID from route params and tries to fetch user record for the ID.
 * @param {function} error - Nuxt error trigger function.
 * @param {{}} lodash
 * @param {{}} params
 * @param {number} params.userId
 * @param {Store} store
 * @return {Promise<boolean>}
 */
export async function userPageValidator ({ error, lodash, params, store }) {
  try {
    const userId = get(params, 'userId')

    if (!isSafeInteger(userId)) {
      return false
    }

    const userInStore = store.getters['users/admin/user']

    if (userInStore instanceof AdminUser && userInStore.id === userId) {
      return true
    }

    const user = await store.dispatch('users/admin/fetch', { id: params.userId })

    if (user instanceof AdminUser) {
      return true
    }
  } catch (err) {
    error({ statusCode: 404, message: `There is no user with ID ${params.userId}.` })
  }

  return false
}

/**
 * transformUserTransactions maps user transactions received as raw API data to entities.
 * @param {{}[]} transactions
 * @return {Transaction[]}
 */
export function transformUserTransactions (transactions = []) {
  if (!Array.isArray(transactions)) {
    warn('The "transactions" attribute must be a collection of unmapped user transactions.')

    return []
  }

  return transactions
    .map((item) => maybeTransformTransactionItemToEntity(item, getTransactionType(item)))
    .filter((transaction) => transaction instanceof TransactionEntities.Transaction)
}

/**
 * getTransactionType returns a normalized, identifiable type of a transaction item, that can be later
 * matched against known entity constructors.
 * @param transactionItem
 * @returns {string}
 */
function getTransactionType(transactionItem) {
  return camelCase(get(transactionItem, 'type'))
}

/**
 * maybeTransformTransactionItemToEntity returns an entity constructor function
 * that should be used with the given transaction `type`.
 * @param {string} type
 * @returns {null|function}
 */
function maybeTransformTransactionItemToEntity(item, type) {
  switch (type) {
    case 'donation':
      return itemToEntity(item, TransactionEntities.Donation)
    case 'dormant':
      return itemToEntity(item, TransactionEntities.Dormant)
    case 'microgrant':
      return itemToEntity(item, TransactionEntities.Microgrant)
    case 'microloan':
      return itemToEntity(item, TransactionEntities.Microloan)
    case 'repayment':
      return itemToEntity(item, TransactionEntities.Repayment)
    case 'topUp':
      return itemToEntity(item, TransactionEntities.TopUp)
    case 'giftVoucherPurchased':
    case 'giftVoucherRedeemed':
    case 'voucher':
      return itemToEntity(item, TransactionEntities.Voucher)
    case 'withdrawal':
      return itemToEntity(item, TransactionEntities.Withdrawal)
    case 'reclaimedBonus':
      return itemToEntity(item, TransactionEntities.ReclaimedBonus)
  }

  return null
}

/**
 * isRepaymentGrantRelated returns `true` if the given repayment type is associated
 * with grants, * `false` otherwise.
 * @param {string} repaymentType
 * @returns {boolean}
 */
export const isRepaymentGrantRelated = (repaymentType) => {
  return [
    repaymentTypes.REPAYMENT_TYPE_GRANT_BUFFER,
    repaymentTypes.REPAYMENT_TYPE_GRANT_CANCELLATION,
  ].includes(repaymentType)
}

/**
 * isRepaymentLoanRelated returns `true` if the given repayment type is associated
 * with loans, `false` otherwise.
 * @param {string} repaymentType
 * @returns {boolean}
 */
export const isRepaymentLoanRelated = (repaymentType) => {
  return [
    repaymentTypes.REPAYMENT_TYPE_LOAN,
    repaymentTypes.REPAYMENT_TYPE_LOAN_BUFFER,
    repaymentTypes.REPAYMENT_TYPE_LOAN_CANCELLATION,
  ].includes(repaymentType)
}
