/** @module modules/teams/store/frontend/actions */

import parse from 'date-fns/parse'
import defaults from 'lodash/defaults'
import get from 'lodash/get'
import omit from 'lodash/omit'
import template from 'lodash/template'
import Microloan from '~/modules/loans/entities/frontend/microloan-grid-item'
import { apiRoutes } from '~/modules/teams/config'
import Team from '~/modules/teams/entities/frontend/team'
import TeamMember from '~/modules/teams/entities/frontend/team-member'
import Lender from '~/modules/users/entities/frontend/lender'
import { getInstance } from '~/plugins/http'

/**
 * Accept pending member's request to join a private team.
 * @param {Store} store - Vuex store.
 * @param {string} teamUuid - Team UUID.
 * @param {string} userUuid - Candidate UUID.
 * @return {Promise<Response>}
 */
const acceptMember = async function (store, { teamUuid, userUuid }) {
  const uri = template(apiRoutes.frontend.myAccount.acceptMember)({ uuid: teamUuid })
  return getInstance().put(uri, { userUuid })
}

/**
 * Create a new team.
 * @param {function} commit
 * @param {{}} getters
 * @param {{}} data - Form data.
 * @return {Promise<{Team}>}
 */
const create = async function ({ commit, getters }, data) {
  const response = await getInstance().post(apiRoutes.frontend.myAccount.create, data)

  const team = new Team(get(response, 'data.data'))
  commit('SET_TEAM', team)

  return getters.team
}

/**
 * Fetch team record from the API.
 * @param {function} commit - Vuex mutation dispatcher.
 * @param {{}} getters - Team module's collection of getters.
 * @param {string} slug - Team slug.
 * @returns {Promise<Team|null>}
 */
const fetch = async function ({ commit, getters }, slug) {
  const uri = template(apiRoutes.frontend.get)({ slug })
  const response = await getInstance().get(uri)
  const teamData = get(response, 'data.data', {})
  const team = new Team(teamData)

  commit('SET_TEAM', team)

  return getters.team
}

/**
 * Fetch team record from the API using team UUID.
 * @param {function} commit
 * @param {{}} getters
 * @param {string} uuid
 * @return {Promise<void>}
 */
const fetchByUuid = async function ({ commit, getters }, uuid) {
  const uri = template(apiRoutes.frontend.myAccount.get)({ uuid })
  const response = await getInstance().get(uri)
  const teamData = get(response, 'data.data', {})
  const team = new Team(teamData)

  commit('SET_TEAM', team)

  return getters.team
}

/**
 * Fetch configuration for team creation process.
 * @param {function} commit
 * @param {{}} getters
 * @return {Promise<*>}
 */
const fetchEditConfig = async function ({ commit, getters }) {
  const response = await getInstance().get(apiRoutes.frontend.editConfig)

  commit('SET_EDIT_CONFIG', get(response, 'data.data.config'))

  return getters.editConfig
}

/**
 * Fetch configuration for team search process.
 * @param {function} commit - Vuex mutation dispatcher.
 * @param {Teams~FrontendStore~Getters} getters - Teams module getters.
 * @return {Promise<void>}
 */
const fetchSearchConfig = async function ({ commit, getters }) {
  const response = await getInstance().get(apiRoutes.frontend.searchConfig)

  const config = defaults(get(response, 'data.data'), omit(getters.searchConfig, 'loaded'))

  commit('SET_SEARCH_CONFIG', {
    ...config,
    loaded: true,
  })
}

/**
 * Fetch team activity from the API.
 * @param {Store} store - Vuex store
 * @param {string} slug - Team slug.
 * @return {Promise<{items: *[], pagination: {}}>}
 */
const fetchActivity = async function (store, { slug }) {
  const uri = template(apiRoutes.frontend.profile.getLatestActivity)({ slug })
  const response = await getInstance().get(uri)
  const items = get(response, 'data.data', [])

  const mapped = items.map(item => {
    try {
      switch (item.type) {
        case 'donation':
          return _mapDonation(item)
        case 'microloan':
          return _mapMicroloan(item)
      }
    } catch (e) {
      console.error(e)
    }
  }).filter(item => typeof item !== 'undefined')

  return { items: mapped, pagination: {} }
}

/**
 * Get paginated team members. Allows searching for both current and pending
 * members.
 * @param {Store} store - Vuex store.
 * @param {number} page - Current page of results.
 * @param {number} perPage - Number of items per page.
 * @param {boolean} pending - Fetch only pending members.
 * @param {string} uuid - Team UUID.
 * @return {Promise<{data, meta}>}
 */
const fetchMembers = async function (store, { page, perPage = 15, pending = false, uuid }) {
  const uri = template(apiRoutes.frontend.myAccount.getMembers)({ uuid })
  const params = {
    page,
    limit: perPage,
    pendingMember: pending,
  }

  const response = await getInstance().get(uri, { params })

  return {
    data: get(response, 'data.data', []).map(memberData => new TeamMember(memberData)),
    pagination: get(response, 'data.meta.pagination', {}),
  }
}

/**
 * Fetch team members from the API.
 * @param {Store} store - Vuex store
 * @param {string} page - Page number.
 * @param {string} slug - Team slug.
 * @return {Promise<{members: [], pagination: {}}>}
 */
const fetchPublicMembers = async function (store, { page, slug, limit }) {
  const uri = template(apiRoutes.frontend.profile.getMembers)({ slug })
  const response = await getInstance().get(uri, {
    params: {
      page: page || 1,
      limit: limit || 15,
    },
  })
  const data = get(response, 'data.data', [])

  const members = data.map(member => {
    return new Lender(member)
  })
  const pagination = get(response, 'data.meta.pagination')

  return { members, pagination }
}

/**
 * Fetch team loans by slug from the API.
 * @param {Store} store - Vuex store
 * @param {string} page - Page number.
 * @param {string} slug - Team slug.
 * @return {Promise<{loans: [], pagination: {}}>}
 */
const fetchMicroloans = async function (store, { page, slug }) {
  const uri = template(apiRoutes.frontend.profile.getLoans)({ slug })
  const response = await getInstance().get(uri, {
    params: {
      page: page || 1,
    },
  })

  return {
    items: get(response, 'data.data', []).map(item => {
      return new Microloan(item)
    }),
    pagination: get(response, 'data.meta.pagination'),
  }
}

/**
 * Perform a team-join request to the API.
 * @param {function} commit - Vuex mutation handler.
 * @param {{}} getters - Team module's frontend getters collection.
 * @param {string} uuid - Team UUID.
 * @return {Promise<Team|null>}
 */
const join = async function ({ commit, getters }, { uuid }) {
  const uri = template(apiRoutes.frontend.join)({ uuid })
  const response = await getInstance().post(uri)

  const teamData = get(response, 'data.data', {})
  const team = new Team(teamData)

  commit('SET_TEAM', team)

  return getters.team
}

/**
 * Perform a leave request to the API.
 * @param {function} commit - Vuex mutation handler.
 * @param {{}} getters - Team module's frontend getters collection.
 * @param {string} uuid - Team UUID.
 * @return {Promise<Team>}
 */
const leave = async function ({ commit, getters }, { uuid }) {
  const uri = template(apiRoutes.frontend.myAccount.leave)({ uuid })
  await getInstance().put(uri)
}

/**
 * Promote member to team administrator.
 * @param {Store} store
 * @param {string} teamUuid
 * @param {string} userUuid
 * @return {Promise<void>}
 */
const makeMemberAdmin = async function (store, { teamUuid, userUuid }) {
  const uri = template(apiRoutes.frontend.myAccount.makeAdmin)({ uuid: teamUuid })
  return getInstance().put(uri, { userUuid })
}

/**
 * Reject pending member's request to join a private team.
 * @param {Store} store - Vuex store.
 * @param {string} rejectionReason
 * @param {string} teamUuid - Team UUID.
 * @param {string} userUuid - Candidate UUID.
 * @return {Promise<Response>}
 */
const rejectMember = async function (store, { rejectionReason, teamUuid, userUuid }) {
  const uri = template(apiRoutes.frontend.myAccount.rejectMember)({ uuid: teamUuid })
  return getInstance().put(uri, { rejectionReason, userUuid })
}

/**
 * Remove member from team.
 * @param {Store} store
 * @param {string} teamUuid
 * @param {string} userUuid
 * @return {Promise<Response>}
 */
const removeMember = async function (store, { teamUuid, userUuid }) {
  const uri = template(apiRoutes.frontend.myAccount.removeMember)({ uuid: teamUuid })
  return getInstance().put(uri, { userUuid })
}

/**
 * Resign as team admin.
 * @param {Store} store
 * @param {string} uuid - Team UUID.
 * @return {Promise<Team>}
 */
const revokeAdmin = async function (store, { uuid }) {
  const uri = template(apiRoutes.frontend.myAccount.revokeAdmin)({ uuid })
  await getInstance().put(uri)
}

/**
 * Search for teams.
 * @param {function} commit - Vuex mutation dispatcher.
 * @param {Teams~FrontendStore~Getters} getters
 * @param {{}} [params={}] - Search parameters.
 * @return {Promise<{}>}
 */
const search = async function ({ commit, getters }, params = {}) {
  // Reset current search results in state.
  commit('SET_SEARCH_RESULTS', [])
  // Store current search parameters.
  commit('SET_SEARCH_PARAMS', params)
  commit('SET_SEARCH_IN_PROGRESS', true)

  // Send a request to the API with search parameters.
  try {
    const response = await getInstance().get(apiRoutes.frontend.index, { params })
    const collection = get(response, 'data.data', [])

    // Store search results in state.
    commit('SET_SEARCH_RESULTS', collection.map(teamData => new Team(teamData)))
    commit('SET_SEARCH_RESULTS_META', get(response, 'data.meta'))
  } catch (e) {
    commit('SET_SEARCH_RESULTS', [])
    commit('SET_SEARCH_RESULTS_META', null)
  }

  commit('SET_SEARCH_IN_PROGRESS', false)
  // Return search results.
  return getters.searchResults
}

/**
 * Update team record in the API.
 * @param {function} commit
 * @param {{}} getters
 * @param {string} uuid - Team UUID.
 * @param {{}} data - Serialized team model.
 * @return {Promise<Team|null>}
 */
const update = async function ({ commit, getters }, { uuid, data }) {
  const uri = template(apiRoutes.frontend.myAccount.update)({ uuid })
  const response = await getInstance().put(uri, data)

  const team = new Team(get(response, 'data.data'))

  commit('SET_TEAM', team)

  return getters.team
}

/**
 * Update team avatar.
 * @param {function} commit
 * @param {{}} getters
 * @param {string} uuid
 * @param {string} avatar
 * @returns {Promise<Team>}
 */
const updateAvatar = async function ({ commit, getters }, { uuid, avatar }) {
  const uri = template(apiRoutes.frontend.myAccount.updateAvatar)({ uuid })
  const response = await getInstance().post(uri, { avatar })

  commit('SET_TEAM', new Team(get(response, 'data.data')))

  return getters.team
}

function _mapDonation (item) {
  try {
    return {
      createdAt: parse(item.createdAt),
      id: item.id,
      type: 'donation',
      user: new Lender(item.user.data),
    }
  } catch (e) {
    console.error(e)
  }
}

function _mapMicroloan (item) {
  try {
    return {
      createdAt: parse(item.createdAt),
      id: item.id,
      microloan: new Microloan(item.microloan.data),
      type: 'microloan',
      user: new Lender(item.user.data),
    }
  } catch (e) {
    console.error(e)
  }
}

export default {
  acceptMember,
  create,
  fetch,
  fetchByUuid,
  fetchEditConfig,
  fetchSearchConfig,
  fetchActivity,
  fetchMembers,
  fetchMicroloans,
  fetchPublicMembers,
  join,
  leave,
  makeMemberAdmin,
  rejectMember,
  removeMember,
  revokeAdmin,
  search,
  update,
  updateAvatar,
}
