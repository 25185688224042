/**
 * @typedef {{}} Withdrawals~Permissions
 * @property ho {string}
 */
/**
 * User permissions to check when rendering navigation or before performing actions
 * @type {Withdrawals~Permissions}
 */
export const permissions = {
  ho: 'ho-business-withdrawals',
}

export const apiRoutes = {
  admin: {
    batches: {
      create: '/admin/withdrawal-batches',
      download: '/admin/withdrawal-batches/<%= batchId %>/downloads/paypal',
      index: '/admin/withdrawal-batches',
    },
    completed: '/admin/withdrawals/complete',
    pending: '/admin/withdrawals/pending',
    requested: '/admin/withdrawals/unbatched',
    setSuccessful: '/admin/withdrawals/<%= withdrawalId %>/successful',
    setUnsuccessful: '/admin/withdrawals/<%= withdrawalId %>/unsuccessful',
  },
}
