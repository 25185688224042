/** @module modules/entrepreneurs/store/actions */

import { apiRoutes, genders } from '~/modules/entrepreneurs/config'
import { SET_CONFIG, SET_ENTREPRENEUR } from '~/modules/entrepreneurs/store/mutation-types'
import { getInstance } from '~/plugins/http'
import get from 'lodash/get'
import merge from 'lodash/merge'
import template from 'lodash/template'

/**
 * Create an entrepreneur in API.
 *
 * @param {function} commit
 * @param {object} data
 * @returns {Promise<Response>}
 */
const create = async function ({ commit }, data) {
  const response = await getInstance().post(apiRoutes.admin.store, data)
  const entrepreneur = get(response, 'data.data', null)

  commit(SET_ENTREPRENEUR, entrepreneur)

  return response
}

/**
 * Return an entrepreneur from state. If there is no entrepreneur in state, fetch it from
 * the API, set it in the state and then return it.
 * @param {Store} store
 * @param {function} store.commit
 * @param {EntrepreneursStore~State} store.state
 * @param {{}} config - Axios request configuration.
 * @param {number} entrepreneurId
 * @returns {Promise<void>}
 */
const fetch = async function ({ commit, state }, { config = {}, entrepreneurId }) {
  const uri = template(apiRoutes.admin.update)({ entrepreneurId })
  const response = await getInstance().get(uri, config)
  const entrepreneur = get(response, 'data.data', null)

  commit(SET_ENTREPRENEUR, entrepreneur)

  return state.current
}

/**
 * Fetch entrepreneurs configuration from the API.
 * @param {Context} context
 * @param {Function} context.commit
 * @param {Object} context.state
 * @returns {Promise<void>}
 */
const fetchConfig = async function ({ commit, state }) {
  // Fetch configuration from API.
  const response = await getInstance().get(apiRoutes.admin.config)
  const config = get(response, 'data.data.config')

  // Store config in state.
  commit(SET_CONFIG, config)

  return state.config
}

/**
 * Fetch configuration items for entrepreneur creation form.
 * @param {Context} context
 * @param {Function} context.dispatch
 * @return {Object}
 */
const fetchCreateConfig = async function ({ dispatch }) {
  const config = await dispatch('fetchConfig')
  const gendersWithLabels = genders.map(entry => ({
    value: entry.value,
    label: this.app.i18n.t(`modules.entrepreneurs.config.genders.${entry.value}`),
  }), this)

  return merge({}, config, { genders: gendersWithLabels })
}

/**
 * Search for an entrepreneur in API.
 * @param {Store} store - Store context
 * @param {Object} params - Search parameters
 * @returns {Promise<void>}
 */
const search = async function (store, params) {
  return getInstance().get(apiRoutes.admin.index, params)
}

/**
 * Update an entrepreneur in API.
 *
 * @kind {Function}
 * @param {Function} commit
 * @param {Number} entrepreneurId
 * @param {Object} data - Entrepreneur model data.
 * @returns {Promise<Response>}
 */
const update = async function ({ commit }, { entrepreneurId, data }) {
  const uri = template(apiRoutes.admin.update)({ entrepreneurId })
  const response = await getInstance().put(uri, data)
  const entrepreneur = get(response, 'data.data', null)

  commit(SET_ENTREPRENEUR, entrepreneur)

  return response
}

/**
 * @typedef {object} EntrepreneursStore~Actions
 * @property {Function} create
 * @property {Function} fetch
 * @property {Function} fetchConfig
 * @property {Function} fetchCreateConfig
 * @property {Function} search
 * @property {Function} update
 */
/**
 * @type {EntrepreneursStore~Actions}
 */
export default {
  create,
  fetch,
  fetchConfig,
  fetchCreateConfig,
  search,
  update,
}
