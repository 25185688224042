export const EVENT_JOB_STARTED = 'lwc:queue:job:started'
export const EVENT_JOB_SUCCESSFUL = 'lwc:queue:job:successful'
export const EVENT_JOB_FAILED = 'lwc:queue:job:failed'
export const EVENT_JOB_FINISHED = 'lwc:queue:job:finished'
export const EVENT_JOB_ADDED = 'lwc:queue:job:added'
export const EVENT_JOB_REMOVED = 'lwc:queue:job:removed'
export const EVENT_QUEUE_ADDED = 'lwc:queue:queue:added'
export const EVENT_QUEUE_FINISHED = 'lwc:queue:queue:finished'

export default {
  EVENT_JOB_ADDED,
  EVENT_JOB_STARTED,
  EVENT_JOB_SUCCESSFUL,
  EVENT_JOB_FAILED,
  EVENT_JOB_FINISHED,
  EVENT_JOB_REMOVED,
  EVENT_QUEUE_ADDED,
}
