import get from 'lodash/get'
import UserStats from '~/modules/users/entities/frontend/user-stats'
import { TRANSACTIONS_DEFAULT_PAGINATION } from '~/modules/users/store/frontend/my-account/support'

export default {
  bonusStats: state => get(state, 'bonusStats', []),
  fundableGrants: state => get(state, 'fundableGrants', []),
  fundableLoans: state => get(state, 'fundableLoans', []),
  fundableGrantsCount: state => get(state, 'fundableGrantsCount', 0),
  fundableLoansCount: state => get(state, 'fundableLoansCount', 0),
  microgrants: state => get(state, 'micrograntsSearch.items', []),
  microloans: state => get(state, 'microloansSearch.items', []),
  micrograntsSearch: state => get(state, 'micrograntsSearch', null),
  microloansSearch: state => get(state, 'microloansSearch', null),
  repayments: state => get(state, 'repayments', []),
  stats: state => get(state, 'stats', new UserStats()),
  teams: state => get(state, 'teams', []),
  transactions: state => get(state, 'transactions.items', null),
  transactionsPagination: state => get(state, 'transactions.pagination', TRANSACTIONS_DEFAULT_PAGINATION),
}
