import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import { TRANSACTIONS_DEFAULT_PAGINATION } from './support'

export default {
  namespaced: true,

  actions,
  getters,
  mutations,

  state: {
    bonusStats: [],
    fundableGrants: [],
    fundableLoans: [],
    fundableGrantsCount: 0,
    fundableLoansCount: 0,
    microgrants: [],
    microloans: [],
    micrograntsSearch: null,
    microloansSearch: null,
    repayments: [],
    stats: null,
    teams: [],
    transactions: {
      items: null,
      pagination: TRANSACTIONS_DEFAULT_PAGINATION,
    },
    updates: [],
    withdrawal: null,
  },
}
