/** @module modules/updates/store/state */

/**
 * @typedef {Object} Updates~StoreState
 * @property {Object} config
 * @property {Update} current
 */
/**
 * @type {Updates~StoreState}
 */
export default {
  config: {},
  current: null,
}
