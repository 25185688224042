import { get, has, template } from 'lodash'
import { getInstance } from '~/plugins/http'

import GrantReport from '@grant-reporting/entities/grant-report'
import { apiRoutes } from '@grant-reporting/config'


/**
 * Exclude grants from a funded batch affiliated with a grant report.
 * @param {Store} store
 * @param {int} reportId
 * @param {{}} data
 * @param {string} data.note
 * @return {Promise<AxiosResponse>}
 */
const excludeGrant = async function (store, { reportId, data }) {
  const uri = template(apiRoutes.admin.grants.exclude)({ reportId: reportId })

  return getInstance().post(uri, data)
}

/**
 * Fetch a grant report from the API
 *
 * @param {Store} store
 * @param {function} store.commit
 * @param {function[]} store.getters
 * @param {GrantReportingStoreState} store.state
 * @param {number} reportId
 * @param {boolean} [convert=false] - Temporary - whether to convert the grant report to an entity.
 * @returns {Promise<GrantReport>}
 */
const fetch = async function ({ commit, getters, state }, { reportId, convert = false }) {
  const uri = template(apiRoutes.admin.get)({ reportId })
  const response = await getInstance().get(uri, {
    params: {
      include: ['grantFundedBatch', 'payment', 'totals', 'exchangeRate'],
    },
  })
  const reportData = get(response, 'data.data', null)
  const report = convert ? new GrantReport(reportData) : reportData

  commit('SET_REPORT', report)
  commit('mfis/SET_CURRENT', get(getters['report'], 'mfi.data', null), { root: true })

  return report
}

/**
 * Include grants in a funded batch affiliated with a grant report.
 * @param {Store} store
 * @param {int} reportId
 * @param {*} data
 * @return {Promise<AxiosResponse>}
 */
const includeGrant = async function (store, { reportId, data }) {
  const uri = template(apiRoutes.admin.grants.include)({ reportId })

  return getInstance().post(uri, data)
}

/**
 * Set (freeze) current batch of funded grants.
 * @param {function} commit
 * @param {number} reportId
 * @return {Promise<AxiosPromise>}
 */
const setFundedBatch = async function ({ commit }, { reportId }) {
  const uri = template(apiRoutes.admin.grants.set)({ reportId })

  const response = await getInstance().post(uri)

  if (response.status === 200 && has(response, 'data.data')) {
    commit('SET_REPORT', response.data.data)
  }
}

/**
 * Send a request setting an exchange rate between USD and funding currency
 * (or reversed).
 * @param {Store} store
 * @param {number} reportId
 * @param {{}} data
 * @return {Promise<void>}
 */
const setFundedBatchRate = async function ({ commit }, { reportId, data }) {
  const uri = template(apiRoutes.admin.grants.setRate)({ reportId })

  const response = await getInstance().post(uri, data)

  if (response.status === 200 && has(response, 'data.data')) {
    commit('SET_REPORT', response.data.data)
  }
}

/**
 * @typedef {{}} GrantReportingStoreActions
 * @property {Function} excludeGrant
 * @property {Function} fetch
 * @property {Function} includeGrant
 * @property {Function} setFundedBatch
 * @property {Function} setFundedBatchRate
 */
/**
 * @type GrantReportingStoreActions
 */
export default {
  excludeGrant,
  fetch,
  includeGrant,
  setFundedBatch,
  setFundedBatchRate,
}
