/**
 * @typedef {{}} Angels~Config~ApiRoutes
 * @property {{}} frontend
 * @property {string} frontend.config
 */

/**
 * @type {Users~Config~ApiRoutes}
 */
export const apiRoutes = {
  frontend: {
    config: '/angels/config',
  },
}

/**
 * @typedef {{}} Angels~Config
 * @property {Angels~Config~ApiRoutes} apiRoutes
 */
export default {
  apiRoutes,
}
