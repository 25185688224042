import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import Entity, { Transformer } from '~/contracts/entity'

/**
 * Entity representing team membership information for currently authenticated user.
 */
export default class TeamMembership extends Entity {
  _transformers () {
    return {
      isAdmin: Boolean,
      isDefault: Boolean,
      isMember: Boolean,
      isPendingMember: Boolean,
      joinedAt: Transformer.date(),
    }
  }

  _apiMapping () {
    return {
      createdAt: 'joinedAt',
    }
  }

  get joinedVerbose () {
    if (!this.joinedAt) return null

    return distanceInWordsToNow(this.joinedAt)
  }
}
