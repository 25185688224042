/** @module libs/currencies/store */

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import stateFactory from './state'

export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state: stateFactory(),
}
