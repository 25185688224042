import Entity, { Transformer } from '~/contracts/entity'
import Avatar from '~/modules/users/entities/frontend/avatar'

export default class Lender extends Entity {
  _transformers () {
    return {
      avatar: Transformer.itemFromPrimitive(Avatar, 'publicId'),
      displayName: String,
      id: Number,
      isAngel: Boolean,
      location: String,
      uuid: String,
    }
  }
}
