import isObject from 'lodash/isObject'

export default {
  render () {
    if (isObject(this.headingInStore)) {
      return this.headingInStore
    }

    if (typeof this.headingInStore === 'string') {
      return <h1 domPropsInnerHTML={this.headingInStore} />
    }

    return null
  },
  computed: {
    headingInStore () {
      return this.$store.getters['global/subheaderHeading']
    },
  },
}
