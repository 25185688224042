/** @module modules/loans/store/admin/getters */

/**
 * @typedef {Object} Loans~Store~Getters
 * @property {function} config
 * @property {function} loan
 * @property {function} loanForm
 * @property {function} scheduleForm
 */
/**
 * @type {Loans~Store~Getters}
 */
export default {
  config: state => state.config,
  loan: state => state.current,
  loanForm: state => state.forms.loan,
  scheduleForm: state => state.forms.schedule,
}
