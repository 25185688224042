/** @module modules/updates/store/mutations */

import set from 'lodash/set'

/**
 * @typedef {Object} Updates~Store~Mutations
 * @property {Function} SET_CONFIG
 * @property {Function} SET_UPDATE
 */
/**
 * @type {Updates~Store~Mutations}
 */
export default {
  SET_CONFIG: (state, config) => {
    state.config = config
  },
  SET_UPDATE: (state, update) => {
    state.current = update
  },
  SET_UPDATE_MESSAGES: (state, messages) => {
    set(state, 'current.messages.data', messages)
  },
}
