/** @module Auth */

/**
 * Where to redirect an authenticated user.
 * @const {string}
 */
export const afterLoginUri = '/my-account'

/**
 * Where to redirect after logging out.
 * @const {string}
 */
export const afterLogoutUri = '/'

/**
 * @typedef {object} Auth~Config~ApiRoutes
 * @property {string} config
 * @property {string} login
 * @property {string} logout
 * @property {string} refreshToken
 * @property {string} requestPasswordReset
 * @property {string} resetPassword
 */
/**
 * @type {Auth~Config~ApiRoutes}
 */
export const apiRoutes = {
  config: '/users/options',
  login: '/auth/login/',
  logout: '/auth/logout',
  refreshToken: '/auth/login/refresh',
  requestPasswordReset: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
}

/**
 * @const {number}
 */
export const passwordMaxLength = 50

/**
 * @const {number}
 */
export const passwordMinLength = 8

/**
 * @typedef {object} Auth~Config
 * @property {string} afterLoginUri
 * @property {string} afterLogoutUri
 * @property {Auth~Config~ApiRoutes} apiRoutes
 * @property {number} passwordMaxLength
 * @property {number} passwordMinLength
 */
export default {
  afterLoginUri,
  afterLogoutUri,
  apiRoutes,
  passwordMaxLength,
  passwordMinLength,
}
