<template>
  <div class="care-logo align-right">
    <div class="care-logo__image">
      <Logo :alt="$t('subheader.careLogo.imageAlt')" />
    </div>
  </div>
</template>

<script>
  import Logo from '~/assets/logos/care-mono.svg?inline'

  export default {
    name: 'CareLogo',
    components: {
      Logo,
    },
  }
</script>

<style lang="scss" scoped>
  @import "~foundation-sites/scss/util/util";
  @import "~assets/scss/frontend/_colours";
  @import "~assets/scss/frontend/_fonts";

  .care-logo {
    display: flex;
    height: 100%;

    .care-logo__image {
      height: rem-calc(50);
      padding-top: 0.15rem;

      svg {
        height: 100%;
      }
    }
  }
</style>
