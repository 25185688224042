/**
 * @typedef {{}} Offsetting~Config~ApiRoutes
 * @property {{}} admin
 * @property {string} admin.index
 */
/**
 *
 * @type {{admin: {index: string}}}
 */
export const apiRoutes = {
  admin: {
    index: '/admin/offset-report',
    get: '/admin/offset-report/<%= offsetReportId %>',
    loans: {
      download: '/admin/offset-report/<%= offsetReportId %>/downloads/funded-loans',
      exclude: '/admin/offset-report/<%= offsetReportId %>/loans/exclude',
      excluded: '/admin/offset-report/<%= offsetReportId %>/loans/excluded',
      include: '/admin/offset-report/<%= offsetReportId %>/loans/include',
      offsettable: '/admin/offset-report/<%= offsetReportId %>/loans',
      set: '/admin/offset-report/<%= offsetReportId %>/set-funded-batch',
      setRate: '/admin/offset-report/<%= offsetReportId %>/set-funded-batch-rate',
    },
    repayments: {
      download: '/admin/offset-report/<%= offsetReportId %>/downloads/repayments-due',
      index: '/admin/offset-report/<%= offsetReportId %>/repayments',
      update: '/admin/offset-report/<%= offsetReportId %>/repayments/<%= repaymentId %>',
      set: '/admin/offset-report/<%= offsetReportId %>/set-repayment-batch',
      setRates: '/admin/offset-report/<%= offsetReportId %>/set-repayment-batch-rates',
      upload: '/admin/offset-report/<%= offsetReportId %>/uploads/repayments-due',
    },
  },
}

/**
 * @typedef {{}} Offsetting~Config~Events
 * @property {string} loanExcluded
 * @property {string} loansExcluded
 */
/**
 * @type {Offsetting~Config~Events}
 */
export const events = {
  loanExcluded: 'offsettingModule::loanExcluded',
  loansExcluded: 'offsettingModule::loansExcluded',
}

/**
 * Max length of a message passed to the API when excluding a loan
 * or changing a repayment amount.
 * @type {number}
 */
export const messageMaxLength = 140

/**
 * The date format used in the offset balance components.
 * @type {string}
 */
export const offsetCompletionDateFormat = `D MMM YYYY HH:MM`

/**
 * User permissions to check when rendering navigation and before performing actions
 * @type {{ho: string}}
 */
export const permissions = {
  ho: 'ho-business-offsetting',
  mfi: 'mfi-offsetting',
}

/**
 * @typedef {{}} Offsetting~Config
 * @property {Offsetting~Config~ApiRoutes} apiRoutes
 * @property {Offsetting~Config~Events} events
 * @property {number} messageMaxLength
 */
export default {
  apiRoutes,
  events,
  messageMaxLength,
  offsetCompletionDateFormat,
}
