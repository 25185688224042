/** @module modules/grants/store/admin/actions */

import get from 'lodash/get'
import has from 'lodash/has'
import merge from 'lodash/merge'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/grants/config'
import BulkProcessingError from '~/modules/grants/entities/admin/bulk-processing-error'
import { getInstance } from '~/plugins/http'
import { formatGrantTypeLabel } from '../../utils/grant-types'
import { defaultConfig } from './state'

/*
 * Create a new grant resource.
 *
 * @param {function} commit
 * @param {object} data
 * @returns {Promise<Response>}
 */
const create = async function ({ commit }, data) {
  const response = await getInstance().post(apiRoutes.admin.create, data)
  const grant = get(response, 'data.data', null)

  commit('SET_GRANT', grant)

  return response
}

/**
 * Return a grant from state. If there is no grant in state, fetch it from
 * the API, set it in the state and then return it.
 * @param {Store} store
 * @param {function} store.commit
 * @param {object} store.state
 * @param {Grants~Grant} store.state.current
 * @param {EntrepreneursStore~State} store.state
 * @param {number} grantId
 * @returns {Promise<void>}
 */
const fetch = async function ({ commit, state }, { grantId }) {
  const uri = template(apiRoutes.admin.get)({ grantId })
  const response = await getInstance().get(uri)
  const grant = get(response, 'data.data', null)

  commit('SET_GRANT', merge({}, grant))

  return state.current
}

/**
 * Fetch grant configuration from the API and store in state.
 * @param {Store} context
 * @param {Function} context.commit
 * @returns {Promise<GrantsConfig>}
 */
const fetchConfig = async ({ commit, state }) => {
  const response = await getInstance().get(apiRoutes.admin.grantsConfig)
  const config = get(response.data, 'data.config', {
    ...defaultConfig
  })

  // Format labels grant type labels.
  config.types = config.types.map(t => {
    t.originalLabel = t.label.slice()
    t.label = formatGrantTypeLabel(t.label)
    return t
  })

  commit('SET_CONFIG', config)

  return state.config
}

/**
 * Post a message for a grant.
 * @param {Store} store
 * @param {number} grantId
 * @param {string} message
 * @return {Promise<void>}
 */
const postMessage = async function (store, { grantId, message }) {
  const uri = template(apiRoutes.admin.messages.create)({ grantId })
  const response = await getInstance().post(uri, { message })

  if (
    get(response, 'status', 0) === 200 &&
    has(response, 'data.data')
  ) {
    store.commit('SET_GRANT_MESSAGES', get(response, 'data.data'))
  }
}

/**
 * Search for grants in API.
 * @param {Store} store - Store context
 * @param {Object} params - Search parameters
 * @returns {Promise<void>}
 */
const search = async function (store, params) {
  return getInstance().get(apiRoutes.admin.index, params)
}

/**
 * Request changing grant's status.
 * @param {function} commit
 * @param {number} grantId
 * @param {string} status
 * @param {undefined|string} [message=undefined]
 * @returns {Promise<AxiosResponse>}
 */
const status = async function ({ commit }, { grantId, status, message = undefined }) {
  const uri = template(apiRoutes.admin.status)({ grantId })
  const data = { status }

  if (message) {
    data.message = message
  }

  const response = await getInstance().post(uri, data, {
    params: {
      include: ['grantImages', 'messages'],
    },
  })

  const grant = get(response, 'data.data', null)

  commit('SET_GRANT', grant)

  return response
}

/**
 * Update grant record in the API.
 * @param {object} context
 * @param {function} context.commit - Vuex store commit method
 * @param {number} id - Grant ID
 * @param {Grant} data - Grant data object
 * @return {Promise<Response>}
 */
const update = async ({ commit }, { grantId, data }) => {
  const uri = template(apiRoutes.admin.update)({ grantId })
  const response = await getInstance().put(uri, data)
  const grant = get(response, 'data.data', null)

  commit('SET_GRANT', grant)

  return response
}

/**
 * Perform a bulk change of grants' status.
 * @param {Store} store
 * @param {number[]} grantIds - Array of grantIDs.
 * @param {string} status - Target status
 * @return {Promise<BulkProcessingError[]|boolean>}
 */
const bulkPublish = async function (store, { grantIds }) {
  const response = await getInstance().patch(apiRoutes.admin.bulkPublish, { grantIds })

  switch (response.status) {
    case 200:
      return get(response.data, 'data', []).map(error => new BulkProcessingError(error))
    case 204:
    default:
      return true
  }
}

/**
 * Perform a bulk change of grants' status.
 * @param {Store} store
 * @param {number[]} grantIds - Array of grant IDs.
 * @param {string} status - Target status
 * @return {Promise<BulkProcessingError[]|boolean>}
 */
const bulkStatus = async function (store, { grantIds, status }) {
  const data = { grantIds, status }

  const response = await getInstance().patch(apiRoutes.admin.bulkStatus, data)

  switch (response.status) {
    case 200:
      return get(response.data, 'data', []).map(error => new BulkProcessingError(error))
    case 204:
    default:
      return true
  }
}
/**
 * @typedef {Object} Grants~AdminStoreActions
 * @property {Function} bulkPublish
 * @property {Function} bulkStatus
 * @property {Function} create
 * @property {Function} fetch
 * @property {Function} fetchConfig
 * @property {Function} search
 * @property {Function} status
 * @property {Function} update
 */

/**
 * @type {Grants~AdminStoreActions}
 */
export default {
  bulkPublish,
  bulkStatus,
  create,
  fetch,
  fetchConfig,
  postMessage,
  search,
  status,
  update,
}
