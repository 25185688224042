import Entity, { Transformer } from '~/contracts/entity'
import MFICurrency from './mfi-currency'
import MfiImage from './mfi-image'

export default class Mfi extends Entity {
  _transformers () {
    return {
      country: String,
      currencies: Transformer.collection(MFICurrency),
      hasProfileTemplates: Boolean,
      id: Number,
      image: Transformer.itemFromPrimitive(MfiImage, 'publicId'),
      lwcUrl: String,
      name: String,
    }
  }

  get isValid () {
    return typeof this.id === 'number'
  }
}
