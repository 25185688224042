import { hyphensToFullStops } from '~/utils/format'

/**
 * Push the uuid to the GA4 dataLayer if the user is logged in.
 * @param {Store} store
 */
export default async function ({ store }) {
  const user = store.getters['auth/user']
  if(user === null || user.uuid === undefined) return

  window.dataLayer.push({
    userUuid: hyphensToFullStops(user.uuid),
  })
}
