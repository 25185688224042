/** @module libs/currencies/utils */

import Money from '@mmieluch/js-money'
import get from 'lodash/get'
import has from 'lodash/has'
import isInteger from 'lodash/isInteger'
import round from 'lodash/round'
import storeFactory from '~/store'
import { money as moneyFormatter } from '~/utils/format'

/**
 * Convert an original float (or string-representation of a float) to an integer.
 * @param {(string|number)} value
 * @param {string} currencyCode - 3-letter currency code.
 * @return {number}
 */
export function amountToInt (value, currencyCode) {
  // Treat null value as 0.
  if (value === null) return 0

  const float = parseFloat(value)

  // Treat NaNs also as 0.
  if (Number.isNaN(float)) return 0

  return Money.fromDecimal(float, currencyCode, round).getAmount()
}

/**
 * Convert an amount between two currencies.
 * @param {*} value - Value to convert; must be in the smallest possible unit, eg 100 USD must be
 *                    passed in as 10000 cents.
 * @param {string} sourceCurrencyCode - 3-letter currency code.
 * @param {string} targetCurrencyCode - 3-letter currency code.
 */
export function convert (value, sourceCurrencyCode, targetCurrencyCode) {
  value = parseFloat(value)

  if (Number.isNaN(value)) {
    throw new Error('[libs/currencies/utils::convert] Invalid "value".')
  }

  // Let's establish our currencies.
  if (!has(Money, sourceCurrencyCode)) {
    throw new Error(`[libs/currencies/utils::convert] Unknown source currency: ${sourceCurrencyCode}.`)
  }
  if (!has(Money, targetCurrencyCode)) {
    throw new Error(`[libs/currencies/utils::convert] Unknown target currency: ${targetCurrencyCode}.`)
  }

  // Let's create a new Money object holding the source amount.
  const sourceAmount = Money.fromInteger(value, sourceCurrencyCode, Math.ceil)
  // Now let's establish a new amount object in a target currency.
  const targetAmount = Money.fromDecimal(1.0, targetCurrencyCode, Math.ceil)
  // We need to access the exchange rate. It's not going to be from source to target,
  // because we need to operate on a targetAmount object. Otherwise, when comparing
  // currencies that don't use decimals (quite a few of them, actually), Money parser
  // would throw errors.
  const store = storeFactory()
  const exchangeRate = store.getters['currencies/single']({
    // Once again, these two lines are *NOT* a mistake!
    from: targetCurrencyCode,
    to: sourceCurrencyCode,
  })

  return targetAmount
    .multiply(sourceAmount.toDecimal(), Math.ceil)
    .divide(exchangeRate, Math.ceil)
}

/**
 * Convert the amount of the given `money` object to USD.
 * @param {Money} money
 * @return {Money}
 */
export function convertToUSD (money) {
  if (!(money instanceof Money)) {
    throw new Error('[money] argument must be an instance of Money.')
  }

  return money.multiply(
    getConversionRatioToUSD(money.getCurrency()),
    Math.ceil,
  )
}

/**
 * Return conversion ratio (exchange rate) of the given `currency` to USD.
 *
 * @param currency
 * @return {*}
 */
export function getConversionRatioToUSD (currency) {
  const currencies = storeFactory().getters['currencies/all']
  const conversionRatio = get(currencies, [currency, 'USD'])

  if (typeof conversionRatio === 'undefined') {
    throw new Error(`Could not find exchange rate to USD for [${currency}].`)
  }

  return conversionRatio
}

/**
 * @param {number} amount - MUST be an integer.
 * @param {string} currencyCode - USD, GBP, VDG, etc.
 * @param {boolean} [full=false]
 * @param {string} [formatterLocale='en-GB'] - Locale string.
 */
export function formatAmount (amount, currencyCode, full = false, formatterLocale = 'en-GB') {
  if (full) {
    return moneyFormatter(amount, currencyCode, formatterLocale)
  }

  if (!isInteger) {
    throw new Error('[libs/currencies/utils::formatAmount] "amount" must be an integer.')
  }

  return Money.fromInteger({ amount, currency: Money[currencyCode] }).toString()
}

/**
 * Convert an integer amount to a displayable float (so, eg. 10000 cents => 100 dollars).
 * @param {number} value - Must be an integer.
 * @param {string} currencyCode - 3-letter currency code.
 */
export function intToAmount (value, currencyCode) {
  if (!isInteger(value)) {
    throw new Error('[libs/currencies/utils::intToAmount] "value" must be an integer.')
  }

  return Money.fromInteger(value, currencyCode).toString()
}

export default {
  amountToInt,
  convert,
  convertToUSD,
  formatAmount,
  getConversionRatioToUSD,
  intToAmount,
}
