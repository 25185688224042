import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'

const CURRENCY = 'GBP'

export default class CreditTopUp extends Entity {
  _transformers () {
    return {
      // Primitives.
      amount: Number,
      id: Number,
      transactionRef: String,
      type: String,
    }
  }

  get amountFormatted () {
    return formatAmount(this.amount, CURRENCY, true)
  }
}
