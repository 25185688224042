export default {
  header: {
    lend: {},
    howItWorks: {},
    aboutUs: {},
    userNav: {
      addCredit: 'Add credit',
      admin: 'Administration area',
      details: 'Edit my details',
      help: 'Help centre',
      loans: 'My loans',
      grants: 'My grants',
      logout: 'Log out',
      myAccount: 'My account',
      profile: 'My profile',
      profileEdit: 'Edit',
      profileView: 'View',
      settings: 'Edit my settings',
      share: 'Recommend a friend',
      transactions: 'My transactions',
      vouchers: 'My gift vouchers',
    },
  },
  offCanvas: {},
}
