/** @module modules/updates/store/actions */

import get from 'lodash/get'
import has from 'lodash/has'
import merge from 'lodash/merge'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/updates/config'
import AdminUpdate from '~/modules/updates/entities/admin/update'
import { getInstance } from '~/plugins/http'

/**
 * Create a new loan update resource.
 *
 * @param {function} commit
 * @param {number} loanId
 * @param {object} data
 * @returns {Promise<Response>}
 */
const create = async function ({ commit }, { loanId, data }) {
  const uri = template(apiRoutes.admin.create)({ loanId })
  const response = await getInstance().post(uri, data)
  const update = get(response, 'data.data', null)

  commit('SET_UPDATE', update)

  return response
}

/**
 * Fetch a loan update record from the API.
 * @param {Store} store
 * @param {function} store.commit
 * @param {object} store.state
 * @param {Update} store.state.current
 * @param {UpdatesStore~State} store.state
 * @param {number} loanId
 * @param {number} updateId
 * @returns {Promise<void>}
 */
const fetch = async function ({ commit, state }, { loanId, updateId }) {
  const uri = template(apiRoutes.admin.get)({ loanId, updateId })
  const response = await getInstance().get(uri)
  const update = get(response, 'data.data', null)

  commit('SET_UPDATE', merge({}, update))

  return state.current
}

/**
 * Fetch loan updates configuration from the API and store in state.
 * @param {Store} context
 * @param {Function} context.commit
 * @returns {Promise<{}|config>}
 */
const fetchConfig = async ({ commit, state }) => {
  const response = await getInstance().get(apiRoutes.admin.config)

  commit('SET_CONFIG', get(response, 'data.data', null))

  return state.config
}

/**
 * Post a message for a loan update.
 * @param {Store} store
 * @param {number} loanId
 * @param {number} updateId
 * @param {string} message
 * @return {Promise<void>}
 */
const postMessage = async function (store, { loanId, updateId, message }) {
  const uri = template(apiRoutes.admin.messages.create)({ loanId, updateId })
  const response = await getInstance().post(uri, { message })

  if (
    get(response, 'status', 0) === 200 &&
    has(response, 'data.data')
  ) {
    store.commit('SET_UPDATE_MESSAGES', get(response, 'data.data'))
  }
}

/**
 * Perform loan update search within particular loan.
 * @param {Store} store
 * @param {number} loanId
 * @param {{}} params - Search parameters.
 * @return {Promise<{updates: AdminUpdate[], pagination: Pagination}>}
 */
const scopedSearch = async function (store, { loanId, params }) {
  const uri = template(apiRoutes.admin.indexScoped)({ loanId })
  const response = await getInstance().get(uri, params)

  const updates = get(response, 'data.data', []).map(updateData => new AdminUpdate(updateData))
  const pagination = get(response, 'data.meta.pagination', {
    total: 0,
    count: 0,
    perPage: get(params, 'limit', 15),
    currentPage: 1,
    totalPages: 1,
  })

  return { updates, pagination }
}

/**
 * Search for loan updates in the API.
 * @param {Store} store - Store context
 * @param {Object} params - Search parameters
 * @returns {Promise<void>}
 */
const search = async function (store, params) {
  return getInstance().get(apiRoutes.admin.index, params)
}

/**
 * Update loan update record in the API.
 * @param {object} context
 * @param {function} context.commit - Vuex store commit method
 * @param {number} id - Update ID
 * @param {AdminUpdate} data - Update data object
 * @return {Promise<Response>}
 */
const update = async ({ commit }, { loanId, updateId, data }) => {
  const http = getInstance()
  const uri = template(apiRoutes.admin.update)({ loanId, updateId })
  const response = await http.put(uri, data, {
    params: {
      include: ['messages'],
    },
  })
  const update = get(response, 'data.data', null)

  commit('SET_UPDATE', update)

  return response
}

/**
 * Request changing a loan update's status.
 * @param {function} commit
 * @param {number} loanId
 * @param {number} updateId
 * @param {string} status
 * @returns {Promise<*>}
 */
const updateStatus = async function ({ commit }, { loanId, updateId, status }) {
  const uri = template(apiRoutes.admin.updateStatus)({ loanId, updateId })
  const response = await getInstance().post(uri, { status }, {
    params: {
      include: ['loanImages', 'messages'],
    },
  })
  const update = get(response, 'data.data', null)

  commit('SET_UPDATE', update)

  return response
}

/**
 * @typedef {Object} Updates~Store~Actions
 * @property {Function} create
 * @property {Function} fetch
 * @property {Function} fetchConfig
 * @property {Function} postMessage
 * @property {Function} search
 * @property {Function} update
 * @property {Function} updateStatus
 */
/**
 * @type {Updates~Store~Actions}
 */
export default {
  create,
  fetch,
  fetchConfig,
  postMessage,
  scopedSearch,
  search,
  update,
  updateStatus,
}
