import Entrepreneur from '~/modules/entrepreneurs/entities/admin/entrepreneur'

export default {
  namespaced: true,

  getters: {
    entrepreneur (state, getters, rootState, rootGetters) {
      return new Entrepreneur(rootGetters['entrepreneurs/entrepreneur'])
    },
  },
  state: {},
}
