export default {
  components: {
    admin: {
      dashboard: {

      },
      mfiSwitcher: {
        placeholder: `Select an MFI`,
      },
    },
  },
}
