import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'

const stateFactory = function () {
  return {
    offCanvasOpen: false,
    subheader: {
      extraClasses: '',
      hasBreadcrumbs: true,
      hasCARELogo: true,
      heading: '',
    },
    intended: null,
  }
}

const mutations = {
  SET_INTENDED (state, intended) {
    state.intended = intended
  },
  SET_SUBHEADER_EXTRA_CLASSES (state, extraClasses) {
    state.subheader.extraClasses = extraClasses
  },
  SET_SUBHEADER_HEADING (state, heading) {
    state.subheader.heading = heading
  },
  SET_SUBHEADER_CARE_LOGO (state, hasCARELogo) {
    if (!isBoolean(hasCARELogo)) {
      throw new Error(`Mutation [SET_SUBHEADER_CARE_LOGO] requires a boolean as an argument.`)
    }

    state.subheader.hasCARELogo = hasCARELogo
  },
  SET_SUBHEADER_BREADCRUMBS (state, hasBreadcrumbs) {
    if (!isBoolean(hasBreadcrumbs)) {
      throw new Error(`Mutation [SET_SUBHEADER_BREADCRUMBS] requires a boolean as an argument.`)
    }

    state.subheader.hasBreadcrumbs = hasBreadcrumbs
  },
  SET_OFF_CANVAS_CLOSED (state) {
    state.offCanvasOpen = false
  },
  SET_OFF_CANVAS_OPEN (state) {
    state.offCanvasOpen = true
  },
}

const actions = {
  setupSubheader ({ commit, state }, data) {
    const mapping = [
      { field: 'extraClasses', mutation: 'SET_SUBHEADER_EXTRA_CLASSES' },
      { field: 'heading', mutation: 'SET_SUBHEADER_HEADING' },
      { field: 'hasCARELogo', mutation: 'SET_SUBHEADER_CARE_LOGO' },
      { field: 'hasBreadcrumbs', mutation: 'SET_SUBHEADER_BREADCRUMBS' },
    ]
    mapping.forEach(({ field, mutation }) => {
      const defaultValue = get(state.subheader, field)
      const value = get(data, field, defaultValue)

      commit(mutation, value)
    })
  },
}

const getters = {
  isOffCanvasOpen: state => state.offCanvasOpen,
  subheaderExtraClasses: state => state.subheader.extraClasses,
  subheaderHeading: state => state.subheader.heading,
  subheaderHasCARELogo: state => state.subheader.hasCARELogo,
  subheaderHasBreadcrumbs: state => state.subheader.hasBreadcrumbs,
}

export default {
  namespaced: true,
  state: stateFactory,
  mutations,
  actions,
  getters,
}
