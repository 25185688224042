/**
 * Redirect to login if user is not authenticated. Set the intended
 * route automatically.
 *
 * @param {{}} app
 * @param {boolean} isServer
 * @param {Store} store
 * @param {function} redirect
 * @param {Route} route
 * @return {void|boolean}
 */
export default function ({ app, isServer, store, redirect, route }) {
  if (!store.getters['auth/isAuthenticated']) {
    const to = {
      name: 'auth:login',
      query: {
        intended: route.fullPath,
      },
    }

    // If it's a server-side rendered request, perform a redirect.
    if (isServer) {
      redirect(401, to)
    }

    // If it's run in a client, wait for the redirection and then resolve
    // to prevent the FoUC.
    redirect(401, to)
  }
}
