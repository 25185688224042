/**
 * @typedef {{}} Loans~Store~Frontend~Getters
 * @property {Loan|null} current
 * @property {{}|null} searchConfig
 * @property {{}} searchDefaults
 * @property {boolean} searchInProgress
 * @property {{}} searchParams
 * @property {{}} searchResults
 */

/**
 * @type {Loans~Store~Frontend~Getters}
 */
export default {
  current: state => state.current,
  searchConfig: state => state.search.config,
  searchDefaults: state => state.search.defaults,
  searchInProgress: state => state.search.inProgress,
  searchParams: state => state.search.params === null ? state.search.defaults : state.search.params,
  searchResults: state => state.search.results,
}
