import Entity from '@/contracts/entity'
import { formatGrantTypeLabel } from '~/modules/grants/utils/grant-types'

/**
 * @typedef {object} GrantRepaymentFrequency
 * @property {boolean} isDefault
 * @property {string} label
 * @property {string} value
 */
/**
 * @typedef {object} GrantType
 * @property {boolean} default
 * @property {string} label
 * @property {string} originalLabel
 * @property {number} value
 * @property {string} name
 * @property {boolean} repayment
 * @property {GrantRepaymentFrequency[]} repaymentFrequencies
 * @property {string} repaymentUnit
 */
export default class GrantType extends Entity {
  _transformers () {
    return {
      default: Boolean,
      id: Number,
      label: formatGrantTypeLabel,
      name: String,
      repayment: Boolean,
      repaymentFrequencies: String,
      repaymentUnit: String,
    }
  }
}
