/** @module modules/notifications/utils */

import { storage, storageKey } from '~/libs/notifications/config'
import isEmpty from 'lodash/isEmpty'

/**
 * Fetch a collection of flash messages from storage.
 * @returns {array}
 */
export function getFlashMessages () {
  return JSON.parse(storage.getItem(storageKey)) || []
}

/**
 * Add a new flash message to an existing collection.
 * @param {object} params - Parameters used to create a vue-notification item.
 */
export function pushFlashMessage (params) {
  if (isEmpty(params)) return

  const collection = getFlashMessages()
  collection.push(params)
  storeFlashMessages(collection)
}

/**
 * Save collection of flash messages in storage.
 * @param {array} [collection=[]]
 */
export function storeFlashMessages (collection = []) {
  storage.setItem(storageKey, JSON.stringify(collection))
}
