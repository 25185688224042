/** @module users/store/actions */

import get from 'lodash/get'
import template from 'lodash/template'
import AuthUser from '~/modules/auth/entities/auth-user'
import MicroloanGridItem from '~/modules/loans/entities/frontend/microloan-grid-item'
import { apiRoutes } from '~/modules/users/config'
import User from '~/modules/users/entities/frontend/user'
import { getInstance } from '~/plugins/http'

/**
 * Closing user account means making it dormant. Once dormant it needs to be
 * restored by an administrator if the user ever wants to use it again.
 *
 * @param {Function} commit
 * @param {Function} dispatch
 * @param {object} getters
 * @param {object }state
 * @param {string} email
 * @param {string} token
 * @returns {Promise<AxiosResponse>}
 */
const closeAccount = async function ({ commit, dispatch, getters, state }, { email, token }) {
  const response = await getInstance().put(apiRoutes.frontend.closeAccount, { email, token })

  if (response.status === 204) {
    commit('SET_USER', null)
    await dispatch('auth/logout', null, { root: true })
  }

  return response
}

/**
 * @param {Store} store
 * @param {function} store.commit
 * @param {object} store.state
 * @param {Users~User} store.state.current
 * @param {number} userId
 * @returns {Promise<void>}
 */
const fetch = async function ({ commit, getters, state }, { userUuid }) {
  if (
    getters.user &&
    getters.user instanceof User &&
    getters.user.uuid === userUuid
  ) {
    return getters.user
  }

  const uri = template(apiRoutes.frontend.get)({ userUuid })
  const config = {
    params: {
      include: ['stats'],
    },
  }
  const response = await getInstance().get(uri, config)
  const userData = get(response, 'data.data', null)

  commit('SET_USER', new User(userData))

  return getters.user
}

/**
 * Fetch currently signed-in user. The API decides which set of data to return.
 * @param {function} commit
 * @param {{}} getters
 * @return {Promise<User>}
 */
const fetchCurrentUser = async function ({ commit, getters }) {
  try {
    const response = await getInstance().get(apiRoutes.frontend.myAccount.currentUser)
    const userData = get(response.data, 'data', {})

    commit('SET_USER', new User(userData))
  } catch (err) {
    this.$sentry.captureException(err)
  }

  return getters.user
}

/**
 * Fetch microloans loaned by a user.
 *
 * @param {function} commit
 * @param {Getters} getters
 * @param {string} userUuid
 * @param {number} [page=1]
 * @param {number} [limit=15]
 * @return {Promise<Loan~Microloan[]|null>}
 */
const fetchUserMicroloans = async function (
  { commit, getters },
  { userUuid, page = 1, limit = 15, sort = 'id', status = null },
) {
  const uri = template(apiRoutes.frontend.microloans)({ userUuid })
  const config = {
    params: {
      include: ['loan.loanImages'],
      limit,
      page,
      sort,
    },
  }

  // Apply status filter if defined.
  if (status) {
    config.params.status = status
  }

  try {
    const response = await getInstance().get(uri, config)
    const normalised = {
      items: [],
      pagination: response.data.meta.pagination,
    }

    normalised.items = response.data.data.map(item => new MicroloanGridItem(item))

    commit('SET_USER_MICROLOANS', normalised)
  } catch (e) {
    commit('SET_USER_MICROLOANS', null)
    throw e
  }

  return getters.microloans
}

/**
 * Perform user registration request to API.
 * @param {Store} store
 * @param {{}} data - Data from registration form.
 * @return {Promise<AuthUser>}
 */
async function register (store, data) {
  const response = await getInstance().post(apiRoutes.frontend.register, data)

  if (get(response, 'status', 500) === 200) {
    const userData = get(response, 'data.data', null)

    // Store received access token in store.
    store.commit('auth/SET_TOKEN', get(userData, 'accessToken', null), { root: true })
    // Store received user object in store.
    store.commit('auth/SET_AUTH_USER', new AuthUser(get(userData, 'user.data', null)), { root: true })

    return store.rootGetters['auth/user']
  }
}

/**
 * Update user data in one of the pre-determined section: avatar, details, profile, settings.
 * If URI to the given section is found the method prints a warning and exits.
 *
 * @param {Store} store
 * @param {string} section - User data section to update.
 * @param {{}} data - User data to send to API.
 * @returns {Promise<User>}
 */
const update = async function (store, { section, data }) {
  let uri = get(apiRoutes.frontend.myAccount.update, section)

  if (!uri) {
    console.warn(`Unknown user data section requested: ${section}.`)
    return store.getters.user
  }

  const response = await getInstance().put(uri, data)
  const userData = get(response.data, 'data', {})

  store.commit('SET_USER', new User(userData))

  // Now that we know that user properties have changed, we need to tell authentication
  // store to also update it's user record. It's safer to do it this way, because we have
  // no guarantee that in any point in the future (or right now) the user record returned
  // from the API will match the record returned by an action in user module.
  // Pro: the user's authentication token will be automatically extended.
  await store.dispatch('auth/authenticate', { refresh: true }, { root: true })

  return store.getters.user
}

export default {
  closeAccount,
  fetch,
  fetchCurrentUser,
  fetchUserMicroloans,
  register,
  update,
}
