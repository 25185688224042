import Vue from 'vue'
import { configure, ValidationProvider } from 'vee-validate'

configure({
  classes: {
    invalid: 'is-invalid-input',
    valid: null,
  },
  useConstraintAttrs: false,
})

Vue.component('ValidationProvider', ValidationProvider)
