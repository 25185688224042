/**
 * @typedef {{}} Vouchers~Config~ApiRoutes
 * @property {{}} admin
 * @property {string} download
 * @property {{}} frontend
 * @property {string} frontend.config
 * @property {string} frontend.create
 * @property {string} frontend.redeem
 * @property {string} frontend.update
 */
/**
 * @type {Vouchers~Config~ApiRoutes}
 */
export const apiRoutes = {
  admin: {
    bulkPurchases: {
      download: '/admin/gift-voucher-batches/<%= batchId %>/download',
      downloadTemplate: '/admin/gift-voucher-batches/template/download',
      index: '/admin/gift-voucher-batches',
      upload: '/admin/gift-voucher-batches',
    },
    resend: '/admin/gift-vouchers/<%= voucherId %>/resend',
    search: '/admin/gift-vouchers',
  },
  download: '<%= host %>/gift-vouchers/<%= key %>/pdf',
  frontend: {
    preview: '/basket/<%= basketUuid %>/voucher-preview/<%= key %>',
    config: '/gift-vouchers/options/purchase',
    create: '/gift-vouchers',
    redeem: '/gift-vouchers/redeem',
    update: '/gift-vouchers/<%= key %>',
    myAccount: {
      vouchers: '/my-account/users/gift-vouchers',
    },
  },
}

/**
 * Maximum length of voucher's message.
 * @const {number}
 */
export const messageMaxLength = 255

/**
 * Minimum length of voucher's redemption code.
 * @const {number}
 */
export const redemptionCodeMaxLength = 15

/**
 * Maximum length of voucher's redemption code.
 * @const {number}
 */
export const redemptionCodeMinLength = 10

/**
 * Maximum gift voucher value.
 * @const {number}
 */
export const voucherMaxAmount = 60000

/**
 * Minimum gift voucher value.
 * @const {number}
 */
export const voucherMinAmount = 1500

/**
 * Permissions to check when rendering navigation and before performing actions
 * @type {{ho: string}}
 */
export const permissions = {
  ho: 'ho-business-vouchers',
}

/**
 * @typedef {{}} Vouchers~Config
 * @property {Vouchers~Config~ApiRoutes} apiRoutes
 * @property {number} messageMaxLength
 * @property {number} voucherMaxAmount
 * @property {number} voucherMinAmount
 */
export default {
  apiRoutes,
  messageMaxLength,
  redemptionCodeMaxLength,
  redemptionCodeMinLength,
  voucherMaxAmount,
  voucherMinAmount,
}
