import Entity from '~/contracts/entity'

export default class UserTeamStats extends Entity {
  _transformers () {
    return {
      donationsValue: { default: 0, type: Number },
      loansValue: { default: 0, type: Number },
    }
  }
}
