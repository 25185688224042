import Admin from './admin'
import Frontend from './frontend'
import MyAccount from './my-account'

export default {
  namespaced: true,

  ...Frontend,

  modules: {
    admin: Admin,
    myAccount: MyAccount,
  },
}
