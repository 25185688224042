import get from 'lodash/get'

/**
 * Format CO2 in grant type labels.
 * @param {string} input
 * @returns {string}
 */
export const formatGrantTypeLabel = function (input) {
  if (input.includes('CO2')) {
    return input.replaceAll('CO2', 'CO<sub>2</sub>')
  }

  return input
}

/**
 * Returns `true` if `typeval` argument represents a grant type that allows
 * repayments; `false` otherwise.
 *
 * The `typeval` argument represents a `value` property of grant type object.
 *
 * @param {GrantType|null} type
 * @returns {boolean}
 */
export const isRepayable = function (type) {
  return get(type, 'repayment', false)
}

/**
 * Finds a type by the given value (`typeval`) in the array of grant types.
 * @param {number} typeval
 * @param {GrantType[]} types
 * @returns {?GrantType}
 */
export const findByValue = function (typeval, types) {
  const t = types.find(t => t.value === typeval)
  return t || null
}

export default {
  findByValue,
  formatGrantTypeLabel,
  isRepayable,
}
