import get from 'lodash/get'

/**
 * Get entrepreneur's API module configuration.
 * @param {State} state
 * @returns {}
 */
function config (state) {
  return get(state, 'config', {})
}

/**
 * Get entrepreneur (or rather entrepreneur MFI's) available currencies.
 * @param {State} state
 * @returns {Array}
 */
function currencies (state) {
  return get(state, 'current.mfi.data.currencies.data', [])
}

/**
 * Get current entrepreneur record.
 * @param state
 * @return {(Entrepreneur|null)}
 */
function entrepreneur (state) {
  return get(state, 'current', null)
}

/**
 * Get the MFI current entrepreneur belongs to.
 * @param {State} state
 * @returns {(Entrepreneur|null)}
 */
function mfi (state) {
  return get(state, 'current.mfi', null)
}

/**
 * @typedef {Object} Entrepreneur~Store~Getters
 * @property {function} config
 * @property {function} currencies
 * @property {function} entrepreneur
 * @property {function} mfi
 */
/**
 * @type {Entrepreneur~Store~Getters}
 */
export default {
  config,
  currencies,
  entrepreneur,
  mfi,
}
