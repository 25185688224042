export const apiRoutes = {
  frontend: {
    paypal: {
      basket: {
        execute: '/checkout/paypal/basket/<%= basketUuid %>/execute',
        setup: '/checkout/paypal/basket/<%= basketUuid %>/setup',
      },
      topup: {
        execute: '/checkout/paypal/topup/execute',
        setup: '/checkout/paypal/topup/setup',
      },
    },
    stripe: {
      basket: {
        confirm: '/checkout/stripe/basket/<%= basketUuid %>/confirm',
        execute: '/checkout/stripe/basket/<%= basketUuid %>/execute',
      },
      topup: {
        confirm: '/checkout/stripe/topup/confirm',
        execute: '/checkout/stripe/topup/execute',
      },
    },
    useCredit: '/checkout/use-credit/<%= basketUuid %>',
  },
}
