import get from 'lodash/get'
import AdminWithdrawal from '~/modules/withdrawals/entities/admin/withdrawal'
import { itemToEntity } from '~/utils/framework'

/**
 * Return pending withdrawal entities from state.
 * @param {State} state
 * @return {AdminWithdrawal[]}
 */
const getPendingWithdrawals = state => {
  const pending = get(state, 'pending', [])

  if (!Array.isArray(pending)) return null

  return pending
    .map(r => itemToEntity(r, AdminWithdrawal))
    .filter(r => r instanceof AdminWithdrawal)
}

/**
 * Return requested withdrawal entities from state.
 * @param {State} state
 * @return {AdminWithdrawal[]}
 */
const getRequestedWithdrawals = state => {
  const requested = get(state, 'requested', [])

  if (!Array.isArray(requested)) return null

  return requested
    .map(r => itemToEntity(r, AdminWithdrawal))
    .filter(r => r instanceof AdminWithdrawal)
}

export default {
  pending: getPendingWithdrawals,
  requested: getRequestedWithdrawals,
}
