import get from 'lodash/get'
import Entity, { Transformer } from '~/contracts/entity'
import Mfi from '~/modules/mfis/entities/mfi'
import { EssentialTeam } from '~/modules/teams/entities/frontend/team'
import Avatar from '~/modules/users/entities/frontend/avatar'
import UserProperties from './user-properties'
import UserSettings from './user-settings'

export default class AuthUser extends Entity {
  _transformers () {
    return {
      // Primitives
      credit: Number,
      displayName: String,
      email: String,
      firstName: String,
      id: Number,
      lastName: String,
      type: String,
      uuid: String,
      urc: String,
      withdrawableCredit: Number,
      numEmailChanges: Number,

      avatar: Transformer.itemFromPrimitive(Avatar, 'publicId'),
      createdAt: Transformer.date(),
      mfis: Transformer.collection(Mfi),
      permissions: Transformer.collection(String),
      properties: Transformer.item(UserProperties),
      roles: Transformer.collection(String),
      settings: Transformer.item(UserSettings),
      teams: Transformer.collection(EssentialTeam),
    }
  }

  _apiMapping () {
    return {
      firstname: 'firstName',
      lastname: 'lastName',
      userProperties: 'properties',
      userSettings: 'settings',
      lastUserEmailChange: 'lastEmailChange',
      userEmailChanges: 'emailChanges',
    }
  }

  get defaultTeam () {
    const teams = get(this, 'teams', [])

    const found = teams.find(team => {
      return get(team, 'membership.isDefault', false) === true
    })

    return found || null
  }

  get fullName () {
    const firstName = get(this, 'firstName', '')
    const lastName = get(this, 'lastName', '')

    return `${firstName} ${lastName}`
  }

  get hasDefaultTeam () {
    return this.defaultTeam instanceof EssentialTeam
  }

  get possessivePronoun () {
    if (!this.firstName) return

    return this.firstName.endsWith('s')
      ? `${this.firstName}'`
      : `${this.firstName}'s`
  }

  get profileRoute () {
    if (!this.uuid) return {}

    return {
      name: 'users:frontend:profile',
      params: {
        userUuid: this.uuid,
      },
    }
  }
}
