import get from 'lodash/get'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import { createCmsInfoFlatMap, parseCmsInfoStructure } from '~/modules/cms/utils'
import { error } from '~/utils/errors'

export default {
  namespaced: true,

  actions: {
    async fetchArticle (store, { uri }) {
      try {
        if (isNil(store.getters.uriMap)) {
          await store.dispatch('fetchStructure')
        }

        const id = get(store.getters, ['uriMap', uri])

        if (typeof id === 'undefined') {
          return null
        }

        store.commit('SET_ARTICLE', await this.app.prismic.client.getByID(id))
      } catch (err) {
        error(err)
      }
    },
    async fetchNews (store, { page = 1, perPage = 9, tags = [] }) {
      const prismic = this.app.prismic
      const prismicApi = await prismic.api(prismic.endpoint)

      page = page || 1

      const predicates = [
        prismic.Predicates.at('document.tags', ['News']),
        prismic.Predicates.any('document.tags', tags),
      ]

      const response = await prismicApi.query(
        predicates,
        {
          orderings: '[my.news_item.date desc,first_publication_date]',
          pageSize: perPage,
          page,
        },
      )

      const results = get(response, 'results', [])
      const meta = {
        page: get(response, 'page', 1),
        pages: get(response, 'total_pages', 1),
        perPage: get(response, 'results_per_page', perPage),
        total: get(response, 'total_results_size', 1),
      }

      store.commit('SET_NEWS_ITEMS', results)
      store.commit('SET_NEWS_ITEMS_META', meta)

      return store.getters['newsItems']
    },
    async fetchNewsItem (store, postID) {
      store.commit('SET_NEWS_ITEM', await this.app.prismic.client.getByID(postID))

      return store.getters['newsItem']
    },
    async fetchStructure (store) {
      const prismic = this.app.prismic
      const prismicApi = await prismic.api(prismic.endpoint)
      const tags = ['Info']

      // Let's optimistically assume there's only one page - at least initially.
      let currentPage = 1
      let pages = 1
      let results = []

      const fetch = function (page = 1) {
        return prismicApi.query(
          prismic.Predicates.at('document.tags', tags),
          { pageSize: 100, page },
        )
      }

      // Stop after 5 calls if somehow while loop gets out of control.
      const MAX_CALLS = 5
      let currentCall = 1

      try {
        const response = await fetch()

        results.push(...get(response, 'results', []))
        pages = response.total_pages

        while (currentPage < pages && currentCall < MAX_CALLS) {
          currentPage++
          const response = await fetch(currentPage)
          currentCall++

          results.push(...get(response, 'results', []))
          pages = response.total_pages
        }

        store.commit('SET_STRUCTURE', parseCmsInfoStructure(results))

        return store.getters.structure
      } catch (err) {
        this.$sentry.captureException(err)
      }
    },
  },

  getters: {
    article: state => state.article,
    newsItem: state => state.newsItem,
    newsItems: state => state.newsItems,
    newsItemsMeta: state => state.newsItemsMeta,
    structure: state => state.structure,
    uriMap: state => createCmsInfoFlatMap(state.structure),
  },

  mutations: {
    SET_ARTICLE: (state, article) => { set(state, 'article', article) },
    SET_NEWS_ITEM: (state, newsItem) => { set(state, 'newsItem', newsItem) },
    SET_NEWS_ITEMS: (state, collection) => { set(state, 'newsItems', collection) },
    SET_NEWS_ITEMS_META: (state, meta) => { set(state, 'newsItemsMeta', meta) },
    SET_STRUCTURE: (state, structure) => { set(state, 'structure', structure) },
  },

  state: {
    article: null,
    structure: null,
    newsItems: null,
    newsItemsMeta: {
      page: 1,
      pages: 1,
      perPage: 9,
      total: 1,
    },
    newsItem: null,
  },
}
