import get from 'lodash/get'

export default {
  SET_LOAN: (state, loan) => { state.current = loan },
  SET_SEARCH_CONFIG: (state, config) => {
    Object.keys(config).forEach(key => {
      state.search.config[key] = config[key]
    })
  },
  SET_SEARCH_PARAMS: (state, params) => { state.search.params = params },
  SET_SEARCH_RESULTS: (state, results) => { state.search.results.items = results },
  SET_SEARCH_RESULTS_META: (state, meta) => {
    state.search.results.currentPage = get(meta, 'pagination.currentPage', 1)
    state.search.results.pages = get(meta, 'pagination.totalPages', 1)
    state.search.results.total = get(meta, 'pagination.total', 0)
    state.search.results.perPage = get(meta, 'pagination.perPage', state.search.config.perPage)
  },
  SET_SEARCH_IN_PROGRESS: (state, value) => { state.search.inProgress = Boolean(value) },
}
