import Entity from '~/contracts/entity'

/**
 * @property {number} loanId
 * @property {string} message
 */
export default class BulkProcessingError extends Entity {
  _transformers () {
    return {
      loanId: Number,
      message: String,
    }
  }
}
