import Entity from '~/contracts/entity'

/**
 * Base class for pagination objects returned by the API.
 */
export default class Pagination extends Entity {
  /**
   * {@inheritdoc}
   */
  _transformers () {
    return {
      count: { default: 0, type: Number },
      currentPage: { default: 1, type: Number },
      perPage: { default: 15, type: Number },
      total: { default: 0, type: Number },
      totalPages: { default: 0, type: Number },
    }
  }
}
