/** @module modules/loans/admin/store */

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

/**
 * @typedef {Object} Loans~Store
 * @property {Boolean} namespaced
 * @property {Loans~Store~Actions} actions
 * @property {Loans~Store~Getters} getters
 * @property {Loans~Store~Mutations} mutations
 * @property {Loans~Store~State} state
 */
/**
 * @type {Loans~Store}
 */
const store = {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
}

export default store
