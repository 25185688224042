import Vue from 'vue'
import Vuex from 'vuex'
import persist from './persist'

Vue.use(Vuex)

const storeModules = {
  // Globals.
  config: require('./config'),
  global: require('./global'),
  // Libs.
  copycat: require('~/libs/copycat/store'),
  currencies: require('~/libs/currencies/store'),
  dev: require('~/libs/dev/store'),
  payments: require('~/libs/payments/store'),
  prefetching: require('~/libs/prefetching/store'),
  UKAid: require('~/libs/uk-aid/store'),
  // Modules.
  admin: require('~/modules/admin/store'),
  angels: require('~/modules/angels/store'),
  appeals: require('~/modules/appeals/store'),
  auth: require('~/modules/auth/store'),
  basket: require('~/modules/basket/store'),
  cms: require('~/modules/cms/store'),
  entrepreneurs: require('~/modules/entrepreneurs/store'),
  grantRepayments: require('~/modules/grant-repayments/store'),
  grantReporting: require('~/modules/grant-reporting/store'),
  grants: require('~/modules/grants/store'),
  loans: require('~/modules/loans/store'),
  mfis: require('~/modules/mfis/store'),
  offsetting: require('~/modules/offsetting/store'),
  repayments: require('~/modules/repayments/store'),
  reporting: require('~/modules/reporting/store'),
  teams: require('~/modules/teams/store'),
  updates: require('~/modules/updates/store'),
  users: require('~/modules/users/store'),
  vouchers: require('~/modules/vouchers/store'),
  withdrawals: require('~/modules/withdrawals/store'),
}

const modules = {}

Object.entries(storeModules).forEach(([key, module]) => {
  modules[key] = module.default
})

const store = new Vuex.Store({
  strict: false,
  modules,
  plugins: [
    persist(),
  ],
})

export default () => store
