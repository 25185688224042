import Entity, { Transformer } from '~/contracts/entity'

export default class AdminWithdrawalBatch extends Entity {
  _transformers () {
    return {
      createdAt: Transformer.date(),
      id: Number,
    }
  }
}
