import Repayment from './repayment'
import { formatAmount } from '~/libs/currencies/utils'

export default class BufferRepayment extends Repayment {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'isBufferRepayment', {
      configurable: true,
      enumerable: true,
      value: true,
      writable: false,
    })
  }

  get formattedExpected () {
    return formatAmount(this.expected || 0, 'GBP', true)
  }

  get formattedReceived () {
    return formatAmount(this.received || 0, 'GBP', true)
  }
}
