/** @module plugins/http/interceptors */

import authToken from './request/auth-token'
import outgoingToSnakeCase from './request/outgoing-to-snake-case'
import refreshToken from './response/refresh-token'

export default {
  request: {
    authToken,
    outgoingToSnakeCase,
  },
  response: {
    refreshToken,
  },
}
