/** @module modules/auth/utils/auth */

import get from 'lodash/get'
import JWTDecode from 'jwt-decode'
import { apiRoutes } from '~/modules/auth/config'
import { getInstance as httpClient } from '~/plugins/http'
import { hyphensToFullStops } from '~/utils/format'

/**
 * Perform a login or token refresh request.
 * @param {(Object|null)} [credentials=null] - Credentials data object - not required for token refresh
 * @param {string} [credentials.email] - User email address.
 * @param {string} [credentials.password] - User password.
 * @param {boolean} refresh - If `true`, will send a request to a token refresh URI.
 * @returns {Promise<{token, user}>}
 * @throws {Error<{message}>}
 */
export async function login (credentials = null, refresh = false) {
  const URI = refresh ? apiRoutes.refreshToken : apiRoutes.login
  const http = httpClient()

  // Perform a request to an established URI. Pass in data, although if
  // it is supposed to be a token-refresh request, it should be null.
  const response = await http.post(URI, credentials)
  // Extract {string} token and {object} user variables.
  const token = get(response, 'data.data.accessToken', null)
  const userData = get(response, 'data.data.user.data', null)

  return { token, userData }
}

/**
 * Send a logout request to the API.
 * @return {Response}
 */
export async function logout () {
  return httpClient().post(apiRoutes.logout)
}

/**
 * Pushes the logged-in user's uuid to the Ga4 dataLayer.
 *
 * @param  {string} uuid The logged-in user's uuid.
 */
export function trackLogin (uuid) {
  window.dataLayer.push({
    event: 'login',
    userUuid: hyphensToFullStops(uuid),
  })
}

/**
 * Retrieve and return access token.
 * @returns {(string|null)}
 */
export function getToken () {
  return get(window, '$nuxt.$store.state.auth.token', null)
}

/**
 * Returns a boolean indicating if current access token is expired.
 * @param {string} token - JWT personal access token.
 * @returns {boolean}
 */
export function isTokenExpired (token) {
  try {
    const decoded = JWTDecode(token)
    const now = new Date().getTime() / 1000 | 0

    return decoded.exp <= now
  } catch (err) {
    return true
  }
}

/**
 * Get path to password reset page straight from the auth module's routing file.
 * @return {string}
 */
export function getResetPasswordURL () {
  if (typeof window === 'undefined') {
    throw new Error(`This function can only be run in a browser environment.`)
  }

  const authRoutesFactory = require('~/modules/auth/routing')
  const resolve = () => {}

  const routes = authRoutesFactory(resolve)

  if (!Array.isArray(routes)) {
    throw new Error(`'Unexpected value returned by auth module's route factory.`)
  }

  const resetPasswordRoute = routes.find(route => route.name === 'auth:reset-password')

  if (!resetPasswordRoute) {
    throw new Error(`Couldn't find password reset route in auth module's routing file.`)
  }

  const path = get(resetPasswordRoute, 'path')

  if (typeof path !== 'string' || !path.length) {
    throw new Error(`'Invalid path to password reset page in auth module's routing file`)
  }

  return `${window.location.origin}${path}`
}

/**
 * Return an appropriate route for a user to be redirected to if they failed their permissions check.
 * @param {Store} store - Application Vuex store.
 * @returns {object}
 */
export function getRouteForPermissionDeniedRedirect (store) {
  if (store.getters['auth/isAuthenticated']) {
    if (store.getters['auth/isAdmin']) {
      return { name: 'admin:index' }
    }

    return { name: 'users:frontend:my-account' }
  }

  return { name: 'homepage:index' }
}
