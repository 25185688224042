import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: () => ({
    breadcrumbs: null,
    dashboard: {
      figures: null,
      stats: null,
    },
    pageHeading: null,
    pageSubheading: null,
    sidebar: {
      hidden: false,
      minimised: false,
    },
    mobileSidebar: false,
  }),
}
