import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'
import Entrepreneur from '~/modules/entrepreneurs/entities/frontend/entrepreneur'
import Team from '~/modules/teams/entities/frontend/team'
import GrantUpdate from '~/modules/updates/entities/update'
import Lender from '~/modules/users/entities/frontend/lender'
import BufferRepayment from './buffer-repayment'
import GrantImage from './grant-image'
import Repayment from './repayment'
import GrantType from './grant-type'
import LocationMapImage from './location-map-image'

export default class Grant extends Entity {
  _transformers () {
    return {
      activity: String,
      amount: Number,
      approvedAt: Transformer.date(),
      bufferRepayment: Transformer.item(BufferRepayment),
      createdAt: Transformer.date(),
      currency: String,
      description: String,
      disbursed: Transformer.date(),
      entrepreneur: Transformer.item(Entrepreneur),
      entrepreneurDescription: String,
      fundedAt: Transformer.date(),
      fundingAmount: Number,
      fundingAmountRemaining: Number,
      fundingCurrency: String,
      groupMembers: Number,
      id: Number,
      images: Transformer.collection(GrantImage),
      grantors: Transformer.collection(Lender),
      location: String,
      locationMap: Transformer.itemFromPrimitive(LocationMapImage, 'publicId'),
      purpose: String,
      repayments: Transformer.collection(Repayment),
      repaymentAmount: Number,
      reserved: Boolean,
      status: String,
      teams: Transformer.collection(Team),
      term: Number,
      type: Transformer.item(GrantType),
      updates: Transformer.collection(GrantUpdate),
    }
  }

  get formattedFundingAmount () {
    return formatAmount(this.fundingAmount, this.fundingCurrency, true)
  }

  get formattedFundingAmountRemaning () {
    return formatAmount(this.fundingAmountRemaining, this.fundingCurrency, true)
  }

  get fundingAmountFunded () {
    return this.fundingAmount - this.fundingAmountRemaining
  }

  get formattedFundingAmountFunded () {
    return formatAmount(this.fundingAmountFunded, this.fundingCurrency, true)
  }

  get mainImage () {
    const found = this.images.find(img => img.isMain === true)

    return found || this.images[0]
  }

  get secondaryImages () {
    return this.images.filter(img => !img.isMain)
  }

  get route () {
    return {
      name: 'grants:frontend:show',
      params: {
        grantId: this.id,
      },
    }
  }

  _apiMapping () {
    return {
      grantBufferRepayment: 'bufferRepayment',
      grantImages: 'images',
      grantRepayments: 'repayments',
      grantUpdates: 'updates',
      grantType: 'type',
    }
  }
}
