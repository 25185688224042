/**
 * @typedef {{}} Teams~Config~ApiRoutes
 * @property {{}} frontend
 * @property {string} frontend.searchConfig
 */
/**
 * API routes.
 * @type {Teams~Config~ApiRoutes}
 */
export const apiRoutes = {
  frontend: {
    editConfig: 'teams/options',
    get: 'teams/<%= slug %>',
    profile: {
      getLatestActivity: 'teams/<%= slug %>/latest-activity',
      getMembers: 'teams/<%= slug %>/members',
      getLoans: 'teams/<%= slug %>/loans',
    },
    index: 'teams',
    join: 'teams/<%= uuid %>/join',
    myAccount: {
      acceptMember: 'my-account/teams/<%= uuid %>/members/accept',
      create: 'my-account/teams',
      get: 'my-account/teams/<%= uuid %>',
      leave: 'my-account/teams/<%= uuid %>/members/leave',
      getMembers: 'my-account/teams/<%= uuid %>/members',
      makeAdmin: 'my-account/teams/<%= uuid %>/members/make-admin',
      rejectMember: 'my-account/teams/<%= uuid %>/members/reject',
      removeMember: 'my-account/teams/<%= uuid %>/members/remove',
      revokeAdmin: 'my-account/teams/<%= uuid %>/members/revoke-admin',
      update: 'my-account/teams/<%= uuid %>',
      updateAvatar: 'my-account/teams/<%= uuid %>/avatar',
    },
    searchConfig: 'teams/search-config',
  },
}

/**
 * The prefix to images' public IDs.
 * @const {string}
 */
export const imagePublicIdPrefix = 'lwc/groups'

/**
 * PublicID to use when team's avatar is null.
 * @const {string}
 */
export const defaultTeamImage = `${imagePublicIdPrefix}/avatar_teams`

/**
 * The name of Cloudinary upload preset for team images.
 * @type {string}
 */
export const imageUploadPreset = 'team-avatar'

/**
 * @typedef {{}} Teams~Config
 * @property {Teams~Config~ApiRoutes} apiRoutes
 * @property {string} defaultTeamImage
 * @property {string} imagePublicIdPrefix
 * @property {string} imageUploadPreset
 */

/**
 * @type {Teams~Config}
 */
export default {
  apiRoutes,
  defaultTeamImage,
  imagePublicIdPrefix,
  imageUploadPreset,
}
