import castArray from 'lodash/castArray'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import template from 'lodash/template'
import Pagination from '~/entities/pagination'
import { apiRoutes } from '~/modules/updates/config'
import Update from '~/modules/updates/entities/update'
import { getInstance } from '~/plugins/http'
import { error } from '~/utils/errors'

export const DEFAULT_PAGINATION = new Pagination({
  count: 0,
  currentPage: 1,
  perPage: 15,
  total: 0,
  totalPages: 0,
})

export default {
  actions: {
    /**
     * Fetch an update by the given ID.
     * @param {Store} store
     * @param {number} updateId
     * @return {Promise<Update|null>}
     */
    async fetch (store, updateId) {
      const uri = template(apiRoutes.frontend.fetch)({ updateId })
      const response = await getInstance().get(uri)

      const update = new Update(get(response, 'data.data'))

      store.commit('SET_UPDATE', update)

      return store.getters.update
    },
    /**
     * Fetch a list of recent updates.
     * @param {Store} store
     * @return {Promise<Update[]>}
     */
    async fetchRecent (store) {
      const params = {
        limit: 3,
        page: 1,
        sort: '-approved_at',
      }
      const response = await getInstance().get(apiRoutes.frontend.index, { params })

      const updates = castArray(get(response, 'data.data', []))
        .map(item => new Update(item))
        .filter(update => update instanceof Update)

      store.commit('SET_RECENT_UPDATES', updates)

      return store.getters.recent
    },
    /**
     * Search for updates in the API.
     * @param {Store} store
     * @param {{}} params
     * @param {number} params.page
     * @return {Promise<{{ items: Update[], pagination: Pagination }}>}
     */
    async search (store, params) {
      try {
        const response = await getInstance().get(apiRoutes.frontend.index, { params })
        const updates = get(response.data, 'data', []).map(data => new Update(data))
        const pagination = new Pagination(get(response, 'data.meta.pagination', DEFAULT_PAGINATION))

        if (
          (isArray(updates) && updates.every(u => u instanceof Update)) &&
          pagination instanceof Pagination
        ) {
          store.commit('SET_SEARCH_RESULTS_ITEMS', updates)
          store.commit('SET_SEARCH_RESULTS_PAGINATION', pagination)
        }

        return {
          items: store.getters['search/items'],
          pagination: store.getters['search/pagination'],
        }
      } catch (err) {
        error(err)

        return {
          items: [],
          pagination: DEFAULT_PAGINATION,
        }
      }
    },
  },
  getters: {
    recent: state => state.recent,
    'search/items': state => isArray(state.search.items) ? state.search.items : [],
    'search/pagination': state => state.search.pagination instanceof Pagination
      ? state.search.pagination
      : DEFAULT_PAGINATION,
    update: state => get(state, 'current', null),
  },
  mutations: {
    SET_RECENT_UPDATES (state, items = []) {
      state.recent = castArray(items)
        .map(item => {
          try {
            return item instanceof Update ? item : new Update(item)
          } catch (err) {
            error(err)
          }
        })
        .filter(update => update instanceof Update)
    },
    SET_SEARCH_RESULTS_ITEMS (state, items = []) {
      state.search.items = isArray(items) && items.every(item => item instanceof Update)
        ? items
        : []
    },
    SET_SEARCH_RESULTS_PAGINATION (state, pagination) {
      state.search.pagination = pagination instanceof Pagination
        ? pagination
        : DEFAULT_PAGINATION
    },
    SET_UPDATE (state, update) {
      if (isNil(update)) state.current = null
      if (update instanceof Update) state.current = update

      try {
        state.update = new Update(update)
      } catch (err) {
        this.$sentry.captureException(err)
      }
    },
  },
  state: {
    current: null,
    recent: [],
    search: {
      items: [],
      pagination: DEFAULT_PAGINATION,
    },
  },
}
