<template>
  <div class="nuxt-error">
    <component :is="errorRenderer" :error="error" />
  </div>
</template>

<script>
  import ControlsSubHeader from '~/mixins/controls-sub-header'

  export default {
    name: 'NuxtError',
    layout: 'frontend',
    mixins: [
      ControlsSubHeader,
    ],
    components: {
      Admin: () => import('~/components/errors/admin/error'),
      Admin404: () => import('~/components/errors/admin/error-404'),
      Frontend: () => import('~/components/errors/frontend/error'),
    },
    props: {
      error: {
        default: () => {},
        type: [Error, Object],
      },
    },
    head () {
      return {
        title: this.message,
        meta: [
          {
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
          },
          {
            hid: 'prerender-status-code',
            name: 'prerender-status-code',
            content: this.statusCode
          }
        ],
      }
    },
    // Only on debug mode
    data () {
      return {
        mounted: false,
      }
    },
    computed: {
      errorRenderer () {
        const adminRe = /^\/admin/
        const code = this.$get(this.error, 'statusCode', 500)
        const path = this.$get(this.$route, 'path')

        if (adminRe.test(path)) {
          if (code === 404) {
            return this.$options.components.Admin404
          }

          return this.$options.components.Admin
        }

        return this.$options.components.Frontend
      },
      message () {
        return this.error.message || this.messages.client_error
      },
      statusCode () {
        return (this.error && this.error.statusCode) || 500
      },
      subheader () {
        return {
          heading: this.statusCode === 404 ? 'Page not found' : 'Error',
          extraClasses: '',
        }
      },
    },
    watch: {
      error (newErr) {
        if (newErr) {
          console.error(newErr)
        }
      },
    },
    mounted () {
      this.mounted = true
    },
  }
</script>
