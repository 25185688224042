/** @module modules/teams/store/admin */

import state from './state'

/**
 * @typedef {Object} TeamsAdminStore
 * @property {Boolean} namespaced
 * @property {TeamsAdminStoreState} state
 */
/**
 * @type {TeamsAdminStore}
 */
const store = {
  namespaced: true,

  state,
}

export default store
