import get from 'lodash/get'
import GrantReport from '@grant-reporting/entities/grant-report'

/**
 * @typedef {{}} GrantReportingStoreGetters
 * @property {function} fundedBatch - Get current grant report's related funded batch.
 * @property {function} report - Get current report from the store.
 */
/**
 * @type {GrantReportingStoreGetters}
 */
export default {
  fundedBatch: state => {
    return state.current instanceof GrantReport
      ? get(state, 'current.fundedBatch', null)
      : get(state, 'current.grantFundedBatch.data', null)
  },
  report: state => get(state, 'current', null),
}
