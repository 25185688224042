/** @module utils/convert */

import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import toString from 'lodash/toString'

/**
 * Recursively convert object keys using a specified {fn} function.
 * Allowed conversions: "camelCase" or "snakeCase".
 * @param {Object} o - Object whose keys need to be converted.
 * @param {String} fn - Name of converting function: camelCase || snakeCase.
 */
export const convertObjectKeys = function (o, fn) {
  const re = /"([^"]+)":/g
  let func

  switch (fn) {
    case 'camelCase':
      func = camelCase
      break
    case 'snakeCase':
      func = snakeCase
      break
  }

  const replaced = JSON.stringify(o).replace(re, function ($0, $1) {
    return `"${func($1)}":`
  })

  return JSON.parse(replaced)
}

/**
 * Convert relative units (REMs) to pixels.
 * @kind function
 * @param {number|string} value - Number in REMs to convert.
 * @param {number} [base=16] - Line height to base calculations on.
 * @return {number} - Value in pixels.
 */
export const remToPx = function (value, base = 16) {
  value = (typeof value === 'number') ? value : parseFloat(value)

  return value / base
}

/**
 * Convert a string to number. Return a valid number or, if parsing resulted in NaN,
 * return NaN (by default) or any given `defaultValue`.
 * @param {string} value
 * @param {*} [defaultValue=NaN] - Value to return if parsed number is NaN.
 * @return {number|*}
 */
export const stringToNumber = function (value, defaultValue = NaN) {
  const asString = toString(value).replace(/,/g, '.')
  const asNumber = Number(asString)

  return Number.isNaN(asNumber) ? defaultValue : asNumber
}

export default {
  convertObjectKeys,
  remToPx,
  stringToNumber,
}
