import Entity, { Transformer } from '~/contracts/entity'
import MFI from '~/modules/mfis/entities/mfi'
import OffsetReportRate from './offset-report-rate'
import OffsetReportTotals from './offset-report-totals'
import FundedBatch from './funded-batch'
import RepaymentBatch from './repayment-batch'
import Payment from './payment'
import format from 'date-fns/format'
import isValidDate from 'date-fns/is_valid'
import Lwc2mfiPaymentUsd from './lwc2mfi-payment-usd'

export default class OffsetReport extends Entity {
  _transformers () {
    return {
      completed: Boolean,
      completedAt: Transformer.date(),
      direction: String,
      exchangeRate: Transformer.item(OffsetReportRate),
      fundedBatch: Transformer.item(FundedBatch),
      fundedBatchId: Number,
      id: Number,
      localCurrency: String,
      lwc2mfiPaymentUsd: Transformer.item(Lwc2mfiPaymentUsd),
      mfi: Transformer.item(MFI),
      offsetMonth: Transformer.date(),
      payments: Transformer.collection(Payment),
      repaymentBatch: Transformer.item(RepaymentBatch),
      repaymentBatchId: Number,
      totals: Transformer.item(OffsetReportTotals),
    }
  }

  get month () {
    return isValidDate(this.offsetMonth)
      ? format(this.offsetMonth, 'MMMM')
      : null
  }
}
