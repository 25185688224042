/** @module Teams~FrontendStore */
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import stateFactory from './state'

/**
 * @typedef {{}} Teams~FrontendStore
 * @property {Teams~FrontendStore~State} state
 * @property {Teams~FrontendStore~Actions} actions
 * @property {Teams~FrontendStore~Getters} getters
 * @property {Teams~FrontendStore~Mutations} mutations
 */
export default {
  state: stateFactory(),

  actions,
  getters,
  mutations,
}
