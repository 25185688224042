<template>
  <div class="sub-header" :class="extraClasses">
    <!-- SubHeader grid container -->
    <div class="grid-container">
      <!-- Main container horizontal grid -->
      <div class="grid-x grid-padding-x">
        <!-- Left column -->
        <div class="cell auto sub-header__left-col">
          <!-- Content vertical centering container -->
          <div>
            <Content id="heading" class="sub-header__heading" />
          </div><!--// Content vertical centering container -->
        </div><!--// Left column -->

        <!-- Right column -->
        <div v-if="mountRightCol" class="cell small-3 sub-header__right-col">
          <!-- Logo -->
          <component :is="$theme.getComponent('layout.subheader.logo')" v-if="mountCARELogo && $theme && $theme.getComponent('layout.subheader.logo')" />
          <!--// Logo -->
        </div><!--// Right column -->
      </div><!--// Main container horizontal grid -->
    </div><!--// SubHeader grid container -->
  </div>
</template>

<script>
  import CareLogo from './care-logo.vue'
  import Content from './sub-header-content'

  export default {
    name: 'SubHeader',
    components: {
      CareLogo,
      Content,
    },
    computed: {
      extraClasses () {
        return this.$store.getters['global/subheaderExtraClasses']
      },
      hasCARELogo () {
        return this.$store.getters['global/subheaderHasCARELogo']
      },
      mountCARELogo () {
        return !this.$server && this.hasCARELogo && this.$device.isMedium
      },
      mountDate () {
        return false
      },
      mountRightCol () {
        return !this.$server && (this.mountCARELogo || this.mountDate)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "~foundation-sites/scss/util/util";
  @import "~assets/scss/frontend/_colours";
  @import "~assets/scss/frontend/_fonts";
  @import "~assets/scss/frontend/_settings";

  .sub-header {
    background-color: $subheader-bg-colour;
    color: $subheader-text-colour;

    @each $breakpoint, $paddings in $subheader-padding {
      @include breakpoint($breakpoint) {
        @each $side, $val in $paddings {
          padding-#{$side}: $val;
        }
      }
    }
  }

  .sub-header__left-col {
    @include flex;
    @include flex-align(null, middle);
  }

  .sub-header__heading {
    color: $colour-white;
    font: {
      family: $font-officina;
    }
    margin: {
      top: 0;
      bottom: 0;
    }
  }
</style>
