/**
 * @typedef {{}} GrantReporting~Config~ApiRoutes
 * @property {{}} admin
 * @property {string} admin.index
 */
/**
 *
 * @type {{admin: {index: string}}}
 */
export const apiRoutes = {
  admin: {
    index: '/admin/grant-reports',
    get: '/admin/grant-reports/<%= reportId %>',
    grants: {
      download: '/admin/grant-reports/<%= reportId %>/downloads/funded-grants',
      exclude: '/admin/grant-reports/<%= reportId %>/grants/exclude',
      excluded: '/admin/grant-reports/<%= reportId %>/grants/excluded',
      include: '/admin/grant-reports/<%= reportId %>/grants/include',
      offsettable: '/admin/grant-reports/<%= reportId %>/grants',
      set: '/admin/grant-reports/<%= reportId %>/set-grant-funded-batch',
      setRate: '/admin/grant-reports/<%= reportId %>/set-grant-funded-batch-rate',
    },
  },
}

/**
 * User permissions to check when rendering navigation and before performing actions
 * @type {{ho: string}}
 */
export const permissions = {
  ho: 'ho-business-grant-reports',
  mfi: 'mfi-grant-reports',
}

/**
 * Max length of a message passed to the API when excluding a grant
 * or changing a repayment amount.
 * @type {number}
 */
export const messageMaxLength = 140

/**
 * The date format used in the grant balance components.
 * @type {string}
 */
export const reportCompletionDateFormat = `D MMM YYYY HH:MM`

/**
 * @typedef {{}} GrantReporting~Config
 * @property {GrantReporting~Config~ApiRoutes} apiRoutes
 */
export default {
  apiRoutes,
}
