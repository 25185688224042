/** @module modules/vouchers/store/frontend/actions */

import get from 'lodash/get'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/vouchers/config'
import Voucher from '~/modules/vouchers/entities/voucher'
import { getInstance } from '~/plugins/http'

/**
 * Fetch configuration required to display a gift voucher creating/updating form.
 * @param {function} commit - Vuex mutation dispatcher.
 * @param {{}} getters - Vuex getters.
 * @return {Promise<*>}
 */
const fetchConfig = async function ({ commit, getters }) {
  const response = await getInstance().get(apiRoutes.frontend.config)

  commit('SET_CONFIG', get(response, 'data.data'))

  return getters.config
}

/**
 * Send a request to the API to create a new gift voucher.
 * @param {Store} store
 * @param {function} store.commit
 * @param {{}} store.getters
 * @param {} store.getters.voucher
 * @param {{}} data
 * @return {Promise<Voucher>}
 */
const create = async function ({ commit, getters }, data) {
  const response = await getInstance().request({
    url: apiRoutes.frontend.create,
    method: 'POST',
    params: {
      include: ['giftVoucherProduct'],
    },
    data,
  })
  return new Voucher(get(response, 'data.data'))
}

/**
 * Perform a gift voucher redemption request.
 * @param {Store} store
 * @param {function} store.commit
 * @param {{}} store.getters
 * @param {} store.getters.voucher
 * @param {{}} data
 * @return {Promise<Voucher>}
 */
const redeem = async function ({ commit, getters }, data) {
  const response = await getInstance().request({
    url: apiRoutes.frontend.redeem,
    method: 'POST',
    data,
  })

  return new Voucher(get(response, 'data.data'))
}

/**
 * Send a request to the API to update a gift voucher record.
 * @param {Store} store
 * @param {function} commit
 * @param {{}} getters
 * @param {{}} data
 * @param {string} key
 * @param {{}} key
 * @return {Promise<Voucher>}
 */
const update = async function ({ commit, getters }, { key, data }) {
  const response = await getInstance().request({
    url: template(apiRoutes.frontend.update)({ key }),
    method: 'PUT',
    params: {
      include: ['giftVoucherProduct'],
    },
    data,
  })

  return new Voucher(get(response, 'data.data'))
}

export default {
  create,
  fetchConfig,
  redeem,
  update,
}
