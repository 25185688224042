import get from 'lodash/get'
import merge from 'lodash/merge'
import { Transformer } from '~/contracts/entity'
import AdminLoan from '~/modules/loans/entities/admin/loan'
import Update from '../update'

export default class AdminUpdate extends Update {
  _transformers () {
    return merge({}, super._transformers(), {
      approvedAt: Transformer.date(),
      loan: Transformer.item(AdminLoan),
    })
  }

  get editRoute () {
    const params = {
      mfiId: Number(get(this.loan, 'entrepreneur.mfi.id')),
      entrepreneurId: Number(get(this.loan, 'entrepreneur.id')),
      loanId: Number(get(this.loan, 'id')),
      updateId: Number(get(this, 'id')),
    }

    if (Object.values(params).some(param => Number.isNaN(param))) {
      this.$sentry.captureMessage('Unable to construct loan update edit route', {
        extra: {
          update: this,
          params,
        }
      })
      return ''
    }

    return {
      name: 'updates:admin:edit',
      params,
    }
  }
}
