import actions from './actions'

/**
 * @typedef {Store} GrantRepaymentsStore
 * @property {GrantRepaymentsStore~Actions} actions
 */
/**
 * @type {GrantRepaymentsStore}
 */
export default {
  namespaced: true,

  actions,
}
