import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

/**
 * @typedef {Store} OffsettingStore
 * @property {OffsettingStore~Actions} actions
 * @property {OffsettingStore~Getters} getters
 * @property {OffsettingStore~Mutations} mutations
 * @property {OffsettingStore~State} state
 */
/**
 * @type {OffsettingStore}
 */
export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
}
