/**
 * @typedef {{}} Appeals~Config~ApiRoutes
 * @property {{}} frontend
 * @property {string} frontend.get
 */

/**
 * @type {Appeals~Config~ApiRoutes}
 */
export const apiRoutes = {
  frontend: {
    show: '/appeals/<%= appealSlug %>',
  },
}

/**
 * @typedef {{}} Appeals~Config
 * @property {Appeals~Config~ApiRoutes} apiRoutes
 */
export default {
  apiRoutes,
}
