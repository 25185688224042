import diffInSeconds from 'date-fns/difference_in_seconds'
import isValidDate from 'date-fns/is_valid'
import parse from 'date-fns/parse'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import store from '~/store'
import * as Sentry from '@sentry/browser'

try {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
      new Sentry.Replay()
    ],
  })
} catch (err) {
  console.error(`Error utils couldn't install Sentry.`)
}

function _getUserContext () {
  const getters = get(store, 'getters')

  if (getters) return null

  return get(store.getters, `'auth/user'`, null)
}

/**
 * Report errors to Sentry, if set up properly, and output the error to console.
 * @param {Error} err
 * @return {void}
 */
export function error (err) {
  const status = get(err, 'response.status')

  const ignorable = [401, 403, 422, 409]
  if (ignorable.includes(status)) return

  if (typeof console !== 'undefined' && typeof console.error === 'function') {
    console.error(err)
  }

  Sentry.configureScope(scope => {
    scope.setTag('customType', 'error')
    scope.setUser(_getUserContext())
  })
  Sentry.captureException(err)
}

/**
 * Issue a warning in console, if available, and send report a non-error
 * incident to Sentry.
 * @param {string} message
 * @param {*} extra
 * @return {void}
 */
export function warn (message, ...extra) {
  if (typeof console !== 'undefined' && typeof console.warn === 'function') {
    console.warn(message, ...extra)
  }

  Sentry.configureScope(scope => {
    scope.setUser(_getUserContext())
    scope.setTag('customType', 'warning')

    if (isObject(extra)) {
      Object.entries(scope).forEach(([key, value]) => {
        scope.setExtra(key, value)
      })
    }
  })
  Sentry.captureMessage(message)
}
