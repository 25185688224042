import defaults from 'lodash/defaults'
import get from 'lodash/get'
import template from 'lodash/template'
import MicrograntGridItem from '~/modules/grants/entities/frontend/microgrant-grid-item'
import MicroloanGridItem from '~/modules/loans/entities/frontend/microloan-grid-item'
import { apiRoutes } from '~/modules/users/config'
import UserStats from '~/modules/users/entities/frontend/user-stats'
import Team from '~/modules/users/entities/frontend/user-team'
import Withdrawal from '~/modules/users/entities/frontend/withdrawal'
import { getInstance } from '~/plugins/http'
import {
  MICROGRANTS_SEARCH_PAGINATION_DEFAULTS,
  MICROLOANS_SEARCH_PAGINATION_DEFAULTS,
  TRANSACTIONS_DEFAULT_PAGINATION,
} from './support'

const actions = {
  /**
   * Attribute an item to a lending team.
   * @param {Store} store
   * @param {number} itemId
   * @param {string} teamUuid
   * @param {string} type - Item type: donation|microloan
   * @returns {Promise<void>}
   */
  async attribute (store, { itemId, teamUuid, type }) {
    const uri = template(apiRoutes.frontend.myAccount.attribute)({ teamUuid })

    return getInstance().put(uri, {
      itemId,
      type,
    })
  },
  async fetchBonusStats (store, params) {
    const uri = apiRoutes.frontend.myAccount.bonusStats
    const response = await getInstance().get(uri)

    store.commit('SET_BONUS_STATS', get(response, 'data.data'))

    return store.getters.transactions
  },
  async fetchLoans ({ commit, dispatch, getters }) {
    const { items, total } = await dispatch('loans/search', {
      limit: 6,
      page: 1,
      sort: 'random',
      status: 'fundable',
    }, { root: true })

    commit('SET_FUNDABLE_LOANS', items)
    commit('SET_FUNDABLE_LOANS_COUNT', total)

    return getters.fundableLoans
  },
  async fetchMicrogrants (
    store,
    { page = 1, limit = 15, sort = 'id', status = null },
  ) {
    const config = {
      params: {
        include: ['grant.grantImages'],
        limit,
        page,
        sort,
      },
    }

    // Apply status filter if defined
    if (status) {
      config.params.status = status
    }

    try {
      const response = await getInstance().get(apiRoutes.frontend.myAccount.microgrants, config)

      const normalised = {
        items: [],
        pagination: get(response, 'data.meta.pagination', MICROGRANTS_SEARCH_PAGINATION_DEFAULTS),
      }

      normalised.items = get(response, 'data.data', []).map(item => new MicrograntGridItem(item))

      store.commit('SET_USER_MICROGRANTS_SEARCH_RESULTS', normalised)
    } catch (err) {
      store.commit('SET_USER_MICROGRANTS_SEARCH_RESULTS', null)
      throw err
    }

    return store.getters.micrograntsSearch
  },
  async fetchMicroloans (
    store,
    { page = 1, limit = 15, sort = 'id', status = null },
  ) {
    const config = {
      params: {
        include: ['loan.loanImages'],
        limit,
        page,
        sort,
      },
    }

    // Apply status filter if defined.
    if (status) {
      config.params.status = status
    }

    try {
      const response = await getInstance().get(apiRoutes.frontend.myAccount.microloans, config)

      const normalised = {
        items: [],
        pagination: get(response, 'data.meta.pagination', MICROLOANS_SEARCH_PAGINATION_DEFAULTS),
      }

      normalised.items = get(response, 'data.data', []).map(item => new MicroloanGridItem(item))

      store.commit('SET_USER_MICROLOANS_SEARCH_RESULTS', normalised)
    } catch (err) {
      store.commit('SET_USER_MICROLOANS_SEARCH_RESULTS', null)
      throw err
    }

    return store.getters.microloansSearch
  },
  async fetchOverview ({ commit, getters }) {
    const uri = apiRoutes.frontend.myAccount.overview
    const response = await getInstance().get(uri)

    const repayments = get(response.data, 'data.repayments.data', [])
    const statsData = get(response.data, 'data.stats.data', {})

    const stats = new UserStats(statsData)

    commit('SET_REPAYMENTS', repayments)
    commit('SET_STATS', stats)

    return {
      repayments: getters.repayments,
      stats: getters.stats,
    }
  },
  async fetchTeams ({ commit, getters }) {
    try {
      const uri = apiRoutes.frontend.myAccount.teams
      const response = await getInstance().get(uri, {
        limit: 1000,
      })

      if (!response) {
        throw new Error('Response is undefined');
      }

      /** @type {{}[]} */
      const teamsData = get(response.data, 'data', [])
      const teams = teamsData.map(team => new Team(team))

      commit('SET_TEAMS', teams)

      return getters.teams
    } catch (error) {
      commit('SET_TEAMS', [])
    }
  },
  async fetchTransactions (store, params) {
    const uri = apiRoutes.frontend.myAccount.transactions
    params = defaults(params, {
      limit: TRANSACTIONS_DEFAULT_PAGINATION.perPage,
      page: 1,
    })
    const response = await getInstance().get(uri, { params })

    store.commit('SET_TRANSACTIONS', get(response, 'data.data'))
    store.commit('SET_TRANSACTIONS_PAGINATION', get(
      response,
      'data.meta.pagination',
      TRANSACTIONS_DEFAULT_PAGINATION,
    ))

    return store.getters.transactions
  },
  async fetchWithdrawalConfig () {
    const response = await getInstance().get(apiRoutes.frontend.myAccount.withdrawalConfig)

    return get(response, 'data.data.config')
  },
  /**
   * Unttribute an item from a lending team.
   * @param {Store} store
   * @param {number} itemId
   * @param {string} teamUuid
   * @param {string} type - Item type: donation|microloan
   * @returns {Promise<void>}
   */
  async unattribute (store, { itemId, type }) {
    return getInstance().put(apiRoutes.frontend.myAccount.unattribute, {
      itemId,
      type,
    })
  },
  /**
   * Create a new credit withdrawal request.
   * @param {Store} store
   * @param {{}} data
   * @returns {Promise<void>}
   */
  async withdraw (store, data) {
    const response = await getInstance().post(apiRoutes.frontend.myAccount.withdraw, data)

    store.commit('SET_WITHDRAWAL', new Withdrawal(get(response, 'data.data')))
    await store.dispatch('auth/authenticate', { refresh: true }, { root: true })

    return get(response, 'data.data')
  },
}

export default actions
