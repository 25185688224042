/** @module modules/entrepreneurs/store/mutations */

import types from './mutation-types'

/**
 * @typedef {object} EntrepreneursStore~Mutations
 * @property {function} SET_CONFIG
 */
export default {
  [types.SET_CONFIG] (state, config) {
    state.config = config
  },
  /**
   * @param {EntrepreneursStore~State} state
   * @param {(Entrepreneur|null)} entrepreneur
   */
  [types.SET_ENTREPRENEUR] (state, entrepreneur) {
    state.current = entrepreneur
  },
}
