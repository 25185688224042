import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import AdminUser from '~/modules/users/entities/admin/user'
import TransactionEntities from '~/modules/users/entities/frontend/transactions'
import { DEFAULT_CREDIT_CONFIG } from '~/modules/users/store/admin/constants'
import { itemToEntity } from '~/utils/framework'
import parse from 'date-fns/parse'

export default {
  SET_CREDIT_CONFIG (state, config = null) {
    config = isNil(config) ? Object.assign({}, DEFAULT_CREDIT_CONFIG) : config

    state.credit.config = config
  },
  SET_MANAGEABLE_ROLES (state, roles = null) {
    state.manageableRoles = roles
  },
  SET_USER (state, userData) {
    if (isNil(userData)) {
      state.user = null
    } else if (userData instanceof AdminUser) {
      state.user = userData
    } else {
      state.user = new AdminUser(userData)
    }

    return state.user
  },
  SET_USERS: (state, data) => {
    state.users = data
  },
  SET_USERS_PAGINATION: (state, data) => {
    state.usersPagination = data
  },
  /**
   * Transform user transactions to their respective entities and set them in state.
   * @param {object} state
   * @param {object[]} transactions
   */
  SET_TRANSACTIONS: (state, transactions = null) => {
    if (!Array.isArray(transactions)) {
      state.transactions = null
      return
    }

    state.transactions = transactions
      .map(item => {
        const type = camelCase(get(item, 'type'))

        switch (type) {
          case 'donation':
            return itemToEntity(item, TransactionEntities.Donation)
          case 'dormant':
            return itemToEntity(item, TransactionEntities.Dormant)
          case 'microloan':
            return itemToEntity(item, TransactionEntities.Microloan)
          case 'repayment':
            return itemToEntity(item, TransactionEntities.Repayment)
          case 'topUp':
            return itemToEntity(item, TransactionEntities.TopUp)
          case 'giftVoucherPurchased':
          case 'giftVoucherRedeemed':
            return itemToEntity(item, TransactionEntities.Voucher)
          case 'withdrawal':
            return itemToEntity(item, TransactionEntities.Withdrawal)
          case 'reclaimedBonus':
            return itemToEntity(item, TransactionEntities.ReclaimedBonus)
          default:
            return null
        }
      })
      .filter(transaction => transaction instanceof TransactionEntities.Transaction)
  },
}
