import get from 'lodash/get'
import Transaction from './transaction'

export default class TopUpTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    Object.defineProperties(this, {
      topUpType: {
        enumerable: true,
        value: get(data, 'top_up_type', null),
      },
      type: {
        enumerable: true,
        value: 'topUp',
      },
    })
  }
}
