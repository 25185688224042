import Entity from '~/contracts/entity'

const numericDefault = {
  default: 0,
  type: Number,
}

export default class UserStats extends Entity {
  _transformers () {
    return {
      bonusGrantsAwarded: { ...numericDefault },
      bonusGrantsExpired: { ...numericDefault },
      bonusGrantsUsed: { ...numericDefault },
      bonusLoansAwarded: { ...numericDefault },
      bonusLoansExpired: { ...numericDefault },
      bonusLoansUsed: { ...numericDefault },
      co2Actual: { ...numericDefault },
      co2Expected: { ...numericDefault },
      creditAdded: { ...numericDefault },
      dependents: { ...numericDefault },
      donationsValue: { ...numericDefault },
      employees: { ...numericDefault },
      entrepreneurs: { ...numericDefault },
      grants: { ...numericDefault },
      grantsValue: { ...numericDefault },
      loans: { ...numericDefault },
      loansDefaulted: { ...numericDefault },
      loansDefaultedLoss: { ...numericDefault },
      loansFinished: { ...numericDefault },
      loansExchangeRateLoss:  { ...numericDefault },
      loansValue: { ...numericDefault },
      repaid: { ...numericDefault },
      successfulReferrals: { ...numericDefault },
      teams: { ...numericDefault },
      vouchersPurchased: { ...numericDefault },
      vouchersPurchasedUnredeemed: { ...numericDefault },
      vouchersPurchasedValue: { ...numericDefault },
      vouchersReceived: { ...numericDefault },
      withdrawn: { ...numericDefault },
    }
  }

  _apiMapping () {
    return {
      carbonActual: 'co2Actual',
      carbonExpected: 'co2Expected',
    }
  }
}
