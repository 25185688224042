import Entity from '~/contracts/entity'

export default class Lwc2mfiPaymentUsd extends Entity {
  _transformers () {
    return {
      amount: Number,
      currency: String,
    }
  }
}
