import Entity, { Transformer } from '~/contracts/entity'
import LoanImage from './loan-image'

export default class MicroloanGridItem extends Entity {
  _transformers () {
    return {
      activity: String,
      amount: Number,
      amountRepaid: Number,
      createdAt: Transformer.date(),
      entrepreneurDisplayName: String,
      entrepreneurGender: String,
      entrepreneurId: Number,
      entrepreneurType: String,
      error: Boolean,
      errorMessage: String,
      id: Number,
      image: Transformer.itemFromPrimitive(LoanImage, 'publicId'),
      loanId: Number,
      country: String,
      teamId: Number,
      teamName: String,
      teamUuid: String,
    }
  }

  get entrepreneur () {
    return this.entrepreneurDisplayName
  }

  get isAttributed () {
    return this.teamId !== null
  }

  get location () {
    return this.country
  }

  get loanRoute () {
    return {
      name: 'loans:frontend:show',
      params: {
        loanId: this.loanId,
      },
    }
  }
}
