import PrismicDOM from 'prismic-dom'
import PrismicJS from 'prismic-javascript'
import Vue from 'vue'
import { cmsApiUrl } from '~/modules/cms/config'
import get from 'lodash/get'
import findKey from 'lodash/findKey'

const Elements = PrismicDOM.RichText.Elements

let store

const linkResolver = function (link) {
  const id = get(link, 'id')

  if (id) {
    if (!store) {
      store = get(Vue.prototype, '$nuxt.$store')
    }

    if (store) {
      const map = store.getters['cms/uriMap']
      const found = findKey(map, mappedId => {
        return mappedId === id
      })

      if (found) {
        return found
      }
    }

    return null
  } else {
    const url = get(link, 'url')

    if (typeof url !== 'string') return null

    if (url.startsWith('https://lendwithcare.org')) {
      return url.replace('https://lendwithcare.org', '')
    }

    return url
  }
}

let htmlSerializer = function (type, element, content, children) {
  let linkUrl

  switch (type) {
    case Elements.heading1:
      return `<h1>${children.join('')}</h1>`
    case Elements.heading2:
      return `<h2>${children.join('')}</h2>`
    case Elements.heading3:
      return `<h3>${children.join('')}</h3>`
    case Elements.heading4:
      return `<h4>${children.join('')}</h4>`
    case Elements.heading5:
      return `<h5>${children.join('')}</h5>`
    case Elements.heading6:
      return `<h6>${children.join('')}</h6>`
    case Elements.paragraph:
      return `<p>${children.join('')}</p>`
    case Elements.preformatted:
      return `<pre>${children.join('')}</pre>`
    case Elements.strong:
      return `<strong>${children.join('')}</strong>`
    case Elements.em:
      return `<em>${children.join('')}</em>`
    case Elements.listItem:
      return `<li>${children.join('')}</li>`
    case Elements.oListItem:
      return `<li>${children.join('')}</li>`
    case Elements.list:
      return `<ul>${children.join('')}</ul>`
    case Elements.oList:
      return `<ol>${children.join('')}</ol>`
    case Elements.image:
      linkUrl = element.linkTo ? PrismicDOM.Link.url(element.linkTo, module.exports.linkResolver) : null
      let linkTarget = element.linkTo && element.linkTo.target ? `target="${element.linkTo.target}" rel="noopener"` : ''
      let wrapperClassList = [element.label || '', 'block-img']
      let img = `<img src="${element.url}" alt="${element.alt || ''}" copyright="${element.copyright || ''}">`
      return (`
        <p class="${wrapperClassList.join(' ')}">
          ${linkUrl ? `<a ${linkTarget} href="${linkUrl}">${img}</a>` : img}
        </p>
      `)
    case Elements.embed:
      return (`
        <div data-oembed="${element.oembed.embed_url}"
          data-oembed-type="${element.oembed.type}"
          data-oembed-provider="${element.oembed.provider_name}">
          ${element.oembed.html}
        </div>
      `)
    case Elements.hyperlink:
      let target = element.data.target ? `target="${element.data.target}" rel="noopener"` : ''
      linkUrl = PrismicDOM.Link.url(element.data, linkResolver)
      return `<a ${target} href="${linkUrl}">${children.join('')}</a>`
    case Elements.label:
      let label = element.data.label ? ` class="${element.data.label}"` : ''
      return `<span ${label}>${children.join('')}</span>`
    case Elements.span:
      return content ? content.replace(/\n/g, '<br />') : ''
    default:
      return null
  }
}

const PrismicVue = {
  install: function (Vue, options = {}) {
    Vue.prototype.$prismic = PrismicJS
    Vue.prototype.$prismic.endpoint = options.endpoint
    Vue.prototype.$prismic.linkResolver = options.linkResolver
    Vue.prototype.$prismic.htmlSerializer = options.htmlSerializer
    Vue.prototype.$prismic.client = PrismicJS.client(options.endpoint, options.apiOptions)
    Vue.prototype.$prismic.richTextAsPlain = function (field) {
      if (!field) {
        return ''
      }
      return PrismicDOM.RichText.asText(field)
    }
  },
}

Vue.use(PrismicVue, {
  endpoint: cmsApiUrl,
  htmlSerializer,
  linkResolver,
})

export default context => {
  context.app.prismic = (new Vue()).$prismic
}
