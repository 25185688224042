import get from 'lodash/get'
import { apiRoutes } from '~/modules/withdrawals/config'
import AdminWithdrawalBatch from '~/modules/withdrawals/entities/admin/batch'
import { getInstance } from '~/plugins/http'
import { error } from '~/utils/errors'
import { SET_PENDING, SET_REQUESTED } from './mutations'
import template from 'lodash/template'
import AdminWithdrawal from '~/modules/withdrawals/entities/admin/withdrawal'

export default {
  async createBatch (store, withdrawals = []) {
    const response = await getInstance().post(apiRoutes.admin.batches.create, {
      withdrawals,
    })

    try {
      return new AdminWithdrawalBatch(get(response, 'data.data'))
    } catch (err) {
      error(err)
    }

    return null
  },
  async fetchPending (store) {
    const response = await getInstance().get(apiRoutes.admin.pending)
    const data = get(response, 'data.data')

    store.commit(SET_PENDING, data)
  },
  async fetchRequested (store) {
    const response = await getInstance().get(apiRoutes.admin.requested)
    const data = get(response, 'data.data')

    store.commit(SET_REQUESTED, data)
  },
  async setSuccessful (store, { transactionRef, withdrawalId }) {
    const uri = template(apiRoutes.admin.setSuccessful)({ withdrawalId })
    const response = await getInstance().post(uri, { transactionRef })

    return new AdminWithdrawal(get(response, 'data.data'))
  },
  async setUnsuccessful (store, { unsuccessfulReason, withdrawalId }) {
    const uri = template(apiRoutes.admin.setUnsuccessful)({ withdrawalId })
    const response = await getInstance().post(uri, { unsuccessfulReason })

    return new AdminWithdrawal(get(response, 'data.data'))
  },
}
