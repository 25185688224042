/**
 * @typedef {{}} CurrenciesLib~Config~APIRoutes
 * @property {{}} admin
 * @property {string} admin.index
 */
/**
 * API routes for the currencies.
 * @type {CurrenciesLib~Config~APIRoutes}
 */
export const apiRoutes = {
  admin: {
    index: '/admin/currency/exchange-rate',
  },
}

/**
 * @typedef {CurrenciesLib~Config}
 * @property {CurrenciesLib~Config~APIRoutes}
 */
export default {
  apiRoutes,
}
