import Entity, { Transformer } from '~/contracts/entity'
import Mfi from '~/modules/mfis/entities/mfi'

const groupNameTransformer = value => {
  return typeof value === 'string' ? value.replace(/\.$/, '') : null
}

export default class Entrepreneur extends Entity {
  _transformers () {
    return {
      createdAt: Transformer.date(),
      description: String,
      displayName: String,
      firstName: String,
      gender: String,
      groupName: groupNameTransformer,
      hasActiveLoan: Boolean,
      id: Number,
      lastName: String,
      mfi: Transformer.item(Mfi),
      title: String,
      type: String,
    }
  }

  _apiMapping () {
    return {
      firstname: 'firstName',
      lastname: 'lastName',
    }
  }
}
