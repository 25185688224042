import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle'
import Vue from 'vue'

const observe = Vue.prototype.constructor.util.defineReactive

export const defaults = {
  isSmall: false,
  isMedium: false,
  isLarge: false,
}

const Device = {
  /**
   * @typedef $device
   * @property {bool} isSmall  Whether the device's screen size is within the "small" range.
   * @property {bool} isMedium Whether the device's screen size is within the "medium" range.
   * @property {bool} isLarge  Whether the device's screen size is within the "large" range.
   */
  /**
   * Get the data on the current device size.
   * @return $device
   */
  collect () {
    return {
      isExactlySmall: this.isDeviceExactlySmall(),
      isExactlyMedium: this.isDeviceExactlyMedium(),
      isExactlyLarge: this.isDeviceExactlyLarge(),
      isSmall: this.isDeviceSmall(),
      isMedium: this.isDeviceMedium(),
      isLarge: this.isDeviceLarge(),
    }
  },
  isDeviceExactlySmall () {
    return this.isDeviceSmall() && !this.isDeviceMedium()
  },
  isDeviceExactlyMedium () {
    return this.isDeviceMedium() && !this.isDeviceLarge()
  },
  isDeviceExactlyLarge () {
    return this.isDeviceLarge() && !this.isDeviceMedium()
  },
  /**
   * Check if a device is of a small (mobile) size.
   *
   * Min device size: 0.
   * Max device size: 640.
   * @return {boolean}
   */
  isDeviceSmall: () => process.browser,
  /**
   * Check if device is of a medium (tablet) size.
   *
   * Min device size: 641.
   * Max device size: 1008.
   * @return {boolean}
   */
  isDeviceMedium: () => {
    return process.browser
      ? window.innerWidth > 640
      : false
  },
  /**
   * Check if device is of a large size.
   *
   * Min device size: 1009.
   * Max device size: Infinity.
   * @return {boolean}
   */
  isDeviceLarge: () => {
    return process.browser
      ? window.innerWidth > 1008
      : false
  },
  /**
   * Update the data on the `Vue.prototype.$device` object.
   *
   * The function first calls the collector and compares the returned object with the current
   * one. If there's an actual difference, it sets the new data set in the service. By using
   * `Vue.prototype.$set` we're making sure the reactivity aspect of the collection is preserved.
   */
  update () {
    const collected = this.collect()

    if (!isEqual(Vue.prototype.$device, collected)) {
      Vue.prototype.$set(Vue.prototype, '$device', collected)
    }
  },
  /**
   * Plugin installer. Called automatically when a new instance of Vue is created.
   */
  install () {
    // Don't override if an object is already set. This will help with unit testing.
    if (typeof Vue.prototype.$device !== 'undefined') {
      return
    }

    // Set an observable object with default values, so it can be accessed by calling
    // `this.$device` from a component.
    observe(Vue.prototype, '$device', defaults)

    // Start updating device information if we're working in actual browser.
    if (process.browser === true) {
      // Set current viewport dimensions.
      this.update(Vue)

      // Start listening to window size changes and calls the event handler.
      // Throttle the calls to the handler, so we save some CPU. The handler will be called
      // at most once per 200ms, so max 5 times per second.
      window.addEventListener('resize', throttle(this.update.bind(this), 200))
    }
  },
}

export default Device
