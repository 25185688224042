import Entity, { Transformer } from '~/contracts/entity'
import GrantReportTotalsGrants from './grant-report-totals-grants'

export default class GrantReportTotals extends Entity {
  _transformers () {
    return {
      grants: Transformer.item(GrantReportTotalsGrants),
    }
  }
}
