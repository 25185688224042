export default {
  pages: {
    index: {
      heading: 'Loan updates',
      breadcrumb: 'Loan updates',
    },
    create: {
      heading: 'Create a loan update',
      breadcrumb: 'Create',
      loanChosen: 'Please see the details of chosen loan in a side&ndash;box.',
      resetLoan: 'Change loan',
      loan404: `Sorry, we couldn't find the loan with ID {id} related to your MFI(s).`,
      loanLoadingFailed: `Sorry, there was a problem while fetching loan with ID: {id}.`,
    },
    chooseLoan: {
      heading: 'Choose a loan',
      breadcrumb: 'Choose a loan',
    },
    edit: {
      heading: 'Edit a loan update',
      breadcrumb: 'Edit',
    },
  },
}
