import has from 'lodash/has'
import { localStorageId } from '~/modules/basket/config'
import validUUID from '~/libs/forms/validators/valid-uuid'
import { warn } from '~/utils/errors'

export function clearUuid () {
  if (!has(window, 'localStorage')) return null

  window.localStorage.removeItem(localStorageId)
}

/**
 * Get basket UUID from local storage (if available).
 * @return {string|null}
 */
export function getUuid () {
  if (!has(window, 'localStorage')) return null

  const uuid = window.localStorage.getItem(localStorageId)

  // Clear item in local storage if stored value is incorrect.
  if (!validUUID(uuid)) {
    clearUuid()
  }

  return typeof uuid === 'string' ? uuid : null
}

/**
 * Store basket UUID in local storage (if available).
 * @param {string} uuid
 * @return {void}
 */
export function storeUuid (uuid) {
  if (!has(window, 'localStorage')) return

  if (validUUID(uuid)) {
    return window.localStorage.setItem(localStorageId, uuid)
  }

  warn('Invalid UUID', uuid)
}
