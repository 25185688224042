import merge from 'lodash/merge'
import FrontendUser from '@users/entities/frontend/user'
import { Transformer } from '~/contracts/entity'
import isValidDate from 'date-fns/is_valid'
import AdminUserProperties from '@users/entities/admin/user-properties'
import UserEmailChanges from '@users/entities/admin/user-email-changes'

export default class AdminUser extends FrontendUser {
  _transformers () {
    return merge({}, super._transformers(), {
      dormant: Transformer.date(),
      emailChanges: Transformer.collection(UserEmailChanges),
      inactive: Transformer.date(),
      lastEmailChange: Transformer.item(UserEmailChanges),
      lastLogin: Transformer.date(),
      properties: Transformer.item(AdminUserProperties),
      raisersEdgeId: String,
      source: String,
    })
  }

  get editRoute () {
    return {
      name: 'users:admin:edit',
      params: {
        userId: this.id,
      },
    }
  }

  get isActive () {
    return !this.isInactive
  }

  get isDormant () {
    try {
      return isValidDate(this.dormant)
    } catch (err) {
      return false
    }
  }

  get isInactive () {
    try {
      return isValidDate(this.inactive)
    } catch (err) {
      return false
    }
  }
}
