import Entity from '~/contracts/entity'

export default class FundedBatchRate extends Entity {
  _transformers () {
    return {
      rate: Number,
      type: String,
    }
  }
}
