import merge from 'lodash/merge'
import BaseImage from '~/entities/image'
import { imagePublicIdPrefix } from '~/modules/loans/config'
import { getCloudinaryURL } from '~/utils/cloudinary'

export default class LoanImage extends BaseImage {
  _transformers () {
    const transformers = super._transformers()

    return merge({}, transformers, {
      isMain: Boolean,
    })
  }

  _apiMapping () {
    return {
      image: 'publicId',
    }
  }

  /**
   * Cloudinary prefix for the loans module.
   * @returns {string}
   */
  get prefix () {
    return imagePublicIdPrefix
  }

  get tiny () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 300,
    })
  }

  get small () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 600,
    })
  }

  get medium () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 800,
    })
  }

  get large () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 1200,
    })
  }

  get openGraph () {
    return getCloudinaryURL(this.fullPublicId, {
      ...this.cloudinaryParams,
      width: 1200,
      height: 630,
      crop: 'fill',
      gravity: 'face',
    })
  }
}
