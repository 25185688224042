import template from 'lodash/template'
import { apiRoutes } from '~/modules/grant-repayments/config'
import { getInstance } from '~/plugins/http'
import { error } from '~/utils/errors'

/**
 * Repay a grant repayment.
 *
 * @param {Store} store
 * @param {number} grantRepaymentId
 * @param {number} value
 * @return {Promise<AxiosResponse>}
 */
const updateGrantRepaymentValue = async function (store, { grantRepaymentId, value, confirmation = false }) {
  const uri = template(apiRoutes.admin.repay)({ grantRepaymentId })

  return getInstance().post(uri, { amountReceived: value, confirmation: confirmation })
}

/**
 * Upload a spreadsheet of grant repayment data.
 *
 * @param {Store} store
 * @param {{}} payload
 * @param {number} mfiId
 * @param {File} payload.file
 * @return {Promise<boolean>}
 */
const uploadGrantRepayments = async function (store, { mfiId, file }) {
  let data
  let uri

  try {
    // Prepare POST data.
    data = new FormData()
    data.append('repayments_file', file)
    // Determine upload URI.
    uri = template(apiRoutes.admin.upload)({ mfiId })
  } catch (err) {
    error(err)
  }

  const response = await getInstance().post(uri, data)

  return response.status === 204
}

/**
 * @typedef {Object} GrantRepaymentsStore~Actions
 * @property {Function} updateGrantRepaymentValue
 * @property {Function} uploadGrantRepayments
 */
/**
 * @type {GrantRepaymentsStore~Actions}
 */
export default {
  updateGrantRepaymentValue,
  uploadGrantRepayments,
}
