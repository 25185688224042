import BaseAvatar from '~/entities/avatar'
import { defaultTeamImage, imagePublicIdPrefix } from '~/modules/teams/config'

export default class TeamAvatar extends BaseAvatar {
  /**
   * Get default prefix for avatars in teams module.
   */
  get prefix () {
    return imagePublicIdPrefix
  }

  /**
   * Abstract getter for the default team avatar.
   * @returns {string}
   */
  get defaultImage () {
    return defaultTeamImage
  }
}
