/** @module modules/notifications/config */

/**
 * @const {(Storage|undefined)}
 */
export const storage = window.sessionStorage || undefined

/**
 * @const {string}
 */
export const storageKey = 'messageBag'
