/** @module modules/loans/store/frontend/actions */

import defaults from 'lodash/defaults'
import get from 'lodash/get'
import omit from 'lodash/omit'
import template from 'lodash/template'
import { apiRoutes } from '~/modules/loans/config'
import Loan from '~/modules/loans/entities/frontend/loan'
import { getInstance } from '~/plugins/http'

/**
 * Return a loan from state. If there is no loan in state, fetch it from
 * the API, set it in the state and then return it.
 * @param {function} commit - Vuex mutation dispatcher.
 * @param {Loans~Store~Frontend~Getters} getters - Module getters.
 * @param {number} loanId
 * @returns {Promise<({}|null)>}
 */
const fetch = async function ({ commit, getters }, { loanId }) {
  const uri = template(apiRoutes.frontend.get)({ loanId })
  const config = {
    params: {
      include: [
        'lenders',
        'loanRepayments',
        'loanUpdates',
        'teams',
        'mfiInfoMessage'
      ],
    },
  }
  const response = await getInstance().get(uri, config)
  const loanData = get(response, 'data.data', null)

  commit('SET_LOAN', new Loan(loanData))

  return getters.current
}

/**
 * Fetch configuration for loan search process.
 * @param {function} commit - Vuex mutation dispatcher.
 * @param {Getters} getters - Loans module getters.
 * @return {Promise<void>}
 */
const fetchSearchConfig = async function ({ commit, getters }) {
  const response = await getInstance().get(apiRoutes.frontend.searchConfig)

  const config = defaults(get(response, 'data.data'), omit(getters.config, 'loaded'))

  commit('SET_SEARCH_CONFIG', {
    ...config,
    loaded: true,
  })
}

/**
 * Search for loans in API.
 * @param {Store} store - Store context
 * @param {Object} params - Search parameters
 * @returns {Promise<void>}
 */
const search = async function ({ commit, getters }, params) {
  // Reset current search results in state.
  commit('SET_SEARCH_RESULTS', [])
  // Store current search parameters.
  commit('SET_SEARCH_PARAMS', params)
  commit('SET_SEARCH_IN_PROGRESS', true)

  // Send a request to the API with search parameters.
  try {
    const response = await getInstance().get(apiRoutes.frontend.index, { params })
    /** @type {array} collection */
    const collection = get(response, 'data.data', [])

    // Store search results in state.
    commit('SET_SEARCH_RESULTS', collection.map(item => new Loan(item)))
    commit('SET_SEARCH_RESULTS_META', get(response, 'data.meta'))
  } catch (e) {
    commit('SET_SEARCH_RESULTS', [])
    commit('SET_SEARCH_RESULTS_META', null)
  }

  commit('SET_SEARCH_IN_PROGRESS', false)
  // Return search results.
  return getters.searchResults
}

export default {
  fetch,
  fetchSearchConfig,
  search,
}
