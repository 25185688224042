import set from 'lodash/set'
import stateFactory from './state'

/**
 * Reset store state to the default form.
 * @param {State} state
 */
const clearExchangeRates = state => {
  state = stateFactory()
}

/**
 * Set a mapped exchange rate record, plus its inversion.
 *
 * @param {State} state
 * @param {string} from - Source currency code.
 * @param {string} to - Target currency code.
 * @param {(string|number}} rate - Exchange rate: from => to.
 * @param {(string|number)} inversed - Exchange rate: to => from.
 */
const setExchangeRate = (state, { from, to, rate, inversed }) => {
  set(state, [from, to], parseFloat(rate))
  set(state, [to, from], parseFloat(inversed))
}

export default {
  CLEAR: clearExchangeRates,
  SET: setExchangeRate,
}
