import get from 'lodash/get'
import EventBus, { AUTH_TOKEN_INVALIDATED } from '~/bus'
import { afterLogoutUri } from '~/modules/auth/config'

const MIDDLEWARE = 'authenticated'

export default function ({ app }) {
  const { router, store } = app

  // Make sure that user's auth data was cleared after logout, even
  // if the browser actually reloaded the window.
  router.beforeResolve((to, from, next) => {
    if (
      store.getters['auth/isLoggingOut'] ||
      (store.getters['auth/isAuthenticated'] && !store.state.auth.token)
    ) {
      store.dispatch('auth/destroyUserAuthData')
    }

    next()
  })

  /**
   * Protect restricted routes against accessing after user logging out.
   */
  EventBus.$on(AUTH_TOKEN_INVALIDATED, function () {
    const destroy = store.dispatch.bind(this, 'auth/destroyUserAuthData')
    const layout = get(router, ['app', '$layout'])

    const layoutMiddleware = get(layout, ['$options', 'middleware'], [])
    const pageMiddleware = get(
      router.currentRoute.matched,
      [0, 'components', 'default', 'options', 'middleware'],
      [],
    )

    // Check if current layout has authentication middleware.
    if (
      layoutMiddleware.indexOf(MIDDLEWARE) > -1 ||
      pageMiddleware.indexOf(MIDDLEWARE) > -1
    ) {
      // Destroy user auth data whether there's a navigation change or not.
      return router.push(afterLogoutUri, destroy, destroy)
    }

    return destroy()
  })
}
