import Entity, { Transformer } from '~/contracts/entity'
import OffsetReportTotalsLoans from './offset-report-totals-loans'
import OffsetReportTotalsRepayments from './offset-report-totals-repayments'

export default class OffsetReportTotals extends Entity {
  _transformers () {
    return {
      loanRepayments: Transformer.item(OffsetReportTotalsRepayments),
      loans: Transformer.item(OffsetReportTotalsLoans),
    }
  }
}
