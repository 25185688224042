import Entity from '~/contracts/entity'

export default class RepaymentRate extends Entity {
  _transformers () {
    return {
      currency: String,
      rate: Number,
    }
  }
}
