import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

/**
 * @typedef {Store} MFIs~Store
 */
/**
 * @type {MFIs~Store}
 */
export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
}
