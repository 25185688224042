import Entity from '~/contracts/entity'

/**
 * Entity representing team statistics.
 */
export default class TeamStats extends Entity {
  _transformers () {
    return {
      angels: Number,
      dependents: Number,
      donationsValue: Number,
      employees: Number,
      entrepreneurs: Number,
      loans: Number,
      loansValue: Number,
      members: Number,
    }
  }
}
