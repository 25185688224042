import get from 'lodash/get'
import merge from 'lodash/merge'
import Entity, { Transformer } from '~/contracts/entity'
import Lender from '~/modules/users/entities/frontend/lender'
import TeamAvatar from './avatar'
import Membership from './team-membership'
import Stats from './team-stats'

export class EssentialTeam extends Entity {
  _transformers () {
    return {
      avatar: Transformer.itemFromPrimitive(TeamAvatar, 'publicId'),
      createdAt: Transformer.date(),
      id: Number,
      membership: Transformer.item(Membership),
      name: String,
      slug: String,
      uuid: String,
    }
  }

  _apiMapping () {
    return {
      isAdmin: 'membership.isAdmin',
      isDefault: 'membership.isDefault',
    }
  }
}

/**
 * @property {Lender[]} admins
 * @property {string} blog
 * @property {string} category
 * @property {string} city
 * @property {string} country
 * @property {Date} createdAt
 * @property {string} description
 * @property {string} facebook
 * @property {number} id
 * @property {boolean} inactive
 * @property {string} location
 * @property {Membership} membership
 * @property {boolean} private
 * @property {string} name
 * @property {string} slug
 * @property {TeamStats} stats
 * @property {string} x
 * @property {string} uuid
 * @property {string} website
 */
export default class Team extends EssentialTeam {
  _transformers () {
    const transformers = super._transformers()

    return merge({}, transformers, {
      admins: Transformer.collection(Lender),
      blog: String,
      category: String,
      city: String,
      country: String,
      description: String,
      facebook: String,
      inactive: Boolean,
      location: String,
      membership: Transformer.item(Membership),
      private: Boolean,
      stats: Transformer.item(Stats),
      x: String,
      website: String,
    })
  }

  /**
   * Get if team is joinable by current user.
   * @return {boolean}
   */
  get isJoinable () {
    const isMember = get(this.membership, 'isMember', false)
    const isPending = get(this.membership, 'isPendingMember', false)

    return !isMember && !isPending
  }

  /**
   * Nice getter for the "private" property.
   * @return {boolean}
   */
  get isPrivate () {
    return this.private
  }
}
