import Entity, { Transformer } from '~/contracts/entity'

export default class Withdrawal extends Entity {
  _transformers () {
    return {
      amount: Number,
      createdAt: Transformer.date(),
      donationAmount: Number,
      dueAt: Transformer.date(),
      id: Number,
      ip: String,
      paypalEmail: String,
    }
  }
}
