/** @module modules/grants/locales/en */

export default {
  errors: {
    invalidEntrepreneurIdInQuery: `The entrepreneur ID in the URL is invalid.`,
    entrepreneur404: `Sorry, we couldn't find an entrepreneur with ID {entrepreneurId}.`,
    entrepreneurLoadingFailed: `Sorry, there was a problem fetching entrepreneur with ID: {entrepreneurId}.`,
  },
  validators: {
    total: {
      message: `Total amount of scheduled repayments must equal the full grant amount.`,
    },
  },
  notifications: {
    grantCreated: 'Grant has been created.',
    grantCreateFailed: `Sorry, the data you submitted seem to be invalid.`,
    grantUpdated: 'Grant has been updated.',
    grantUpdateFailed: `Sorry, the data you submitted seem to be invalid.`,
    grantRejected: 'Grant has been rejected.',
  },
  pages: {
    index: {
      heading: 'Grants',
      breadcrumb: 'Grants',
    },
    create: {
      heading: 'Add a grant',
      breadcrumb: 'Add a grant',
      entrepreneurChosen: 'Please see the details of chosen entrepreneur in a side&ndash;box.',
      resetEntrepreneur: 'Change entrepreneur',
    },
  },
}
