/** @module users/store/state */

/**
 * @typedef {object} User~User
 * @property {array} roles
 * @property {array} permissions
 */

/**
 * @typedef {Object} User~State
 * @property {User|null} current
 * @property {Loans~Microloan[]} microloans
 */
export default {
  current: null,
  microloans: [],
}
