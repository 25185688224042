/** @module modules/grants/store/state */

/**
 * @typedef {Object} GrantsConfigItem
 * @property {boolean} isDefault
 * @property {string} label
 * @property {string|number} value
 */
/**
 * @typedef {Object} GrantsConfig
 * @property {GrantsConfigItem[]} activities
 * @property {GrantsConfigItem[]} locations
 * @property {GrantsConfigItem[]} locationMaps
 * @property {GrantsConfigItem[]} statuses
 * @property {GrantType[]} types
 */
/**
 * @type {GrantsConfig}
 */
export const defaultConfig = {
  activities: [],
  locations: [],
  locationMaps: [],
  statuses: [],
  types: [],
}

/**
 * @typedef {Object} Grants~AdminStoreState
 * @property {GrantsConfig} config
 * @property {Grant} current
 * @property {{}} forms
 * @property {{}|null} forms.grant
 * @property {{}|null} forms.schedule
 */
/**
 * @type {Grants~AdminStoreState}
 */
export default {
  config: {
    ...defaultConfig,
  },
  current: null,
  datagrids: {
    grants: null,
  },
  forms: {
    grant: null,
    schedule: null,
  },
}
