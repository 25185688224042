import Vue from 'vue'

// ==============================================================================
// BUS
// ==============================================================================
/**
 * Vue instance serving as a shared event bus.
 * @type {Component}
 */
export default new Vue({
  name: 'EventBus',
})

// ==============================================================================
// EVENTS
// ==============================================================================
/**
 * Used for switching the off-canvas menu on/off.
 * @event Bus#OFF_CANVAS_TOGGLE
 */
export const OFF_CANVAS_TOGGLE = 'OFF_CANVAS_TOGGLE'

/**
 * Input component model's value has changed.
 * @event Bus#FORM_VALUE_CHANGED
 * @type {object}
 * @property {Component} vm - The instance of the component containing the changed model.
 * @property {*} value - The updated value.
 */
export const FORM_VALUE_CHANGED = 'FORM_VALUE_CHANGED'

/**
 * User has successfully signed in.
 * @event Bus#USER_SIGNED_IN
 * @type {object}
 * @property {object} user - User object received from the API.
 */
export const USER_SIGNED_IN = 'USER_SIGNED_IN'

/**
 * Modal component has been opened.
 * @event Bus#MODAL_OPENED
 * @type {object}
 * @property {Component} vm - The instance of the modal component.
 */
export const MODAL_OPENED = 'MODAL_OPENED'

/**
 * Modal component has been closed.
 * @event Bus#MODAL_CLOSED
 * @type {object}
 */
export const MODAL_CLOSED = 'MODAL_CLOSED'

/**
 * Current MFI in the context has been updated.
 * @event Bus#MFI_CONTEXT_UPDATED
 * @const {object}
 * @property {(MFI~MFI|null)} current - Current MFI in context.
 */
export const MFI_CONTEXT_UPDATED = 'MFI_CONTEXT_UPDATED'

/**
 * User has been logged out on the API side and access token has been
 * removed from auth store.
 * @event Bus#AUTH_TOKEN_INVALIDATED
 * @const {string}
 */
export const AUTH_TOKEN_INVALIDATED = 'AUTH_TOKEN_INVALIDATED'

/**
 * Frontend page has been rendered.
 *
 * @event Bus#FRONTEND_PAGE_RENDERED
 * @const {string}
 */
export const FRONTEND_PAGE_RENDERED = 'FRONTEND_PAGE_RENDERED'
