/** @module modules/grants/store/mutations */

import set from 'lodash/set'

/**
 * @typedef {Object} Grants~AdminStoreMutations
 * @property {Function} SET_CONFIG
 * @property {Function} SET_DATAGRID
 * @property {Function} SET_GRANT
 * @property {Function} SET_GRANT_FORM
 * @property {Function} SET_SCHEDULE_FORM
 */
/**
 * @type {Grants~AdminStoreMutations}
 */
export default {
  SET_CONFIG: (state, config) => {
    state.config = config
  },
  SET_DATAGRID: (state, { name, instance }) => {
    state.datagrids[name] = instance
  },
  SET_GRANT: (state, grant) => {
    state.current = grant
  },
  SET_GRANT_FORM: (state, model) => {
    state.forms.grant = model
  },
  SET_GRANT_MESSAGES: (state, messages) => {
    set(state, 'current.messages.data', messages)
  },
  SET_SCHEDULE_FORM: (state, model) => {
    state.forms.schedule = model
  },
}
