export default {
  install (Vue) {
    Vue.prototype.$isDev = __DEV__

    if (__DEV__) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      Vue.prototype.$faker = require('~/libs/dev/services/faker').default
    }
  },
}
