/**
 * The prefix to images' public IDs.
 * @const {string}
 */
export const imagePublicIdPrefix = 'lwc/maps/'

export const apiRoutes = {
  admin: {
    profileTemplateOptions: '/admin/mfis/<%= id %>/profile-templates/<%= type %>/options',
    profileTemplate: '/admin/mfis/<%= id %>/profile-templates/<%= tplId %>',
    mfiInfoMessages: {
      index: '/admin/mfis/info-messages',
      get: '/admin/mfis/info-messages/<%= mfiInfoMessageId %>',
      edit: '/admin/mfis/info-messages/<%= mfiInfoMessageId %>',}
  },
}

/**
 * Permissions to check in navigation.
 * @type {{ho: string}}
 */
export const permissions = {
  ho: 'ho-site-settings',
}
