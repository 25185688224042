import isNaN from 'lodash/isNaN'
import isInt from 'lodash/isInteger'

export default function ({ redirect, route }) {
  if (route.path === '/ie') return true

  let msie = parseInt((/msie (\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1])

  if (isNaN(msie)) {
    msie = parseInt((/trident\/.*; rv:(\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1])
  }

  if (isInt(msie)) {
    redirect('/ie')
  }
}
