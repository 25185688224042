/**
 * @typedef {{}} Teams~FrontendStore~Getters
 * @property {{}|null} editConfig
 * @property {{}|null} searchConfig
 * @property {{}} searchDefaults
 * @property {boolean} searchInProgress
 * @property {{}} searchParams
 * @property {{}} searchResults
 * @property {Loan|null} team
 */
/**
 * @type {Teams~FrontendStore~Getters}
 */
export default {
  editConfig: state => state.editConfig,
  searchConfig: state => state.search.config,
  searchDefaults: state => state.search.defaults,
  searchInProgress: state => state.search.inProgress,
  searchParams: state => state.search.params === null ? state.search.defaults : state.search.params,
  searchResults: state => state.search.results,
  team: state => state.current,
}
