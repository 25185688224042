/**
 * @typedef {{}} Appeals~Store~Frontend~Getters
 * @property {Appeal|null} current
 */

/**
 * @type {Appeals~Store~Frontend~Getters}
 */
export default {
  current: state => state.current,
}
