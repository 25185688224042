/** @module modules/grants/store/admin/getters */

/**
 * @typedef {Object} Grants~AdminStoreGetters
 * @property {function} config
 * @property {function} grant
 * @property {function} grantForm
 * @property {function} scheduleForm
 */
/**
 * @type {Grants~AdminStoreGetters}
 */
export default {
  config: state => state.config,
  grant: state => state.current,
  grantForm: state => state.forms.grant,
  scheduleForm: state => state.forms.schedule,
}
