import get from 'lodash/get'
import AdminUser from '~/modules/users/entities/admin/user'
import { itemToEntity } from '~/utils/framework'
import { DEFAULT_CREDIT_CONFIG } from './constants'

export default {
  creditConfig: state => get(state, 'credit.config', Object.assign({}, DEFAULT_CREDIT_CONFIG)),
  manageableRoles: state => get(state, 'manageableRoles', null),
  transactions: state => get(state, 'transactions'),
  user: state => get(state, 'user'),
  users (state) {
    const users = get(state, 'users')

    if (Array.isArray(users)) {
      return users.map(user => itemToEntity(user, AdminUser))
    }

    return null
  },
  usersPagination: state => get(state, 'usersPagination', null),
}
