import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import Basket from '~/modules/basket/entities/basket'

export const getDefaultBasketDefinition = () => new Basket({
  uuid: null,
  total: 0,
  userCredit: 0,
  requiredTopUp: 0,
  microgrants: [],
  microloans: [],
  vouchers: [],
  donation: null,
  creditTopUp: null,
  fetchedAt: null,
})

/**
 * @typedef {{}} Basket~State~Basket
 * @property {string|null} uuid - Basket UUID.
 * @property {number|null} total
 * @property {number} userCredit
 * @property {number} requiredTopUp
 * @property {Loans~BasketMicrogrant[]} microgrants
 * @property {Loans~BasketMicroloan[]} microloans
 * @property {Vouchers~BasketVoucher[]} vouchers
 * @property {Donations~BasketDonation|null} donation
 * @property {Loans~CreditTopUp|null} creditTopUp
 */

/**
 * @typedef {{}} Basket~Store~Frontend
 * @property {boolean} namespaced
 * @property {Basket~State~Basket} state
 * @property {string|null} state.frontend.uuid
 * @property {number} state.frontend.total
 * @property {number} state.frontend.userCredit
 * @property {number} state.frontend.requiredTopUp
 * @property {Loans~BasketMicroloan[]} state.frontend.microloans
 * @property {Vouchers~BasketVoucher[]} state.frontend.vouchers
 */
export default {
  namespaced: true,

  state: {
    frontend: getDefaultBasketDefinition(),
  },

  actions,
  getters,
  mutations,
}
