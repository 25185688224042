/** @module plugins/notify */

import { pushFlashMessage } from '~/libs/notifications/utils'
import Notifications from 'vue-notification'
import Vue from 'vue'

Vue.use(Notifications)

Vue.mixin({
  methods: {
    $flash: pushFlashMessage,
  },
})

export default ({ app }) => {
  app.notify = Vue.prototype.$notify
  app.flash = pushFlashMessage
}
