import get from 'lodash/get'
import { Transformer } from '~/contracts/entity'
import Team from '~/modules/teams/entities/frontend/team'
import Transaction from './transaction'

export default class DonationTransaction extends Transaction {
  constructor (data = {}) {
    super(data)

    Object.defineProperties(this, {
      isReversible: {
        enumerable: true,
        value: get(data, 'donation.data.reversible', false),
      },
      isMatched: {
        enumerable: true,
        value: get(data, 'matchPotClaim.data.id', false) === null ? false : true,
      },
      type: {
        enumerable: true,
        value: 'donation',
      },
    })
  }

  _transformers () {
    const transformers = super._transformers()

    return Object.assign({}, transformers, {
      team: { default: null, type: Transformer.item(Team) },
    })
  }
}
