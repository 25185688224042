import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

/**
 * @typedef {Store} GrantReportingStore
 * @property {GrantReportingStoreActions} actions
 * @property {GrantReportingStoreGetters} getters
 * @property {GrantReportingStoreMutations} mutations
 * @property {GrantReportingStoreState} state
 */
/**
 * @type {GrantReportingStore}
 */
export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
}
