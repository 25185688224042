import Entity from '~/contracts/entity'

export default class MFICurrency extends Entity {
  _transformers () {
    return {
      id: Number,
      code: String,
      isHome: Boolean,
      name: String,
      numberToBasic: Number,
    }
  }
}
