import Entity, { Transformer } from '~/contracts/entity'

export default class Repayment extends Entity {
  constructor (data = {}) {
    super(data)

    Object.defineProperty(this, 'isBufferRepayment', {
      configurable: true,
      enumerable: true,
      value: false,
      writable: false,
    })
  }

  _transformers () {
    return {
      dueAt: Transformer.date(),
      dueToUsersAt: Transformer.date(),
      expected: Number,
      id: Number,
      received: Number,
      repaidAt: Transformer.date(),
    }
  }

  get formattedExpected () {
    const formatted = this.expected.toLocaleString()
    return this.expected.toString() === '1'
      ? `${formatted} tonne`
      : `${formatted} tonnes`
  }

  get formattedReceived () {
    const formatted = this.received.toLocaleString()
    return this.received.toString() === '1'
      ? `${formatted} tonne`
      : `${formatted} tonnes`
  }

  get isDue () {
    return this.dueToUsersAt > new Date()
  }

  _apiMapping () {
    return {
      amountExpected: 'expected',
      amountToUsers: 'received',
    }
  }
}
