export default {
  unknown: {
    title: 'Ooops!',
    message: `<p>Sorry, something went wrong. Please try again. If it doesn't help, please
<a href="/contact">contact us</a>.</p>`,
  },
  formError: `<p>There was a problem submitting your form. Please review error messages listed below
and/or within the form.</p>`,
  401: {
    title: '401 Unauthorized',
    message: `Sorry, this part of the website can be viewed only by Lendwithcare administrators.`,
  },
  403: {
    title: `403 Forbidden`,
    message: `Sorry, Lendwithcare administrators did not give you permissions to access this page.`,
  },
  404: {
    title: `404 Not Found`,
    message: `Sorry, we couldn't find the page you were looking for.`,
  },
  500: {
    title: '500 Internal Server Error',
    message: `Sorry, we're having technical difficulties.`,
  },
  back: 'Take me back to the Homepage',
  cloudinary: {
    upload: {
      // Bad request.
      400: `<p>The image hosting service rejected this attempt to upload an image.</p><p>Please contact Head Office.</p><p>Response code: <strong>400</strong>.</p>`,
      // Authorization required.
      401: `<p>The image hosting service rejected this attempt to upload an image.</p><p>Please contact Head Office.</p><p>Response code: <strong>401</strong>.</p>`,
      // Not allowed.
      403: `<p>The image hosting service rejected this attempt to upload an image.</p><p>Please contact Head Office.</p><p>Response code: <strong>403</strong>.</p>`,
      // Not found.
      404: `<p>The image hosting service couldn't recognize the address we requested.</p><p>Please contact Head Office.</p><p>Response code: <strong>404</strong>.</p>`,
      // Already exists.
      409: `<p>The image hosting service claims this image has been previously uploaded and refuses to overwrite it.</p><p>Please contact Head Office.</p><p>Response code: <strong>409</strong>.</p>`,
      // Rate limited.
      420: `<p>There have been too many upload requests to the image hosting service.<p><p>Please try again later. If the problem persists, please contact Head Office.</p><p>Response code: <strong>420</strong>.</p>`,
      500: `<p>The image hosting service responded with an error we couldn't recognize.</p><p>This may be a temporary issue. Please try again later.</p><p>If the problem persists, please contact Head Office.</p>`,
    },
  },
}
