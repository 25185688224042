import CreditTopUp from '~/modules/basket/entities/credit-top-up'
import Donation from '~/modules/donations/entities/frontend/donation'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

const _collectBasketItems = state => {
  return [
    get(state, 'frontend.creditTopUp', null),
    get(state, 'frontend.donation', null),
    ...(get(state, 'frontend.microgrants', [])),
    ...(get(state, 'frontend.microloans', [])),
    ...(get(state, 'frontend.vouchers', [])),
  ].filter(item => item !== null)
}

const items = state => {
  return sortBy(_collectBasketItems(state), ['createdAt'])
}

const itemCount = state => {
  const items = _collectBasketItems(state).filter(item => !(item instanceof CreditTopUp))

  if (!Array.isArray(items)) {
    return 0
  }

  const hasZeroDonation = items.some(item => item instanceof Donation && get(item, 'amount', 0) === 0)

  return hasZeroDonation ? items.length - 1 : items.length
}

export default {
  basket: state => state.frontend,
  isCompleted: state => state.frontend.isCompleted,
  items,
  itemCount,
  microgrants: state => state.frontend.microgrants,
  microloans: state => state.frontend.microloans,
  vouchers: state => state.frontend.vouchers,
  donation: state => state.frontend.donation,
}
