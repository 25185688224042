import get from 'lodash/get'
import RepaymentBatch from '~/modules/offsetting/entities/repayment-batch'
import isNil from 'lodash/isNil'

/**
 * @typedef {{}} OffsettingStore~Getters
 * @property {function} fundedBatch - Get current offset report's related funded batch.
 * @property {function} repaymentBatch - Get current offset report's related repayment batch.
 * @property {function} report - Get current report from the store.
 */
/**
 * @type {OffsettingStore~Getters}
 */
export default {
  fundedBatch: state => get(state, 'current.fundedBatch.data', null),
  repaymentBatch (state) {
    const repaymentBatch = get(state, 'current.repaymentBatch.data', null)

    if (repaymentBatch instanceof RepaymentBatch) return repaymentBatch
    if (isNil(repaymentBatch)) return null

    return new RepaymentBatch(repaymentBatch)
  },
  report: state => get(state, 'current', null),
}
