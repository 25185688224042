import merge from 'lodash/merge'
import Vue from 'vue'
/** @module modules/auth/store/mutations */
import AuthUser from '~/modules/auth/entities/auth-user'
import { warn } from '~/utils/errors'

/**
 * Set user object in state.
 * @param state
 * @param {User} data
 */
function setUser (state, data) {
  if (typeof data !== 'object') {
    return Vue.set(state, 'user', null)
  }

  Vue.set(state, 'user', new AuthUser(data))
}

function setUserProperty (state, data) {
  if (!(state.user instanceof AuthUser)) {
    return warn('Trying to set auth user property on an object not being an instance of AuthUser.')
  }

  Vue.set(state, 'user', new AuthUser(merge({}, state.user, data)))
}

export default {
  SET_AUTH_USER: setUser,
  SET_AUTH_USER_PROPERTY: setUserProperty,
  SET_CONFIG: (state, config) => { state.config = config },
  SET_LOGGING_OUT: (state, value) => { state.loggingOut = value },
  SET_SETTINGS_CONFIG: (state, settingsConfig) => { state.settingsConfig = settingsConfig },
  SET_TOKEN: (state, token) => { state.token = token },
}
